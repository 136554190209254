import { MenuItem } from "src/commons";
import { FormClaim } from "src/screens";

export const routes: MenuItem[] = [
  {
    key: "googleMaps",
    basePath: "/",
    target: {
      component: FormClaim,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Mapa",
    children: [],
    default: true,
  },
];
