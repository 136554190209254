import { AreaResponsaleSub } from "src/app/models/business/AreaResponsableSub";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'arearesponsable_sub';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'id_arearesponsable_sub',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'id_arearesponsable_sub',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.AreaResponsableSub ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.AreaResponsableSub,
  hoc: { ...defaultOptions.hoc, ...businessOptions.AreaResponsableSub.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<AreaResponsaleSub>(DAO_NAME, optionsToUse);

// hooks
export const useAreaResponsableSubFetchById = fetchById;
export const useAreaResponsableSubLibbyCall = libbyCall;
export const useAreaResponsableSubDAO = libbyDAO;
export const useAreaResponsableSubLibbyFetch = libbyFetch;

// components/hoc
export const AreaResponsableSubAutocomplete = entityAutocomplete;
export const AreaResponsableSubCall = renderPropCall;
export const AreaResponsableSubById = renderPropFetchById;
export const AreaResponsableSubSelect = simpleSelect;
export const AreaResponsableSubAccordionSelector = accordionSelector;
export const AreaResponsableSubSelectedChips = selectedChips;

// context
// ByIdContext
export const useAreaResponsableSubByIdContext = useByIdContext;
export const AreaResponsableSubByIdProvider = ByIdProvider;
export const AreaResponsableSubByIdContext = ByIdContext;
// GeneralContext
export const useAreaResponsableSubContext = useGeneralContext;
export const AreaResponsableSubProvider = GeneralProvider;
export const AreaResponsableSubContext = GeneralContext;
