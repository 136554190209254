import { RespuestaFormulario } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'respuestas_formulario';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'id_respuestas_formulario',
      labelKey: 'formulario.nombre_de_formulario',
    },
    withSelectedChips: {
      idKey: 'id_respuestas_formulario',
      labelKey: 'formulario.nombre_de_formulario',
    },
  },
};

const optionsToUse = !businessOptions.AnswerForm ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.AnswerForm,
  hoc: { ...defaultOptions.hoc, ...businessOptions.AnswerForm.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<RespuestaFormulario>(DAO_NAME, optionsToUse);

// hooks
export const useAnswerFormFetchById = fetchById;
export const useAnswerFormLibbyCall = libbyCall;
export const useAnswerFormDAO = libbyDAO;
export const useAnswerFormLibbyFetch = libbyFetch;

// components/hoc
export const AnswerFormAutocomplete = entityAutocomplete;
export const AnswerFormCall = renderPropCall;
export const AnswerFormById = renderPropFetchById;
export const AnswerFormSelect = simpleSelect;
export const AnswerFormAccordionSelector = accordionSelector;
export const AnswerFormSelectedChips = selectedChips;

// context
// ByIdContext
export const useAnswerFormByIdContext = useByIdContext;
export const AnswerFormByIdProvider = ByIdProvider;
export const AnswerFormByIdContext = ByIdContext;
// GeneralContext
export const useAnswerFormContext = useGeneralContext;
export const AnswerFormProvider = GeneralProvider;
export const AnswerFormContext = GeneralContext;
