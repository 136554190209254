import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField as MaterialTextField,
  TextFieldProps as MaterialTextFieldProps,
} from "@material-ui/core";

// TODO: try to do this in material theme
const useStyles = makeStyles({
  buttonRoot: {
    fontSize: 16,
    padding: "9px 12px",
  },
  formControl: {
    margin: 1,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "7px 1em",
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
    },
  },
});

export type TextFieldProps = Omit<MaterialTextFieldProps, "variant">;

export const TextField = (props: TextFieldProps) => {
  const classes = useStyles();
  const basicInputProps = { className: classes.buttonRoot };
  const { inputProps, ...rest } = props;
  const normalizedInputProps = inputProps
    ? { ...basicInputProps, ...inputProps }
    : basicInputProps;
  return (
    <MaterialTextField
      fullWidth
      variant="outlined"
      {...rest}
      inputProps={normalizedInputProps}
      className={`${classes.formControl} ${props.className}`}
    />
  );
};
