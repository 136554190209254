import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class ActiveTeacherDAO extends LibbyFetchDAO {
  constructor() {
    super("activeTeacher", "idviewdocentesactivos");
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  fetchChargeTeachers(locationId: any) {
    return this.query()
      .equals('tipo_de_cargo', 'Conducción')
      .and()
      .equals('cueanexo', locationId)
      .run();
  }
}
