import React, { useState, useReducer, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useNotificationTerritoryDAO } from "src/app/business";
import { customFormDialog, FooterForm } from "src/commons";
import { SelectUserScreen } from "./components/SelectionUserScreen";
import { NotificacionFormScreen } from "./components/NotificationFormScreen";
import { useSnackbar } from "notistack";

const modalTableUserStyles = makeStyles(() => ({
  container: {
    padding: "20px 30px",
  },
  containerForm: {
    alignItems: "center",
  },
  allEstablish: {
    marginTop: 5,
    textAlign: "center",
  },
  tableContainer: {
    maxHeight: "350px",
  },
}));

const reducer = (prev: any, action: any) => {
  if (prev?.some((item: any) => item.idusuario === action.idusuario)) {
    action = prev.filter(
      (itemprev: any) => itemprev.idusuario !== action.idusuario
    );
    return action;
  }
  return Array.isArray(action) ? action : [...prev, action];
};

export const ModalCommunication = ({ reFetch }: any) => {
  const classes = modalTableUserStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [checkbox, setCheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userSelect, setUserSelect] = useReducer(reducer, []);
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState<{
    mensaje: string;
    titulo: string;
  }>({
    titulo: "",
    mensaje: "",
  });

  const NotificationTerritoryDAO = useNotificationTerritoryDAO();

  const handleSendNotification = useCallback(async () => {
    try {
      setLoading(true);
      if (checkbox) {
        const messageGlobal = [
          {
            usuario: { idusuario: 1 },
            esglobal: true,
            information: JSON.stringify({}),
            estado_notificacion: { id_estado_notificacion: 1},
            leido: false,
            ...formValues,
          },
        ];
        await NotificationTerritoryDAO.aspect("aspect-web").save({
          arrayUser: messageGlobal,
        });
      } else {
        const arrUser = userSelect.map((usuarios: any) => ({
          usuario: { idusuario: usuarios.idusuario },
          esglobal: false,
          information: JSON.stringify({}),
          estado_notificacion: { id_estado_notificacion: 1 },
          leido: false,
          ...formValues,
        }));
        await NotificationTerritoryDAO.aspect("aspect-web").save({
          arrayUser: arrUser,
        });
      }
      enqueueSnackbar("Se ha enviado el mensaje", {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
    } finally {
      reFetch();
      setLoading(false);
      customFormDialog.handleCancel();
    }
  }, [
    NotificationTerritoryDAO,
    formValues,
    userSelect,
    enqueueSnackbar,
    reFetch,
    checkbox
  ]);

  const buttonConfig: any = {
    0: [
      {
        title: "Cancelar",
        handleOnClick: () => customFormDialog.handleCancel(),
        type: "secondary",
        size: "small",
      },
      {
        title: "Continuar",
        handleOnClick: () =>
          setActiveStep((prevActiveStep) => prevActiveStep + 1),
        size: "small",
        disabled: checkbox ? false : !userSelect.length,
      },
    ],
    1: [
      {
        title: "Cancelar",
        handleOnClick: () => customFormDialog.handleCancel(),
        type: "secondary",
        size: "small",
      },
      {
        title: "Atrás",
        handleOnClick: () =>
          setActiveStep((prevActiveStep) => prevActiveStep - 1),
        type: "secondary",
        size: "small",
        customStyle: { border: "1px solid transparent" },
      },
      {
        title: "Enviar",
        handleOnClick: handleSendNotification,
        size: "small",
        disabled: !formValues.titulo || !formValues.mensaje || loading,
      },
    ],
  };

  const renderSteps = [
    {
      render: (
        <SelectUserScreen
          setUserSelect={setUserSelect}
          userSelect={userSelect}
          loading={loading}
          checkbox={checkbox}
          setCheckbox={setCheckbox}
        />
      ),
    },
    {
      render: (
        <NotificacionFormScreen
          userSelect={userSelect}
          checkbox={checkbox}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      ),
    },
  ];

  return (
    <Grid container className={classes.container}>
      {renderSteps[activeStep].render}
      <FooterForm buttonConfig={buttonConfig[activeStep]} spacing={1} />
    </Grid>
  );
};
