//type ChaletNewYorkFont = { [k: string]: any };

type MuiCssBaselineType = { [k: string]: any };

// TODO: change this
/* const chaletNewYorkFont: ChaletNewYorkFont = {
  fontFamily: 'ChaletNewYork',
  fontStyle: 'normal',
  src: `
    local('ChaletNewYork'),
    local('ChaletNewYork-Regular'),
    url('https://ster-ecommerce-img.s3.amazonaws.com/assets/fonts/CHANEWEI.woff') format('woff')
    url('https://ster-ecommerce-img.s3.amazonaws.com/assets/fonts/CHANEWEI.ttf') format('ttf')
    url('https://ster-ecommerce-img.s3.amazonaws.com/assets/fonts/oswald/Oswald-Regular.ttf') format('truetype')
    url('https://ster-ecommerce-img.s3.amazonaws.com/assets/fonts/lato/Lato-Regular.ttf') format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}; */

const rubikFont: MuiCssBaselineType = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  src: `
    local('Rubik'),
    url('https://ster-ecommerce-img.s3.amazonaws.com/assets/fonts/rubik/Rubik-VariableFont_wght.ttf') format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const MuiCssBaseline: MuiCssBaselineType = {
  '@global': {
    '@font-face': [rubikFont],
  },
};

export default MuiCssBaseline;
