import React, { useMemo, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Grid } from "@material-ui/core";
import { useTabBarValue } from "src/commons";
import { Marker } from "./components/Marker";
import { useEstablishmentLibbyFetch } from "src/app/business";
import { loading } from "src/assets/images";

export const EstablishmentMap = () => {
  const { params } = useTabBarValue();
  const [position, setPosition] = useState<any>({
    lat: params?.lat || -34.59816060240002,
    lng: params?.lng || -58.412250704195955,
  });

  const filter = useMemo(
    () => ({
      visit: !Object.keys(params).length
        ? {}
        : [
            {
              path: "idestablecimiento",
              value: params.establishment.idestablecimiento,
            },
          ],
    }),
    [params]
  );

  const {
    data: establishments = [],
    working: workingEstablishment,
  } = useEstablishmentLibbyFetch({
    limit: 10000,
    filter,
  });

  const data = useMemo(
    () =>
      establishments.reduce((acum: any, establishment) => {
        establishment.localizacion.forEach((location) => {
          acum.push(location);
        });
        return acum;
      }, []),
    [establishments]
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;
      setPosition({
        lat: latitude,
        lng: longitude,
      });
    });
  }, []);

  return (
    <div style={{ height: "100vh", width: "100%", padding: 15 }}>
      {workingEstablishment ? (
        <Grid container justify="center" alignItems="center">
          <img src={loading} width={50} alt="loading" />
        </Grid>
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDszJRRSlfzenwffr9lVpagB-SA_q6ma6w" }}
          defaultCenter={position}
          defaultZoom={15}
        >
          {data?.map((item: any, index: any) => (
            <Marker
              lat={item?.domicilio?.latlon?.x || 0}
              lng={item?.domicilio?.latlon?.y || 0}
            />
          ))}
        </GoogleMapReact>
      )}
    </div>
  );
};
