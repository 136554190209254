import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ClaimDAO extends LibbyFetchDAO {
  constructor() {
    super('reclamoterritorio', 'idreclamoterritorio');
  }

  getAll() {
    return this.query().limit(0, 20).run();
  }

}
