import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { MainButton } from "src/commons";
import { useCreateFormContext } from "../../hook";

const HeaderTerriStyles = makeStyles(() => ({
  searchStyle: {
    marginLeft: 10,
    marginRight: 10,
  },
  containerSearch: {
    paddingTop: 20,
    marginBottom: 10,
  },
  button: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 20,
  },
}));



export const HeaderCreateForm = ({
  children,
  disabledPreview = false,
}: any) => {
  const classes = HeaderTerriStyles();

  const { handleSubmit, errors, } = useCreateFormContext();
  console.log(errors);

  return (
    <Grid container>
      <Grid container className={classes.containerSearch}>
        <Grid item xs={12}>
          <Grid container justify="flex-end">
            <Grid item className={classes.button}>
              <MainButton
                handleOnClick={() => handleSubmit()}
                title="Publicar"
                typeButton="submit"
                disabled={Object.keys(errors).length !== 0 || disabledPreview}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {children}
    </Grid>
  );
};
