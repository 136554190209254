import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { SideMenu, ColorBar, Header } from '..';
import { useRouterContext } from '../../../lib/router';
import { LibbyObject } from '../../types';
import { useRolesContext } from 'src/context';
import { MenuCollection } from 'src/commons/ui';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    margin: -8,
  },
  menuContainer: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
  },
  sideAndContent: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface MainLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
  libby: LibbyObject;
}

const MainLayoutRaw: FunctionComponent<MainLayoutProps> = ({ libby, children }) => {
  const classes = useStyles();
  const { metadata } = useRouterContext();
  const { userInfo } = useRolesContext();

  return (
    <div className={classes.container}>
      <ColorBar />
      <div className={classes.sideAndContent}>
        <SideMenu />
        <div className={classes.menuContainer}>
          <Header
            title={metadata.title}
            user={userInfo.name}
            backButton={metadata.showBackButton}
          />
          <main>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export const MainLayout = DatabaseConnector(MainLayoutRaw)();
