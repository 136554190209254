import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'student';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idalumnomovimiento',
      labelKey: 'lazo',
    },
    withSelectedChips: {
      idKey: 'idalumnomovimiento',
      labelKey: 'lazo',
    },
  },
};

const optionsToUse = !businessOptions.StudentMovement ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.StudentMovement,
  hoc: { ...defaultOptions.hoc, ...businessOptions.StudentMovement.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useStudentMovementFetchById = fetchById;
export const useStudentMovementLibbyCall = libbyCall;
export const useStudentMovementDAO = libbyDAO;
export const useStudentMovementLibbyFetch = libbyFetch;

// components/hoc
export const StudentMovementAutocomplete = entityAutocomplete;
export const StudentMovementCall = renderPropCall;
export const StudentMovementById = renderPropFetchById;
export const StudentMovementSelect = simpleSelect;
export const StudentMovementAccordionSelector = accordionSelector;
export const StudentMovementSelectedChips = selectedChips;

// context
// ByIdContext
export const useStudentMovementByIdContext = useByIdContext;
export const StudentMovementByIdProvider = ByIdProvider;
export const StudentMovementByIdContext = ByIdContext;
// GeneralContext
export const useStudentMovementContext = useGeneralContext;
export const StudentMovementProvider = GeneralProvider;
export const StudentMovementContext = GeneralContext;
