export const filterComunication = (search: string) => {
  const filterSearch = [
    {
      path: "titulo",
      value: search,
      method: "includes",
    },
  ];

  return { filterSearch };
};
