import { LibbyFetchDAO } from './LibbyFetchDAO';

export class AreaResponsableSubDAO extends LibbyFetchDAO {
  constructor() {
    super('arearesponsable_sub', 'id_arearesponsable_sub');
  }

  getAll() {
    return this.query().limit(0, 20).run();
  }

}
