import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'facilitador_intec';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idfacilitadorintec',
      labelKey: 'nombre',
    },
    withSelectedChips: {
      idKey: 'idfacilitadorintec',
      labelKey: 'nombre',
    },
  },
};

const optionsToUse = !businessOptions.FacilitadorIntec ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.FacilitadorIntec,
  hoc: { ...defaultOptions.hoc, ...businessOptions.FacilitadorIntec.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useFacilitadorIntecFetchById = fetchById;
export const useFacilitadorIntecLibbyCall = libbyCall;
export const useFacilitadorIntecDAO = libbyDAO;
export const useFacilitadorIntecLibbyFetch = libbyFetch;

// components/hoc
export const FacilitadorIntecAutocomplete = entityAutocomplete;
export const FacilitadorIntecCall = renderPropCall;
export const FacilitadorIntecById = renderPropFetchById;
export const FacilitadorIntecSelect = simpleSelect;
export const FacilitadorIntecAccordionSelector = accordionSelector;
export const FacilitadorIntecSelectedChips = selectedChips;

// context
// ByIdContext
export const useFacilitadorIntecByIdContext = useByIdContext;
export const FacilitadorIntecByIdProvider = ByIdProvider;
export const FacilitadorIntecByIdContext = ByIdContext;
// GeneralContext
export const useFacilitadorIntecContext = useGeneralContext;
export const FacilitadorIntecProvider = GeneralProvider;
export const FacilitadorIntecContext = GeneralContext;
