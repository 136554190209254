import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'studentView';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idalumnomovimiento',
      labelKey: 'nombre',
    },
    withSelectedChips: {
      idKey: 'idalumnomovimiento',
      labelKey: 'nombre',
    },
  },
};

const optionsToUse = !businessOptions.StudentView ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.StudentView,
  hoc: { ...defaultOptions.hoc, ...businessOptions.StudentView.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useStudentViewFetchById = fetchById;
export const useStudentViewLibbyCall = libbyCall;
export const useStudentViewDAO = libbyDAO;
export const useStudentViewLibbyFetch = libbyFetch;

// components/hoc
export const StudentViewAutocomplete = entityAutocomplete;
export const StudentViewCall = renderPropCall;
export const StudentViewById = renderPropFetchById;
export const StudentViewSelect = simpleSelect;
export const StudentViewAccordionSelector = accordionSelector;
export const StudentViewSelectedChips = selectedChips;

// context
// ByIdContext
export const useStudentViewByIdContext = useByIdContext;
export const StudentViewByIdProvider = ByIdProvider;
export const StudentViewByIdContext = ByIdContext;
// GeneralContext
export const useStudentViewContext = useGeneralContext;
export const StudentViewProvider = GeneralProvider;
export const StudentViewContext = GeneralContext;
