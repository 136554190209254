import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MainButton } from '../MainButton';
import { primary } from '../../../theme/colors';
import {Loading} from "../Loading";

export interface ButtonTypesProps {
  title: React.ReactNode;
  disabled?: boolean;
  typeButton?: string;
  size?: string;
  handleOnClick?: () => void;
  icon?: JSX.Element;
  type?: string;
  customStyle?: any;
  customStyleIcon?: any;
}

interface FooterFormProps {
  buttonConfig: Array<ButtonTypesProps>;
  position?: any;
  spacing?: any;
  customStyle?: any;
  loading?: boolean;
}

export const FooterForm = ({
  buttonConfig,
  position = 'flex-end',
  spacing = 4,
  customStyle,
  loading,
}: FooterFormProps) => {
  const footerStyles = makeStyles(() => ({
    containerFooter: {
      background: primary.white,
      padding: '10px 20px',
      boxShadow: '0px -7px 3px rgba(0, 0, 0, 0.1);',
      ...customStyle,
    },
  }));
  const classes = footerStyles();
  return loading ? (
    <Loading />
  ) : (
    <Grid container className={classes.containerFooter} spacing={0}>
      <Grid item xs={12}>
        <Grid container justify={position} spacing={spacing}>
          {buttonConfig.map((value: ButtonTypesProps, index: number) => (
            <Grid key={index} item>
              <MainButton
                title={value.title}
                typeButton={value.typeButton}
                size={value.size}
                type={value.type}
                handleOnClick={value.handleOnClick}
                disabled={value.disabled}
                customStyle={value.customStyle}
                customStyleIcon={value.customStyleIcon}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
