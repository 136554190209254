export const columns = [
    {
      id: "localizacion.descripcion",
      label: "Establecimiento",
      width: "5%",
      orderById: "nombre",
    },
    {
      id: "localizacion.cueanexo",
      label: "CUE",
      width: "5%",
      orderById: "nombre",
    },
    {
      id: "icon",
      label: "",
      width: "4%",
      hideSortIcon: true,
      noSort: true,
    },
  ];