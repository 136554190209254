import { Grid, Radio } from "@material-ui/core";
import React from "react";

export const RadioSecondary = () => {
  return (
    <Grid>
      <Radio checked={false} />
      Bueno
      <Radio checked={false}/>
      Regular
      <Radio checked={false}/>
      Malo
    </Grid>
  );
};
