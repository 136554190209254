import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import { primary } from "../../../theme/colors";
interface AvatarProps {
  name?: string;
  img?: string;
  avatarWidth: number;
  avatarHeight: number;
  size?: number;
  icon?: JSX.Element;
}

export const UserAvatar = ({
  name,
  avatarWidth,
  avatarHeight,
  img,
  size = 10,
  icon,
}: AvatarProps) => {
  const AvatarStyles = makeStyles(() => ({
    avatarText: {
      padding: 5,
      width: avatarWidth,
      height: avatarHeight,
      backgroundColor: primary.lightBlue,
      fontSize: size,
      fontFamily: "Open Sans",
      color: primary.white,
    },
    avatarImage: {
      width: avatarWidth,
      height: avatarHeight,
    },
  }));
  const classes = AvatarStyles();
  const getInitials = () => {
    if (name) {
      const names = name.split(" ");
      let initials = names[0].substring(0, 1).toUpperCase();
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }
  };
  return (
    <>
      {icon ? (
        <Avatar className={classes.avatarText}>{icon}</Avatar>
      ) : !img ? (
        <Avatar className={classes.avatarText}>{getInitials()}</Avatar>
      ) : (
        <Avatar className={classes.avatarImage} src={img} />
      )}
    </>
  );
};
