import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class InfoPersonalNoDocentDAO extends LibbyFetchDAO {
  constructor() {
    super('infopersonalnodocente', 'idinfopersonalnodocente');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
