import React from 'react'
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';




const useStyles = makeStyles((theme) => ({
    container: {
      padding: 15,
      overflow: "auto",
      maxHeight: "490px",
    },
    iconStyle: {
      color: "#5E5CE6",
    },
    iconStyleSub: {
      color: "#1C1C1E",
    },
    childColumnFlex: {
      borderWidth: 1,
      border: "1px solid #e0e0e0",
      borderRadius: 8,
      marginBottom: 10,
      padding: 10,
    },
    childColumnFlexDataSub: {
      borderWidth: 1,
      border: "1px solid #e0e0e0",
      borderRadius: 8,
      marginBottom: 10,
      padding: 10,
      width: "46%",
      marginRight: 10,
    },
    titleDataFirst: {
      fontSize: 13,
      color: "#8C8C8C",
    },
    subtitleDataFirst: {
      fontSize: 13,
    },
    containerDrawer: {
      padding: 10,
    },
    widthDrawe: {
      width: 300,
    },
  }));

export const DrawerList = ({ infoData, selectDrawer }: any) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.containerDrawer}>
        <Grid container>
          <Typography display="block">{infoData.info[selectDrawer]?.title}</Typography>
        </Grid>
        {infoData.info[selectDrawer]?.dataFirst.map((item: any, index: any) => (
          <Grid
            className={classes.childColumnFlex}
            direction="column"
            container
          >
            <Typography className={classes.titleDataFirst} display="block">
              {item.title}
            </Typography>
            <Typography display="block" className={classes.subtitleDataFirst}>
              {item.subtitle}
            </Typography>
            {!!item?.tercerTitle && (
              <Typography>{item.tercerTitle}</Typography>
            )}
            {!!item?.tercerTitle && (
              <Typography>{item.cuartoTitle}</Typography>
            )}
          </Grid>
        ))}
        <Grid container>
          {infoData.info[selectDrawer]?.dataSub.map((item: any, index: any) => (
            <Grid item className={classes.childColumnFlexDataSub}>
              <Typography className={classes.titleDataFirst}>
                {item?.titleSub}
              </Typography>
              <Typography className={classes.subtitleDataFirst}>
                {item?.subtitleSub}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    )
}
