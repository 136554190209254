import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'establecimientos_arearesponsable';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'id_establecimientos_arearesponsable',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'id_establecimientos_arearesponsable',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstablishmentAreaResponsability ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.EstablishmentAreaResponsability,
  hoc: { ...defaultOptions.hoc, ...businessOptions.EstablishmentAreaResponsability.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useEstablishmentAreaResponsabilityFetchById = fetchById;
export const useEstablishmentAreaResponsabilityLibbyCall = libbyCall;
export const useEstablishmentAreaResponsabilityDAO = libbyDAO;
export const useEstablishmentAreaResponsabilityLibbyFetch = libbyFetch;

// components/hoc
export const EstablishmentAreaResponsabilityAutocomplete = entityAutocomplete;
export const EstablishmentAreaResponsabilityCall = renderPropCall;
export const EstablishmentAreaResponsabilityById = renderPropFetchById;
export const EstablishmentAreaResponsabilitySelect = simpleSelect;
export const EstablishmentAreaResponsabilityAccordionSelector = accordionSelector;
export const EstablishmentAreaResponsabilitySelectedChips = selectedChips;

// context
// ByIdContext
export const useEstablishmentAreaResponsabilityByIdContext = useByIdContext;
export const EstablishmentAreaResponsabilityByIdProvider = ByIdProvider;
export const EstablishmentAreaResponsabilityByIdContext = ByIdContext;
// GeneralContext
export const useEstablishmentAreaResponsabilityContext = useGeneralContext;
export const EstablishmentAreaResponsabilityProvider = GeneralProvider;
export const EstablishmentAreaResponsabilityContext = GeneralContext;
