import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'teacherView';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'iddocente',
      labelKey: 'nombre',
    },
    withSelectedChips: {
      idKey: 'iddocente',
      labelKey: 'nombre',
    },
  },
};

const optionsToUse = !businessOptions.TeacherView ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.TeacherView,
  hoc: { ...defaultOptions.hoc, ...businessOptions.TeacherView.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useTeacherViewFetchById = fetchById;
export const useTeacherViewLibbyCall = libbyCall;
export const useTeacherViewDAO = libbyDAO;
export const useTeacherViewLibbyFetch = libbyFetch;

// components/hoc
export const TeacherViewAutocomplete = entityAutocomplete;
export const TeacherViewCall = renderPropCall;
export const TeacherViewById = renderPropFetchById;
export const TeacherViewSelect = simpleSelect;
export const TeacherViewAccordionSelector = accordionSelector;
export const TeacherViewSelectedChips = selectedChips;

// context
// ByIdContext
export const useTeacherViewByIdContext = useByIdContext;
export const TeacherViewByIdProvider = ByIdProvider;
export const TeacherViewByIdContext = ByIdContext;
// GeneralContext
export const useTeacherViewContext = useGeneralContext;
export const TeacherViewProvider = GeneralProvider;
export const TeacherViewContext = GeneralContext;
