import React from "react";
import {
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { IconTablesTypes, IconTablesContentTypes } from "src/commons/types";
import { makeStyles } from "@material-ui/styles";
import { grey } from 'src/theme/colors';


const iconTablesStyles = makeStyles(() => ({
  container: {
    padding: 0,
    marginLeft: -19,
  },
  iconCenter: {
    textAlign: "center",
  },
  iconText: {
    fontSize: 12,
    color: grey.textSecondary,
    marginTop: 5,
  },
}));

export const IconTables = ({ content }: IconTablesTypes) => {
  const classes = iconTablesStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid container spacing={0} className={classes.container}>
      {content?.map((t: IconTablesContentTypes) =>
        t.tooltip && t.disabled ? (
          <Tooltip title={t.tooltip}>
            <Grid item xs={4} style={{ color: "lightgrey" }}>
              {t.icon}
            </Grid>
          </Tooltip>
        ) : (
          <Grid item xs={4} className={classes.iconCenter}>
            <IconButton
              className={t.class}
              disabled={t.disabled}
              onClick={(event) => {
                t.handleOnClick();
                if (t.popoverContent) handleClickPopover(event);
              }}
              style={{ padding: 0, ...t.styleIcon }}
            >
              {t.icon}
            </IconButton>
            {t.text && (
              <Typography className={classes.iconText}>{t.text}</Typography>
            )}
            {t.popoverContent && (
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {t.popoverContent}
              </Popover>
            )}
          </Grid>
        )
      )}
    </Grid>
  );
};
