import React, { FunctionComponent } from "react";
import { DatePicker as MuiDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ParsableDate } from "@material-ui/pickers/constants/prop-types";
import { makeStyles } from "@material-ui/core";

interface DatePickerProps {
  value: ParsableDate;
  format?: string;
  error?: boolean;
  helperText?: string;
  clearable?: boolean;
  placeholder?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  onChange: (date: MaterialUiPickersDate) => void;
  className?: any;
  fullWidth?: boolean;
}

export const DatePicker: FunctionComponent<DatePickerProps> = ({
  value,
  error,
  format = "DD/MM/yyyy",
  onChange,
  clearable,
  helperText,
  placeholder = "dd/mm/aaaa",
  disablePast,
  disableFuture,
  className,
  fullWidth = false,
}) => {
  const inputStyles = makeStyles(() => ({
    root: {
      padding: "7px",
    },
    buttonRoot: {
      fontSize: 16,
      padding: "9px 12px",
    },
  }));

  const classes = inputStyles();
  const basicInputProps = { className: classes.buttonRoot };
  const classNameNormalized = className || classes.root;
  return (
    <MuiDatePicker
      fullWidth={fullWidth}
      error={error}
      value={value}
      okLabel="Aceptar"
      clearLabel="Limpiar"
      cancelLabel="Cancelar"
      format={format}
      onChange={onChange}
      clearable={clearable}
      helperText={helperText}
      disablePast={disablePast}
      disableFuture={disableFuture}
      placeholder={placeholder}
      className={classNameNormalized}
      inputProps={basicInputProps}
      inputVariant="outlined"
    />
  );
};
