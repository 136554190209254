import { LibbyFetchDAO } from './LibbyFetchDAO';

export class AreaResponsabilityWebDAO extends LibbyFetchDAO {
  constructor() {
    super('arearesponsableweb', 'idarearesponsable');
  }

  getAll() {
    return this.query().limit(0, 20).run();
  }

}
