import { MenuItem } from "src/commons";
import { NewCreateForm } from "src/screens";
import { ListForm } from "src/screens/Private/CreateForm/ListForm/ListForm";

export const routes: MenuItem[] = [
  {
    key: 'listForm',
    basePath: '/nuevo-formulario/:id_formulario',
    target: {
      component: NewCreateForm,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Nuevo Formulario',
    children: [],
  },
  {
    key: 'listForm',
    basePath: '/nuevo-formulario',
    target: {
      component: NewCreateForm,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Nuevo Formulario',
    children: [],
  },
  {
    key: "listForm",
    basePath: "/",
    target: {
      component: ListForm,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Formulario",
    children: [],
    default: true,
  },
];
