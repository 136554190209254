import React, { FunctionComponent, useEffect, useState } from "react";
import { DatabaseStore } from "./platform/libby/DatabaseStore";
import { ITerriUser, SessionChangeEmitter } from "./platform/libby/session";
import { PhinxApp } from "./lib/profiles/PhinxApp";
import { useProfileContext } from "./lib/profiles/ProfileContext";
import { Loading } from "./commons/components/Loading";
import { useRolesContext } from "./context";
import { usePermissionContext } from "./lib/permission";

// Initialize Libby
DatabaseStore.init();

export const App: FunctionComponent = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [initialProfileLoaded, setInitialProfileLoaded] = useState(false);
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);
  const { setProfile } = useProfileContext();
  const { setUserInfo } = useRolesContext();
  const { desactivateAllRulesSets, activateRulesSet } = usePermissionContext()

  useEffect(
    () =>
      SessionChangeEmitter.subscribe(
        ({
          isLogged: logged,
          user,
        }: {
          isLogged: boolean;
          user: ITerriUser;
        }) => {
          setUserInfo({
            idusuario: user.idusuario,
            email: user.email,
            name: `${user?.persona?.nombre} ${user?.persona?.apellido}`,
            rol: user.rol,
          });
          // Esta logica es para manejar la seleccion del rol y la seleccion de las reglas de permisos
          // if (user.groupSelected) {
      if (user?.rol?.idrol === 1 || user?.rol?.idrol === 2) {
            desactivateAllRulesSets();
            activateRulesSet(`rol-1`);
          } else {
            desactivateAllRulesSets();
          } 
          setIsLogged(logged);
          setTimeout(() => setIsFirstLoading(false), 0);
        }
      ),
    [setUserInfo, desactivateAllRulesSets, activateRulesSet]
  );

  useEffect(() => {
    if (!isFirstLoading) {
      if (!isLogged) {
        setProfile("public");
      } else {
        setProfile("private");
      }
      setInitialProfileLoaded(true);
    }
  }, [isFirstLoading, isLogged, setProfile]);

  return !initialProfileLoaded ? <Loading /> : <PhinxApp />;
};
