import { ReclamoTerritorio } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'barrio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idbarrio',
      labelKey: 'barrio',
    },
    withSelectedChips: {
      idKey: 'barrio',
      labelKey: 'asunto',
    },
  },
};

const optionsToUse = !businessOptions.Barrio ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Barrio,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Barrio.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ReclamoTerritorio>(DAO_NAME, optionsToUse);

// hooks
export const useBarrioFetchById = fetchById;
export const useBarrioLibbyCall = libbyCall;
export const useBarrioDAO = libbyDAO;
export const useBarrioLibbyFetch = libbyFetch;

// components/hoc
export const BarrioAutocomplete = entityAutocomplete;
export const BarrioCall = renderPropCall;
export const BarrioById = renderPropFetchById;
export const BarrioSelect = simpleSelect;
export const BarrioAccordionSelector = accordionSelector;
export const BarrioSelectedChips = selectedChips;

// context
// ByIdContext
export const useBarrioByIdContext = useByIdContext;
export const BarrioByIdProvider = ByIdProvider;
export const BarrioByIdContext = ByIdContext;
// GeneralContext
export const useBarrioContext = useGeneralContext;
export const BarrioProvider = GeneralProvider;
export const BarrioContext = GeneralContext;
