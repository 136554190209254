import { createContext, useContext } from "react";
export interface Rol {
  idrol: number;
  descripcion: string;
}


export type UserInfo = {
  idusuario: string | number;
  name: string;
  email: string;
  rol: Rol | {};
};

export type RolesContextValue = {
  userInfo: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
};

export const RolesContext = createContext<RolesContextValue>({
  userInfo: {
    idusuario: "",
    name: "",
    email: "",
    rol: {},
  },
  setUserInfo: () => {},
});

export const useRolesContext = () => useContext(RolesContext);
