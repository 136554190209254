import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class FacilitadorIntecDAO extends LibbyFetchDAO {
  constructor() {
    super('facilitador_intec', 'idfacilitadorintec');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
