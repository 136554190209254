import React, {createContext, ReactNode, useContext} from "react";
import {makeEntityFetchById} from "../hooks";

export function makeByIdContext<T>(daoName: string) {
  const useHook = makeEntityFetchById(daoName)

  interface ByIdContextValue {
    data: T;
    working: boolean;
  }

  // @ts-ignore
  const ByIdContext = createContext<ByIdContextValue>({ data: null, working: false });

  const useByIdContext = () => useContext<ByIdContextValue>(ByIdContext);

  interface ByIdProviderProps {
    children: ReactNode;
    id: string;
  }

  const ByIdProvider = ({ children, id }: ByIdProviderProps) => {
    const value = useHook(id);

    return (
      <ByIdContext.Provider value={value}>
        {children}
      </ByIdContext.Provider>
    );
  };

  return {
    ByIdContext,
    ByIdProvider,
    useByIdContext,
  };
}
