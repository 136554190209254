/* eslint-disable class-methods-use-this */
import {
  Database,
  Libby,
  RESTSessionStrategy,
  RESTStoreStrategy,
} from "@phinxlab/libby-rest-web";
import { API_URL } from "../../config";
import { SessionChangeEmitter } from "./session";
import { TokenManager } from "./TokenManager";
import {
  UsuarioDAO,
  EstablishmentDAO,
  NivelDAO,
  LocalizacionDAO,
  ResponsibleAreaDAO,
  ClaimDAO,
  SexoDAO,
  TypeDocumentDAO,
  PersonDAO,
  ClaimAnswerDAO,
  EstablishmentVisitsDAO,
  SectionDAO,
  EstablishmentFutureDAO,
  SchoolGreenDAO,
  PadronDAO,
  ActiveTeacherDAO,
  PlantaDAO,
  TeacherViewDAO,
  InfoPersonalNoDocentDAO,
  StudentViewDAO,
  TransportSchoolDAO,
  EstablishmentPriorityDAO,
  BarrioDAO,
  ComunaDAO,
  SchoolDistrictDAO,
  NotificationTerritoryDAO,
  AnswerFormDAO,
  EspecialidadDAO,
  FacilitadorIntecDAO,
  AreaResponsabilityDAO,
  EstablishmentAreaResponsabilityDAO,
  AreaResponsableSubDAO,
  AreaResponsabilityWebDAO,
  AreaResponsableSubWebDAO,
  EstablecimientoCategorias,
} from "./dao";
import { StudentMovementDAO } from "./dao/StudentMovementDAO";
import { FormDAO } from "./dao/FormDAO";
import { QuestionDAO } from "./dao/QuestionDAO";

class DatabaseStoreRaw {
  chinoTokenEnvironmet: string;

  url: string;

  constructor() {
    this.chinoTokenEnvironmet = "SterApp";
    this.url = API_URL;
  }

  addSession(): void {
    const Session = RESTSessionStrategy.init(this.url);
    Session.setTokenManager(TokenManager.create());
    Database.addSessionStrategy(Session);
    Session.addSessionListener({
      // TODO: remove any type
      newSessionState: (state: any) => {
        const { user } = Session;
        const isLogged: boolean = !!user && user.isGuest === false;
        SessionChangeEmitter.emitChange({ isLogged, user, state });
      },
    });
    Database.addSessionStrategy(Session);
  }

  register(): void {
    Database.register(
      new RESTStoreStrategy("usuario", "/base/usuario", "idusuario"),
      UsuarioDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "usuarioestablecimiento",
        "/app/usuarioestablecimiento",
        "idusuarioestablecimiento"
      ),
      EstablishmentDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "establecimiento",
        "/establecimientoweb/establecimiento",
        "idestablecimiento"
      ),
      LocalizacionDAO
    );
    Database.register(
      new RESTStoreStrategy("nivel", "/app/nivel", "idnivel"),
      NivelDAO
    );
    Database.register(
      new RESTStoreStrategy("sexo", "/app/sexo", "idsexo"),
      SexoDAO
    );
    Database.register(
      new RESTStoreStrategy("persona", "/app/persona", "idpersona"),
      PersonDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "tipodocumento",
        "/app/tipodocumento",
        "idtipodocumento"
      ),
      TypeDocumentDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "responsibleArea",
        "/base/arearesponsable",
        "idarearesponsable"
      ),
      ResponsibleAreaDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "reclamoterritorio",
        "/app/reclamoterritorio",
        "idreclamoterritorio"
      ),
      ClaimDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "respuestaterritorio",
        "/app/respuestaterritorio",
        "idrespuestaterritorio"
      ),
      ClaimAnswerDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "establecimientovisita",
        "/app/establecimientovisita",
        "idestablecimientovisita"
      ),
      EstablishmentVisitsDAO
    );
    Database.register(
      new RESTStoreStrategy("seccion", "/app/seccion", "idseccion"),
      SectionDAO
    );
    Database.register(
      new RESTStoreStrategy("formulario", "/app/formulario", "id_formulario"),
      FormDAO
    );
    Database.register(
      new RESTStoreStrategy("pregunta", "/app/pregunta", "id_pregunta"),
      QuestionDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "respuestas_formulario",
        "/app/respuestas_formulario",
        "id_respuestas_formulario"
      ),
      AnswerFormDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "secundariadelfuturo",
        "/app/secundariadelfuturo",
        "id_secundariadelfuturo"
      ),
      EstablishmentFutureDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "escuelas_verdes",
        "/app/escuelas_verdes",
        "id_escuelas_verdes"
      ),
      SchoolGreenDAO
    );
    Database.register(
      new RESTStoreStrategy("padron", "/app/padron", "idpadron"),
      PadronDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "activeTeacher",
        "/app/view_docentes_activos",
        "idviewdocentesactivos"
      ),
      ActiveTeacherDAO
    );
    Database.register(
      new RESTStoreStrategy("planta", "/app/planta", "idplanta"),
      PlantaDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "arearesponsable",
        "/base/arearesponsable",
        "idarearesponsable"
      ),
      AreaResponsabilityDAO
    );
    Database.register(
      new RESTStoreStrategy("teacherView", "/base/docente", "iddocente"),
      TeacherViewDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "infopersonalnodocente",
        "/app/infopersonalnodocente",
        "idinfopersonalnodocente"
      ),
      InfoPersonalNoDocentDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "student",
        "/app/alumnomovimiento",
        "idalumnomovimiento"
      ),
      StudentMovementDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "studentView",
        "/base/alumnomovimiento",
        "idalumnomovimiento"
      ),
      StudentViewDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "transporteescolar",
        "/app/transporteescolar",
        "idtransporteescolar"
      ),
      TransportSchoolDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "establecimientoprioridades",
        "/app/establecimientoprioridades",
        "idestablecimientoprioridades"
      ),
      EstablishmentPriorityDAO
    );
    Database.register(
      new RESTStoreStrategy("barrio", "/app/barrio", "idbarrio"),
      BarrioDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "establecimientos_arearesponsable",
        "/establecimientoweb/establecimientos_arearesponsable",
        "id_establecimientos_arearesponsable"
      ),
      EstablishmentAreaResponsabilityDAO
    );
    Database.register(
      new RESTStoreStrategy(
        'arearesponsable_sub',
        '/app/arearesponsable_sub',
        'id_arearesponsable_sub',
      ),
      AreaResponsableSubDAO,
    );
    Database.register(
      new RESTStoreStrategy("comuna", "/app/comuna", "idcomuna"),
      ComunaDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "distritoescolar",
        "/app/distritoescolar",
        "iddistritoescolar"
      ),
      SchoolDistrictDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "especialidad",
        "/app/especialidad",
        "idespecialidad"
      ),
      EspecialidadDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "facilitador_intec",
        "/app/facilitadorintec",
        "idfacilitadorintec"
      ),
      FacilitadorIntecDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "notificacionterritorio",
        "/establecimientoweb/notificacionterritorio",
        "idnotificacionterritorio"
      ),
      NotificationTerritoryDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "arearesponsableweb",
        "/establecimientoweb/arearesponsable",
        "idarearesponsable"
      ),
      AreaResponsabilityWebDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "arearesponsable_subweb",
        "/establecimientoweb/arearesponsable_sub",
        "id_arearesponsable_sub"
      ),
     AreaResponsableSubWebDAO
    );
    Database.register(
      new RESTStoreStrategy(
        "establecimiento_categorias",
        "/establecimientoweb/establecimiento_categorias",
        "id_establecimientos_categorias"
      ),
     EstablecimientoCategorias
    );
  }

  init(): void {
    Libby.App = this.chinoTokenEnvironmet;
    this.addSession();
    this.register();
  }
}

export const DatabaseStore = new DatabaseStoreRaw();
