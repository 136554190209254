import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class ClaimAnswerDAO extends LibbyFetchDAO {
  constructor() {
    super('respuestaterritorio', 'idrespuestaterritorio');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
