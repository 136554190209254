import { MenuItem } from "src/commons";
import { AbmUser } from "src/screens";


  
export const routes: MenuItem[] = [
  {
    key: 'abmUser',
    basePath: '/',
    target: {
      component: AbmUser,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'ABM Usuarios',
    children: [],
    default: true,
  },
];
