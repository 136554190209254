import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    overflow: "auto",
    maxHeight: "490px",
  },
  iconStyle: {
    color: "#5E5CE6",
  },
  iconStyleSub: {
    color: "#1C1C1E",
  },
  childColumnFlex: {
    borderWidth: 1,
    border: "1px solid #e0e0e0",
    borderRadius: 8,
    marginBottom: 10,
    padding: 10,
  },
  childColumnFlexDataSub: {
    borderWidth: 1,
    border: "1px solid #e0e0e0",
    borderRadius: 8,
    marginBottom: 10,
    padding: 10,
    width: "46%",
    marginRight: 10,
  },
  titleDataFirst: {
    fontSize: 13,
    color: "#8C8C8C",
  },
  subtitleDataFirst: {
    fontSize: 14,
    wordWrap: "break-word",
    width: "100%",
  },
  containerDrawer: {
    padding: 10,
  },
  widthDrawe: {
    width: 300,
  },
  infoPriorityUser: {
    marginTop: 10,
  },
  firstTitle: {
    marginBottom: 10,
    fontSize: 16,
  },
}));

const titles: any = {
  0: "Primera Prioridad",
  1: "Segunda Prioridad",
  2: "Tercera Prioridad",
};

export const DrawerPriority = ({ infoData }: any) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.containerDrawer}>
      <Grid container>
        <Typography display="block" className={classes.firstTitle}>
          Prioridades
        </Typography>
      </Grid>
      {infoData?.map((item: any, index: any) => (
        <Grid className={classes.childColumnFlex} direction="column" container>
          <Typography className={classes.titleDataFirst} display="block">
            {titles[index]}
          </Typography>
          <Typography display="block" className={classes.subtitleDataFirst}>
            {item.contenido}
          </Typography>
          <Grid container direction="row" className={classes.infoPriorityUser}>
            <Grid item xs={6}>
              <Typography className={classes.titleDataFirst} display="block">
                Por {item.author}{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.titleDataFirst} display="block">
                {item.fecha}{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
