export const tabCustom = [
    {
      title: "Pendientes",
    },
    {
      title: "Respondidos",
    },
    {
      title: "Sin novedad",
    },
  ];
