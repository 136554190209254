import moment from "moment";

export const filterEstablishmentVisit = (filterModal:any, idestablecimiento:any,searchDebounced:any) => {
  const filter = {
  estado: [
    {
      path: "estadovisita.idestadovisita",
      value: 1,
      method: "notEquals",
    },
    {
      path: "estadovisita.idestadovisita",
      value: 2,
      method: "notEquals",
    },
  ],
  modal: !filterModal.fecha
      ? {}
      : [
        {
          path: "fechainiciovisita",
          value: moment(filterModal?.fecha).format("YYYY-MM-DD"),
          method: "higher",
        },
      ],
  funcionario: !filterModal.funcionario
      ? {}
      : [
        {
          path: "usuario.idusuario",
          value: filterModal?.funcionario,
          method: "equals",
        },
      ],
  area: !filterModal.area
      ? {}
      : [
        {
          path: "arearesponsable.idarearesponsable",
          value: filterModal?.area,
        },
      ],
  nivel: !filterModal.nivel
      ? {}
      : [
        {
          path: "nivel.idnivel",
          value: filterModal?.nivel,
        },
      ],
  tipo: !filterModal.tipo
      ? {}
      : [{ path: "tipoformulario.idtipoformulario", value: filterModal?.tipo }],
  establecimiento: !idestablecimiento
      ? {}
      : [
        {
          path: "establecimiento.idestablecimiento",
          value: idestablecimiento,
        },
      ],
  };
      const searchFilter = [
        {
            path: "establecimiento.nombre",
            value: searchDebounced,
            method: "includes",
        },
        {
            path: "establecimiento.cue",
            value: searchDebounced,
            method: "includes",
        },
        {
            path: "usuario.persona.nombre",
            value: searchDebounced,
            method: "includes",
        },
        {
            path: "usuario.persona.apellido",
            value: searchDebounced,
            method: "includes",
        },
    ];
    return {
        ...filter,
        searchFilter
    };
};
