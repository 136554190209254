import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class EstablishmentPriorityDAO extends LibbyFetchDAO {
  constructor() {
    super('establecimientoprioridades', 'idestablecimientoprioridades');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
