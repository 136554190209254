import React, { useCallback, useMemo } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { grey } from "src/theme/colors";

import { useEstablishmentLibbyFetch } from "./Establishment";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      padding: 5,
      border: "none",
    },
  },
  labelStyle: {
    marginBottom: 8,
    fontSize: 13,
    fontWeight: 600,
    color: grey.medium,
  },
}));

const dummyFilter = {};

export const EstablishmentAutoComplete = ({
  multiple = false,
  autoCompleteProps,
  onChange,
  filter = dummyFilter,
  textFieldProps = dummyFilter,
  setInputValue,
  inputValue = null,
  disabled = false,
  label,
  loading,
}: any) => {
  const classes = useStyles();

  const { working, data: options = [] } = useEstablishmentLibbyFetch({
    filter,
  });

  const establishmentData = useMemo(
    () =>
      options.reduce((acum: any, establishments) => {
        establishments.localizacion.forEach((item: any) => {
          acum.push({
            ...establishments,
            localizacion: item,
          });
        });
        return acum;
      }, []),
    [options]
  );

  const onChangeAutoComplete = useCallback(
    (event, newValue) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <>
      <Autocomplete
        classes={classes}
        options={establishmentData}
        loading={working}
        freeSolo
        onChange={onChangeAutoComplete}
        disabled={disabled || loading}
        renderInput={(params) => {
          return (
            <>
              <Typography className={classes.labelStyle}>{label}</Typography>
              <TextField
                placeholder="Ingresa el numero de cue del establecimiento"
                {...params}
                variant="outlined"
                {...textFieldProps}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {working || loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            </>
          );
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option?.localizacion?.idlocalizacion.toString()}
        renderOption={(option) => {
          return <>{option.localizacion.descripcion}</>;
        }}
        getOptionSelected={(option, _value) =>
          option.idestablecimiento === _value.idestablecimiento
        }
        {...(autoCompleteProps || {})}
      />
    </>
  );
};
