import { RespuestaTerritorio } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'respuestaterritorio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idrespuestaterritorio',
      labelKey: 'respuesta',
    },
    withSelectedChips: {
      idKey: 'idrespuestaterritorio',
      labelKey: 'respuesta',
    },
  },
};

const optionsToUse = !businessOptions.ClaimAnswer ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.ClaimAnswer,
  hoc: { ...defaultOptions.hoc, ...businessOptions.ClaimAnswer.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<RespuestaTerritorio>(DAO_NAME, optionsToUse);

// hooks
export const useClaimAnswerFetchById = fetchById;
export const useClaimAnswerLibbyCall = libbyCall;
export const useClaimAnswerDAO = libbyDAO;
export const useClaimAnswerLibbyFetch = libbyFetch;

// components/hoc
export const ClaimAnswerAutocomplete = entityAutocomplete;
export const ClaimAnswerCall = renderPropCall;
export const ClaimAnswerById = renderPropFetchById;
export const ClaimAnswerSelect = simpleSelect;
export const ClaimAnswerAccordionSelector = accordionSelector;
export const ClaimAnswerSelectedChips = selectedChips;

// context
// ByIdContext
export const useClaimAnswerByIdContext = useByIdContext;
export const ClaimAnswerByIdProvider = ByIdProvider;
export const ClaimAnswerByIdContext = ByIdContext;
// GeneralContext
export const useClaimAnswerContext = useGeneralContext;
export const ClaimAnswerProvider = GeneralProvider;
export const ClaimAnswerContext = GeneralContext;
