import { ReclamoTerritorio } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'reclamoterritorio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idreclamoterritorio',
      labelKey: 'asunto',
    },
    withSelectedChips: {
      idKey: 'idreclamoterritorio',
      labelKey: 'asunto',
    },
  },
};

const optionsToUse = !businessOptions.Claim ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Claim,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Claim.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ReclamoTerritorio>(DAO_NAME, optionsToUse);

// hooks
export const useClaimFetchById = fetchById;
export const useClaimLibbyCall = libbyCall;
export const useClaimDAO = libbyDAO;
export const useClaimLibbyFetch = libbyFetch;

// components/hoc
export const ClaimAutocomplete = entityAutocomplete;
export const ClaimCall = renderPropCall;
export const ClaimById = renderPropFetchById;
export const ClaimSelect = simpleSelect;
export const ClaimAccordionSelector = accordionSelector;
export const ClaimSelectedChips = selectedChips;

// context
// ByIdContext
export const useClaimByIdContext = useByIdContext;
export const ClaimByIdProvider = ByIdProvider;
export const ClaimByIdContext = ByIdContext;
// GeneralContext
export const useClaimContext = useGeneralContext;
export const ClaimProvider = GeneralProvider;
export const ClaimContext = GeneralContext;
