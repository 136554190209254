export const filterEstablishVisits = (establishment: any) => {
  const filter = {
    visit: [
      {
        path: "estadovisita.idestadovisita",
        value: 1,
        method: "notEquals",
      },
    ],
    establishmentVisit: !establishment?.idestablecimiento
      ? [{}]
      : [
          {
            path: "establecimiento.idestablecimiento",
            value: establishment?.idestablecimiento,
          },
        ],
  };

  return filter;
};
