import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class NotificationTerritoryDAO extends LibbyFetchDAO {
  constructor() {
    super("notificacionterritorio", "idnotificacionterritorio");
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
