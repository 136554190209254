import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class SectionDAO extends LibbyFetchDAO {
  constructor() {
    super("seccion", "idseccion");
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
