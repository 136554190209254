/* eslint-disable global-require */

import directivo from './Directivos.png';
import coordinadorDeArea from './CoordinadorDeArea.png';
import preceptor from './Preceptor.png';
import tutor from './Tutor.png';
import asesorPedagogico from './AsesorPedagogico.png';
import docente from './Docente.png';
import loading from './loading.gif';
import biologia from './imagesCards/Biologia.png';
import artesVisuales from './imagesCards/Artes-Visuales.png';
import imageTest from './imagesCards/primary-lightBlue-test.png';
import artesMusica from './imagesCards/Artes-Musica.png';
import artesTeatro from './imagesCards/Artes-Teatro.png';
import defaultMateria from './imagesCards/Default.png';
import formacionEticaCiudadana from './imagesCards/Form-Etica-y-Ciudadana.png';
import educacionFisica from './imagesCards/Educacion-Fisica.png';
import educacionTecnologica from './imagesCards/Educ-Tecnologica.png';
import geografia from './imagesCards/Geografia.png';
import historia from './imagesCards/Historia.png';
import lenguaYLiteratura from './imagesCards/Lengua-y-Literatura.png';
import nubes from './nubes-png.png';

const roles: Record<string, string> = {
  '1': directivo,
  '2': docente,
  '3': coordinadorDeArea,
  '5': tutor, // equipoDeConduccion
  '4': asesorPedagogico,
  '7': preceptor,
  '8': tutor,
};

const materias = {
  biologia,
  artesVisuales,
  imageTest,
  artesMusica,
  artesTeatro,
  formacionEticaCiudadana,
  educacionFisica,
  educacionTecnologica,
  geografia,
  historia,
  lenguaYLiteratura,
  fallback: defaultMateria,
};

const nubesBackground: Record<string, any> = {
  nubes,
};

export { loading, roles, materias, nubesBackground };
