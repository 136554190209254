import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'transporteescolar';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idtransporteescolar',
      labelKey: 'seccion.nombre',
    },
    withSelectedChips: {
      idKey: 'idtransporteescolar',
      labelKey: 'seccion.nombre',
    },
  },
};

const optionsToUse = !businessOptions.TransportSchool ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.TransportSchool,
  hoc: { ...defaultOptions.hoc, ...businessOptions.TransportSchool.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useTransportSchoolFetchById = fetchById;
export const useTransportSchoolLibbyCall = libbyCall;
export const useTransportSchoolDAO = libbyDAO;
export const useTransportSchoolLibbyFetch = libbyFetch;

// components/hoc
export const TransportSchoolAutocomplete = entityAutocomplete;
export const TransportSchoolCall = renderPropCall;
export const TransportSchoolById = renderPropFetchById;
export const TransportSchoolSelect = simpleSelect;
export const TransportSchoolAccordionSelector = accordionSelector;
export const TransportSchoolSelectedChips = selectedChips;

// context
// ByIdContext
export const useTransportSchoolByIdContext = useByIdContext;
export const TransportSchoolByIdProvider = ByIdProvider;
export const TransportSchoolByIdContext = ByIdContext;
// GeneralContext
export const useTransportSchoolContext = useGeneralContext;
export const TransportSchoolProvider = GeneralProvider;
export const TransportSchoolContext = GeneralContext;
