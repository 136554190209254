import { Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useField } from 'react-final-form-hooks';
import { DatePicker, useFormInputStyles } from 'src/app/components';
//import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';

export interface FormDatePickerProps {
  form: any;
  name: string;
  label: string;
  clearable?: boolean;
  placeholder?: string;
  disablePast?: boolean;
  fullWidth?: boolean;
  className?: any;
  disableFuture?: boolean;
}

export const FormDatePicker: FunctionComponent<FormDatePickerProps> = ({
  name,
  clearable = true,
  placeholder,
  disablePast,
  form,
  label,
  fullWidth = false,
  className = {},
  disableFuture = false
}) => {
  const field = useField(name, form);
  const classes = useFormInputStyles();
  const date = (field.input.value && new Date(field.input.value)) || null;
  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      <DatePicker
      fullWidth={fullWidth}
        value={date}
        clearable={clearable}
        placeholder={placeholder}
        disablePast={disablePast}
        error={!!field.meta.error}
        helperText={field.meta.error}
        onChange={(_date) => field.input.onChange(_date?.toDate())}
        className={className}
        disableFuture={disableFuture}
      />
    </>
  );
};
