import React, { useMemo } from "react";
import { useQuestionLibbyFetch } from "src/app/business";
import { templates } from "./templates";

export type childrenFunc = ({
  template,
  working,
  initialValues,
}: {
  template?: any;
  working: boolean;
  initialValues: any;
}) => JSX.Element;

export const CreateFormEdit = ({ children, editIdForm }: any) => {
  const filterForm = useMemo(
    () => ({
      filterFormId: [{ path: "formulario.id_formulario", value: editIdForm }],
    }),
    [editIdForm]
  );

  const { data = [], working, reFetch } = useQuestionLibbyFetch({
    filter: filterForm,
    limit: 1,
  });

  const reformTemplate = useMemo(
    () => ({
      template: {
        question: {
          title_question: {
            value: decodeURI(data[0]?.template?.question?.title_question.value),
          },
          question_first: {
            type: data[0]?.template?.question?.question_first.type,
            value: decodeURI(data[0]?.template?.question?.question_first.value),
          },
          question_alternative: data[0]?.template?.question?.question_alternative.map(
            (item: any) => ({ type: item.type, value: decodeURI(item.value) })
          ),
        },
      },
    }),
    [data]
  );

  return (
    <>
      {children({
        template: templates,
        data: reformTemplate.template,
        working: working,
        question: data[0],
        reFetch: reFetch,
      })}
    </>
  );
};
