import { Persona } from "src/app/models/business/Persona";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'persona';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idpersona',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idpersona',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Person ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Person,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Person.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Persona>(DAO_NAME, optionsToUse);

// hooks
export const usePersonFetchById = fetchById;
export const usePersonLibbyCall = libbyCall;
export const usePersonDAO = libbyDAO;
export const usePersonLibbyFetch = libbyFetch;

// components/hoc
export const PersonAutocomplete = entityAutocomplete;
export const PersonCall = renderPropCall;
export const PersonById = renderPropFetchById;
export const PersonSelect = simpleSelect;
export const PersonAccordionSelector = accordionSelector;
export const PersonSelectedChips = selectedChips;

// context
// ByIdContext
export const usePersonByIdContext = useByIdContext;
export const PersonByIdProvider = ByIdProvider;
export const PersonByIdContext = ByIdContext;
// GeneralContext
export const usePersonContext = useGeneralContext;
export const PersonProvider = GeneralProvider;
export const PersonContext = GeneralContext;
