import { useHistory } from "react-router";
import moment from "moment";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import WorkIcon from "@material-ui/icons/Work";
import InfoIcon from "@material-ui/icons/Info";
import PeopleIcon from "@material-ui/icons/People";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import FaceIcon from "@material-ui/icons/Face";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import { makeStyles } from "@material-ui/core";
import { useTabBarSetValue } from "src/commons";

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    color: "#5E5CE6",
  },
  iconStyleSub: {
    color: "#1C1C1E",
  },
}));
const fallback = "-";

const getDrivingEquipment = (teachers = []) => {

  return teachers?.reduce((acum: any, teacher:any, currentIndex) => {
    const {apellido_y_nombre, cargo, situacion_de_revista, fecha_de_inicio} = teacher;
    const lowercaseCharge = cargo.toLowerCase();
    const firstWordCharge = lowercaseCharge.split(" ")[0];
    acum.director = firstWordCharge === "director" && situacion_de_revista === "Titular" ? `${apellido_y_nombre}` : firstWordCharge === "director" && !acum.director ? `${apellido_y_nombre}` : acum.director || "";
    acum.vicedirector = firstWordCharge === "vicedirector" && situacion_de_revista === "Titular" ? `${apellido_y_nombre}` : firstWordCharge === "vicedirector" && !acum.vicedirector ? `${apellido_y_nombre}` : acum.vicedirector || "";
    acum.secretario = (lowercaseCharge.includes(" secretario") || firstWordCharge === "secretario") && !acum.secretario ? `${apellido_y_nombre}` : acum.secretario || "";
    return acum;
  }, {})
}

const SERVICE_COUNTERS_MAP: any = {
  1: "COUNT_BECAS_MEDIA",
  2: "COUNT_BECAS_ALIMENTARIAS",
  3: "COUNT_ENTREGA_PSBA",
  4: "COUNT_ENTREGA_PSBA_NOTEBOOK",
  5: "COUNT_ENTREGA_PSBA_TABLET",
  7: "COUNT_TRANSPORTE_ESCOLAR",
};

const getAlumnosCantidad = (student: any = [], nivel: any) => {
  const filterAlumn = student.filter(
    (item: any, index: any) =>
      item?.seccion?.unidadeducativa?.nivel?.idnivel === nivel
  );
  return filterAlumn.length;
};

const getServiceCounters = (students = []) =>
  students.reduce(
    (acum: any, { alumno }: any) => {
      const {
        persona: { tiposervicio: tiposervicios },
      } = alumno;
      tiposervicios.forEach(({ idtiposervicio }: any) => {
        acum[SERVICE_COUNTERS_MAP[idtiposervicio]] =
          (acum[SERVICE_COUNTERS_MAP[idtiposervicio]] || 0) + 1;
      });
      return acum;
    },
    {
      COUNT_BECAS_MEDIA: 0,
      COUNT_BECAS_ALIMENTARIAS: 0,
      COUNT_ENTREGA_PSBA: 0,
      COUNT_ENTREGA_PSBA_NOTEBOOK: 0,
      COUNT_ENTREGA_PSBA_TABLET: 0,
    }
  );

export const getNivel = (sections = []) => {
  if (!sections.length) {
    return null;
  }
  const nivels = sections.reduce((acum: any, section: any) => {
    if (
      !section.unidadeducativa.nivel.descripcion ||
      acum.includes(section.unidadeducativa.nivel.descripcion)
    ) {
      return acum;
    }
    return [...acum, section.unidadeducativa.nivel.descripcion];
  }, []);
  return nivels.join(", ");
};

export const useInformationRow = (
  setState: any,
  setDrawer: any,
  row: any,
  useData: any,
  setSelectDrawe: any
) => {
  const classes = useStyles();
  const history = useHistory();
  const setTabBarValue = useTabBarSetValue();
  // setTabBarValue(4, { nombreSeccion }
  //  const { params } = useTabBarValue();
  //const setTabBarValue = useTabBarSetValue();

  const onViewMap = () => {
    setTabBarValue(1, {
      establishment: row,
      lat: row.localizacion.domicilio.latlon.x,
      lng: row.localizacion.domicilio.latlon.y,
    });
  };

  const {
    COUNT_BECAS_MEDIA,
    COUNT_BECAS_ALIMENTARIAS,
    COUNT_ENTREGA_PSBA,
    COUNT_ENTREGA_PSBA_NOTEBOOK,
    COUNT_ENTREGA_PSBA_TABLET,
  } = getServiceCounters(useData?.studentView);

  const { director, vicedirector, secretario } = getDrivingEquipment(
    useData?.activeTeachers
  );
  const dateEstablishmentAntiguety = moment(
    useData?.padronData[0]?.alta_est,
    "YYYYMMDD"
  ).fromNow(true);

  const handleDrawer = (id: number) => {
    setSelectDrawe(id);
    setDrawer({ position: "right", open: true });
  };

  const containerPropsData = [
    {
      title: "Registro Actividades",
      list: [
        {
          title: "Visitas",
          onClick: () => setState({ position: "left", open: true }),
          icon: CalendarTodayIcon,
          className: classes.iconStyle,
        },
        {
          title: "Reclamos",
          onClick: () =>
            history.push(`establecimientos-lista/${row.idestablecimiento}/1`),
          icon: ErrorOutlineIcon,
          className: classes.iconStyle,
        },
        {
          title: "Observaciones",
          onClick: () =>
            history.push(`establecimientos-lista/${row.idestablecimiento}/2`),
          icon: VisibilityIcon,
          className: classes.iconStyle,
        },
      ],
    },
    {
      title: "Datos del establecimiento",
      list: [
        {
          title: "General",
          icon: InfoIcon,
          onClick: () => handleDrawer(0),
          className: classes.iconStyleSub,
        },
        {
          title: "Conducción",
          icon: WorkIcon,
          onClick: () => handleDrawer(1),
          className: classes.iconStyleSub,
        },
        {
          title: "Prioridades",
          icon: VolumeDownIcon,
          onClick: () => handleDrawer(10),
          className: classes.iconStyleSub,
        },
        {
          title: "Docentes",
          icon: FaceIcon,
          onClick: () => handleDrawer(2),
          className: classes.iconStyleSub,
        },
        {
          title: "No docentes",
          icon: PeopleIcon,
          onClick: () => handleDrawer(3),
          className: classes.iconStyleSub,
        },
        {
          title: "Becas",
          icon: PeopleIcon,
          onClick: () => handleDrawer(6),
          className: classes.iconStyleSub,
        },
        {
          title: "Estudiantes",
          icon: ChildCareIcon,
          onClick: () => handleDrawer(4),
          className: classes.iconStyleSub,
        },
        {
          title: "Comedor",
          icon: LocalDiningIcon,
          onClick: () => handleDrawer(5),
          className: classes.iconStyleSub,
        },
        {
          title: "Transporte",
          icon: DirectionsBusIcon,
          onClick: () => handleDrawer(7),
          className: classes.iconStyleSub,
        },
      ],
    },
  ];

  const informationData = [
    {
      title: "General",
      subtitle: "Establecimiento",
      dataFirst: [
        {
          title: "N°, D.E. y Nombre",
          subtitle: `${row.nombre} | ${row.localizacion?.domicilio.codigopostalbarrio.codigopostal}`,
        },
        {
          title: "Domicilio, Barrio y Comuna",
          subtitle: `${row.localizacion?.domicilio.calle} ${row.localizacion?.domicilio.altura}`,
          tercerTitle: `Barrio ${
            row?.localizacion?.domicilio?.codigopostalbarrio?.barrio
              ?.barrio || "-"
          }`,
          cuartoTitle: `Comuna ${
            row?.localizacion?.domicilio?.codigopostalbarrio?.barrio?.comuna
              ?.nombre || "-"
          }`,
        },
      ],
      dataSub: [
        {
          titleSub: "Oferta y Jornada",
          subtitleSub: `${getNivel(useData.sections || {})} y ${
            row?.tipoestablecimiento?.descripcion
          }`,
        },
        {
          titleSub: "Especialidad",
          subtitleSub:
            useData?.especialidad.map(
              (item: any, index: any) => `${item.especialidad},`
            ) || "-",
        },
        {
          titleSub: "Escuela del futuro",
          subtitleSub: !useData?.establishmentFutureData?.length ? "No" : "Si",
        },
        {
          titleSub: "Escuela Verde",
          subtitleSub: !useData?.schoolgreen?.length ? "No" : "Si",
        },
        {
          titleSub: "Antigüedad",
          subtitleSub: !useData?.padronData?.length
            ? "-"
            : `${dateEstablishmentAntiguety}`,
        },

        {
          titleSub: "Facilitador INTEC",
          subtitleSub: !useData?.intec?.length ? "No" : "Si",
        },
      ],
    },
    {
      title: "Conducción",
      subtitle: "Directivos",
      dataFirst: [
        {
          title: "Dirección",
          subtitle: director || fallback,
        },
      ],
      dataSub: [
        {
          titleSub: "Vicedirección",
          subtitleSub: vicedirector || fallback,
        },
        {
          titleSub: "Secretario/a",
          subtitleSub: secretario || fallback,
        },
      ],
    },
    {
      title: "Docentes",
      subtitle: "Establecimiento",
      dataFirst: [
        {
          title: "Cantidad de docentes",
          subtitle: useData?.teacherView?.length || 0,
        },
      ],
      dataSub: [],
    },
    {
      title: "Equipo No Docente",
      subtitle: "Establecimiento",
      dataFirst: [
        {
          title: "Cantidad de no docentes",
          subtitle: useData?.infoPersonalNoDocent?.length || 0,
        },
      ],
      dataSub: [],
    },
    {
      title: "Estudiantes",
      subtitle: "Matrícula",
      dataFirst: [],
      dataSub: [
        {
          titleSub: "Nivel inicial",
          subtitleSub: `${getAlumnosCantidad(useData?.studentMovement, 1)}`,
        },
        {
          titleSub: "Nivel Primaria",
          subtitleSub: `${getAlumnosCantidad(useData?.studentMovement, 2)}`,
        },
        {
          titleSub: "Nivel Media",
          subtitleSub: `${getAlumnosCantidad(useData?.studentMovement, 3)}`,
        },
        {
          titleSub: "Nivel Superior",
          subtitleSub: `${getAlumnosCantidad(useData?.studentMovement, 4)}`,
        },
      ],
    },
    {
      title: "Comedor",
      subtitle: "Establecimiento",
      dataFirst: [],
      dataSub: [
        {
          titleSub: "Inscriptos",
          subtitleSub: "-",
        },
        {
          titleSub: "Servicio",
          subtitleSub: "-",
        },
        {
          titleSub: "Becados",
          subtitleSub: "-",
        },
        {
          titleSub: "Usa App Canasta",
          subtitleSub: "-",
        },
      ],
    },
    {
      title: "Becas",
      subtitle: "Totales",
      dataFirst: [],
      dataSub: [
        {
          titleSub: "Becas Ciudad - Media",
          subtitleSub: COUNT_BECAS_MEDIA,
        },
        {
          titleSub: "Beca Alimentaria",
          subtitleSub: COUNT_BECAS_ALIMENTARIAS,
        },
        {
          titleSub: "Entrega PSBA",
          subtitleSub: COUNT_ENTREGA_PSBA,
        },
        {
          titleSub: "Entrega PSBA - Notebook",
          subtitleSub: COUNT_ENTREGA_PSBA_NOTEBOOK,
        },
        {
          titleSub: "Entrega PSBA - Tablet",
          subtitleSub: COUNT_ENTREGA_PSBA_TABLET,
        },
      ],
    },
    {
      title: "Transporte",
      subtitle: "Totales",
      dataFirst: [
        {
          title: "Estudiantes que utilizan el servicio",
          subtitle: useData?.transportSchool?.length || 0,
        },
      ],
      dataSub: [],
    },
  ];

  return {
    info: informationData,
    containerPropsData,
    onViewMap,
  };
};
