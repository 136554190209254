import React, { useState, useReducer, useCallback } from "react";
import { Grid } from "@material-ui/core";
import { useForm } from "react-final-form-hooks";
import { makeStyles } from "@material-ui/styles";
import { customFormDialog, FooterForm } from "src/commons";
import { useSnackbar } from "notistack";
import { SelectCategory, SelectEstablishment } from "./components";
import { useEstablecimientoCategoriasDAO } from "src/app/business/business/EstablecimientoCategorias";
import { categoryMobileApp } from "../../helpers";

const modalTableUserStyles = makeStyles(() => ({
  container: {
    padding: "20px 30px",
  },
  containerForm: {
    alignItems: "center",
  },
  allEstablish: {
    marginTop: 5,
    textAlign: "center",
  },
  tableContainer: {
    maxHeight: "350px",
  },
}));

const reducer = (prev: any, action: any) => {
  if (
    prev?.some(
      (item: any) => item.idestablecimiento === action.idestablecimiento
    )
  ) {
    action = prev.filter(
      (itemprev: any) => itemprev.idestablecimiento !== action.idestablecimiento
    );
    return action;
  }
  return Array.isArray(action) ? action : [...prev, action];
};



export const ModalAssignEstablishmentArea = ({
  reFetch,
  isValues = {}
}: any) => {
  const classes = modalTableUserStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [establishmentSelect, setEstablishmentSelect] = useReducer(reducer, []);
  const [activeStep, setActiveStep] = useState(0);
  const formValues = isValues;



  const EstablecimientoCategoriasDAO = useEstablecimientoCategoriasDAO();

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const categorias_select = JSON.stringify(values);

        const areaEstablishment = establishmentSelect.map((item: any) => ({
          id_localizacion: item.localizacion.cueanexo,
          categorias: categorias_select,
        }));
        await EstablecimientoCategoriasDAO.aspect("aspect-web").save({
          categorias: areaEstablishment,
        });

        enqueueSnackbar("Se han desactivado las categorías", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar("Ya tienes un establecimiento asignado en la lista", {
          variant: "error",
        });
      } finally {
        reFetch();
        setLoading(false);
        customFormDialog.handleCancel();
      }
    },
    [establishmentSelect, EstablecimientoCategoriasDAO, enqueueSnackbar, reFetch]
  );

  const { form, handleSubmit } = useForm({
    initialValues: formValues,
    onSubmit,
  });

  const buttonConfig: any = {
    0: [
      {
        title: "Cancelar",
        handleOnClick: () => customFormDialog.handleCancel(),
        type: "secondary",
        size: "small",
      },
      {
        title: "Continuar",
        handleOnClick: () =>
          setActiveStep((prevActiveStep) => prevActiveStep + 1),
        size: "small",
        disabled: !establishmentSelect.length,
      },
    ],
    1: [
      {
        title: "Cancelar",
        handleOnClick: () => customFormDialog.handleCancel(),
        type: "secondary",
        size: "small",
      },
      {
        title: "Atrás",
        handleOnClick: () =>
          setActiveStep((prevActiveStep) => prevActiveStep - 1),
        type: "secondary",
        size: "small",
        customStyle: { border: "1px solid transparent" },
      },
      {
        title: "Guardar",
        handleOnClick: () => null,
        size: "small",
        disabled: loading,
        typeButton: "submit",
      },
    ],
  };

  const renderSteps = [
    {
      render: (
        <SelectEstablishment
          setEstablishmentSelect={setEstablishmentSelect}
          establishmentSelect={establishmentSelect}
          loading={loading}
        />
      ),
    },
    {
      render: <SelectCategory category={categoryMobileApp} form={form} />,
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Grid container className={classes.container}>
        {renderSteps[activeStep].render}
        <FooterForm buttonConfig={buttonConfig[activeStep]} spacing={1} />
      </Grid>
    </form>
  );
};
