export const column = [
  {
    id: "name",
    label: "Usuario",
    width: "5%",
    orderById: "persona.nombre",
  },
  {
    id: "document",
    label: "N° de documento",
    width: "5%",
    orderById: "persona.documento",
  },
  {
    id: "rol",
    label: "Rol",
    width: "5%",
    orderById: "rol.descripcion",
  },
  {
    id: "icon",
    label: "Gestionar",
    width: "4%",
    hideSortIcon: true,
    noSort: true,
    style: { textAlign: "left" },
  },
];
