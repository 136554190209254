import React, { useState, useMemo } from "react";
import { useHistory } from "react-router";
import {
  HeaderTerri,
  InfoTable,
  Loading,
  useDebounce,
  useOrderByState,
  useRouteScreenTitle,
} from "src/commons";
import { useListFormLogic } from "./hooks";

export const ListForm = () => {
  useRouteScreenTitle("Formularios");
  const history = useHistory();
  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 1000);

  const filterSearch = useMemo(
    () => ({
      tableFilter: [
        {
          path: "nombre_de_formulario",
          value: searchDebounced,
          method: "includes",
        },
        {
          path: "estado_formulario.descripcion",
          value: searchDebounced,
          method: "includes",
        },
      ],
    }),
    [searchDebounced]
  );

  const { orderBy, direction, onOrderByDirectionChange } = useOrderByState(
    "estado_formulario.id_estado_formulario",
    "asc"
  );

  const {
    rows,
    column,
    loading,
    loadingExternal,
    fetchMore,
  } = useListFormLogic({
    orderBy,
    direction,
    filterSearch,
  });

  return (
    <HeaderTerri
      rightLayout
      searchValue={search}
      setSearchValue={setSearch}
      titleButton="Nuevo formulario"
      handleButton={() => history.push("formularios/nuevo-formulario")}
      placeholder="Buscar formulario por nombre"
    >
      {loadingExternal ? (
        <Loading />
      ) : (
        <InfoTable
          working={loading}
          rows={rows || []}
          columns={column}
          onBottomScroll={fetchMore}
          rowIdKey="id_formulario"
          direction={direction}
          orderBy={orderBy}
          onSortChange={onOrderByDirectionChange}
        />
      )}
    </HeaderTerri>
  );
};
