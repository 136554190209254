import React, { useState, useCallback, useMemo } from "react";
import moment from "moment";
import { Grid, Radio } from "@material-ui/core";
import { useForm, useField } from "react-final-form-hooks";
import { useSnackbar } from "notistack";
import {
  customFormDialog,
  FooterForm,
  MainInput,
  SimpleSelect,
} from "src/commons";
import {
  SexoSelect,
  TypeDocumentSelect,
  useFuncionarioLibbyFetch,
  usePersonDAO,
  useUserEstablishmentLibbyFetch,
} from "src/app/business";
import { grey, primary } from "src/theme/colors";
import { useAccountDAO } from "src/app/business/Account";
import { FormDatePicker } from "src/lib/templates";
import { makeStyles } from "@material-ui/styles";
import { regexValidator } from "src/utils/regex";
const customStyles = {
  customStyleTitle: { color: grey.medium, position: "relative", top: "-12px" },
  customStyleTitleEstableshiment: {
    color: grey.medium,
    position: "relative",
    top: "-12px",
    border: "none",
  },
  containerGrid: { paddingRight: 20, paddingLeft: 20, paddingBottom: 17 },
  inputGrid: { paddingTop: 25 },
  footer: { marginTop: 20 },
};

const materialStyles = makeStyles(() => ({
  datePicker: {
    paddingLeft: 0,
    "& .MuiOutlinedInput-root": {
      border: `1px solid ${primary.white}`,
    },
  },
}));

const arrDependecia = [
  {
    title: "SSGEFYAR",
    id: 1,
  },
  {
    title: "SSCPEE",
    id: 2,
  },
  {
    title: "SSCDOC",
    id: 3,
  },
  {
    title: "UEICEE",
    id: 4,
  },
  {
    title: "SSTEYS",
    id: 5,
  },
  {
    title: "DGCLEI",
    id: 6,
  },
  {
    title: "UM",
    id: 7,
  },
  {
    title: "SSAALV",
    id: 8,
  },
];

export const ModalCreateUser = ({
  isValues = {},
  idusuario,
  idperson,
  reFetch,
}: any) => {
  const formValues = isValues;
  const classes = materialStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorDependency, setErrorDependency] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const filterUserEstablishment = useMemo(
    () => ({
      id: !idusuario
        ? [{}]
        : [
            {
              path: "idusuario",
              value: idusuario,
            },
          ],
    }),
    [idusuario]
  );

  const { data: dataEstablishment } = useUserEstablishmentLibbyFetch({
    filter: filterUserEstablishment,
    orderBy: "idusuarioestablecimiento",
    direction: "asc",
  });

  const accountDAO = useAccountDAO();
  const PersonDAO = usePersonDAO();

  const validate = (values: any) => {
    const errors = {};
    if (
      !values.name_user ||
      !values.surname ||
      !values.sexo ||
      !values.email ||
      !values.type_document ||
      !values.document ||
      !values.date ||
      !values.rol ||
      !values.iddependence
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    return errors;
  };

  const onSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const personSave = {
          nombre: values.name_user,
          apellido: values.surname,
          sexo: values.sexo,
          tipodocumento: { idtipodocumento: values.type_document },
          documento: values.document,
          fechanacimiento: moment(values.date).format(),
        };

        const personEdit = {
          ...personSave,
          idpersona: idperson,
        };

        const objectPerson = !idperson ? personSave : personEdit;

        const { idpersona } = await PersonDAO.aspect("aspect-web").save(
          objectPerson
        );

        const userSave = {
          email: values.email,
          username: values.email,
          admin: 0,
          id_dependence: values.iddependence,
          password: `${values.name_user}${values.surname}`.replace(/ /g, ""),
          rol: { idrol: values.rol },
          persona: { idpersona },
          disableduser: false,
        };

        const userEdit = {
          ...userSave,
          idusuario,
          persona: { idpersona: idperson },
        };
        const { password, ...userEdits } = userEdit;
        const objectUser = !idusuario ? userSave : userEdits;
        await accountDAO.aspect("aspect-web").save(objectUser);
        const textEnqueue = !idusuario
          ? "Se ha creado el usuario con exito!"
          : "Se ha modificado el usuario con exito!";
        enqueueSnackbar(textEnqueue, {
          variant: "success",
        });
        reFetch();
      } catch (e) {
        console.log(e);
        enqueueSnackbar("Se ha producido un error intentelo nuevamente", {
          variant: "error",
        });
      } finally {
        setLoading(false);
        customFormDialog.handleCancel();
      }
    },
    [PersonDAO, accountDAO, reFetch, enqueueSnackbar, idperson, idusuario]
  );

  const { form, handleSubmit, submitting, pristine } = useForm({
    initialValues: formValues,
    onSubmit,
    validate,
  });

  const nameUserForm = useField("name_user", form);
  const surNameForm = useField("surname", form);
  const sexoForm = useField("sexo", form);
  const typeDocumentForm = useField("type_document", form);
  const documentForm = useField("document", form);
  const dateForm = useField("date", form);
  const emailForm = useField("email", form);
  const radioForm = useField("rol", form);
  const areaResponsableForm = useField("iddependence", form);

  const filterUserEmail = useMemo(
    () => ({
      emailUsuario: [
        {
          path: "email",
          value: emailForm.input.value,
        },
      ],
    }),
    [emailForm.input.value]
  );

  const { data: userEmailVerification = [] } = useFuncionarioLibbyFetch({
    filter: filterUserEmail,
    enabled: !errorEmail,
  });

  const buttonConfig = [
    {
      title: "Cancelar",
      handleOnClick: () => customFormDialog.handleCancel(),
      type: "secondary",
      size: "small",
    },
    {
      title: "Guardar",
      handleOnClick: () => null,
      size: "small",
      typeButton: "submit",
      disabled:
        disabled ||
        submitting ||
        pristine ||
        loading ||
        errorEmail ||
        userEmailVerification.length > 0 ||
        errorDependency,
    },
  ];

  /*   const validateNumber = (e: React.FormEvent<EventTarget>, form: any) => {
    const target = e.target as HTMLInputElement;
    const valueNumber = regexValidator.onlyNumber.test(target.value);
    if (valueNumber) {
      form.input.onChange(target.value);
    }
  }; */

  const validateEmail = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    const valueEmail = regexValidator.emailBueEdu.test(target.value);
    if (valueEmail) {
      setErrorEmail(false);
    } else {
      setErrorEmail(true);
    }
    emailForm.input.onChange(target.value);
  };

  const validateDependency = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;

    const dependeciValidator = target.value;

    if (dependeciValidator) {
      setErrorDependency(false);
    } else {
      setErrorDependency(true);
    }
    areaResponsableForm.input.onChange(target.value);
  };

  const arrSelect = [
    {
      input: true,
      label: "Nombre",
      placeholder: "Ingresa el nombre",
      name: nameUserForm.input.name,
      handleChange: nameUserForm.input.onChange,
      value: nameUserForm.input.value,
      maxLengths: 20,
    },
    {
      input: true,
      label: "Apellido",
      placeholder: "Ingresa el apellido",
      inputProps: { min: 0 },
      name: surNameForm.input.name,
      handleChange: surNameForm.input.onChange,
      value: surNameForm.input.value,
      maxLength: 20,
    },
    {
      input: false,
      component: SexoSelect,
      title: "Sexo",
      placeholder: "Seleccioná sexo",
      name: sexoForm.input.name,
      handleChange: sexoForm.input.onChange,
      value: sexoForm.input.value,
      valueKey: "idsexo",
    },
    {
      input: false,
      component: TypeDocumentSelect,
      title: "Tipo de documento",
      placeholder: "Seleccioná tipo",
      name: typeDocumentForm.input.name,
      handleChange: typeDocumentForm.input.onChange,
      value: typeDocumentForm.input.value,
      valueKey: "idtipodocumento",
    },
    {
      input: true,
      label: "N° de documento",
      placeholder: "Ingresá N° de documento",
      inputProps: { min: 0 },
      name: documentForm.input.name,
      handleChange: documentForm.input.onChange,
      value: documentForm.input.value,
      maxLengths: 10,
    },
    {
      input: false,
      component: FormDatePicker,
      label: "Fecha de nacimiento",
      placeholder: "dd/mm/aaaa",
      name: dateForm.input.name,
      onChange: dateForm.input.onChange,
      value: dateForm.input.value,
      form: form,
      fullWidth: true,
      disableFuture: true,
      classNames: classes.datePicker,
    },
    {
      input: true,
      label: "Email",
      placeholder: "Ingrese su email @bue.edu.ar",
      inputProps: { min: 0 },
      name: emailForm.input.name,
      handleChange: validateEmail,
      value: emailForm.input.value,
      maxLengths: 50,
      helperText: errorEmail
        ? "Email invalido"
        : userEmailVerification.length > 0
        ? "Correo Existente"
        : "",
      error: errorEmail || userEmailVerification.length > 0,
    },
    {
      input: false,
      component: SimpleSelect,
      title: "Dependencia",
      placeholder: "Seleccioná dependecia",
      name: areaResponsableForm.input.name,
      handleChange: validateDependency,
      value: areaResponsableForm.input.value,
      content: arrDependecia,
      valueKey: "id",
      labelKey: "title",
      helperText: errorDependency && "Debe Seleccionar una dependencia",
      error: errorDependency,
    },
  ];

  return (
    <Grid container style={{ width: "95%", margin: "auto", marginTop: 10 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} style={customStyles.containerGrid}>
          {arrSelect.map((e: any) =>
            e.input ? (
              <Grid item xs={6} style={customStyles.inputGrid}>
                <MainInput
                  {...e}
                  handleChange={e.handleChange}
                  value={e.value}
                  fullWidth
                  customStyleLabel={customStyles.customStyleTitle}
                />
              </Grid>
            ) : (
              <Grid item xs={6}>
                {e.component({ ...e })}
              </Grid>
            )
          )}
          {!!idusuario && (
            <Grid item xs={6} style={customStyles.inputGrid}>
              <MainInput
                value={dataEstablishment?.length as number}
                handleChange={() => null}
                disabled={true}
                label="Cantidad de establecimientos"
                placeholder=""
                customStyleLabel={customStyles.customStyleTitleEstableshiment}
              />
            </Grid>
          )}
        </Grid>
        <Grid style={{ marginLeft: 10 }}>
          <Radio
            checked={parseInt(radioForm.input.value) === 1}
            value={1}
            onChange={radioForm.input.onChange}
          />
          Funcionario
          <Radio
            checked={parseInt(radioForm.input.value) === 2}
            value={2}
            onChange={radioForm.input.onChange}
          />
          Mesa Territorial
        </Grid>
        <FooterForm
          buttonConfig={buttonConfig}
          spacing={1}
          customStyle={customStyles.footer}
        />
      </form>
    </Grid>
  );
};
