import { Formulario } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'formulario';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'id_formulario',
      labelKey: 'nombre_de_formulario',
    },
    withSelectedChips: {
      idKey: 'id_formulario',
      labelKey: 'nombre_de_formulario',
    },
  },
};

const optionsToUse = !businessOptions.Formulario ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Formulario,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Formulario.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Formulario>(DAO_NAME, optionsToUse);

// hooks
export const useFormularioFetchById = fetchById;
export const useFormularioLibbyCall = libbyCall;
export const useFormularioDAO = libbyDAO;
export const useFormularioLibbyFetch = libbyFetch;

// components/hoc
export const FormularioAutocomplete = entityAutocomplete;
export const FormularioCall = renderPropCall;
export const FormularioById = renderPropFetchById;
export const FormularioSelect = simpleSelect;
export const FormularioAccordionSelector = accordionSelector;
export const FormularioSelectedChips = selectedChips;

// context
// ByIdContext
export const useFormularioByIdContext = useByIdContext;
export const FormularioByIdProvider = ByIdProvider;
export const FormularioByIdContext = ByIdContext;
// GeneralContext
export const useFormularioContext = useGeneralContext;
export const FormularioProvider = GeneralProvider;
export const FormularioContext = GeneralContext;
