import { ReclamoTerritorio } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'distritoescolar';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'iddistritoescolar',
      labelKey: 'nombre',
    },
    withSelectedChips: {
      idKey: 'iddistritoescolar',
      labelKey: 'nombre',
    },
  },
};

const optionsToUse = !businessOptions.SchoolDistrict ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.SchoolDistrict,
  hoc: { ...defaultOptions.hoc, ...businessOptions.SchoolDistrict.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ReclamoTerritorio>(DAO_NAME, optionsToUse);

// hooks
export const useSchoolDistrictFetchById = fetchById;
export const useSchoolDistrictLibbyCall = libbyCall;
export const useSchoolDistrictDAO = libbyDAO;
export const useSchoolDistrictLibbyFetch = libbyFetch;

// components/hoc
export const SchoolDistrictAutocomplete = entityAutocomplete;
export const SchoolDistrictCall = renderPropCall;
export const SchoolDistrictById = renderPropFetchById;
export const SchoolDistrictSelect = simpleSelect;
export const SchoolDistrictAccordionSelector = accordionSelector;
export const SchoolDistrictSelectedChips = selectedChips;

// context
// ByIdContext
export const useSchoolDistrictByIdContext = useByIdContext;
export const SchoolDistrictByIdProvider = ByIdProvider;
export const SchoolDistrictByIdContext = ByIdContext;
// GeneralContext
export const useSchoolDistrictContext = useGeneralContext;
export const SchoolDistrictProvider = GeneralProvider;
export const SchoolDistrictContext = GeneralContext;
