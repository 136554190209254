import { Sexo } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'sexo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idsexo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idsexo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Sexo ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Sexo,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Sexo.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Sexo>(DAO_NAME, optionsToUse);

// hooks
export const useSexoFetchById = fetchById;
export const useSexoLibbyCall = libbyCall;
export const useSexoDAO = libbyDAO;
export const useSexoLibbyFetch = libbyFetch;

// components/hoc
export const SexoAutocomplete = entityAutocomplete;
export const SexoCall = renderPropCall;
export const SexoById = renderPropFetchById;
export const SexoSelect = simpleSelect;
export const SexoAccordionSelector = accordionSelector;
export const SexoSelectedChips = selectedChips;

// context
// ByIdContext
export const useSexoByIdContext = useByIdContext;
export const SexoByIdProvider = ByIdProvider;
export const SexoByIdContext = ByIdContext;
// GeneralContext
export const useSexoContext = useGeneralContext;
export const SexoProvider = GeneralProvider;
export const SexoContext = GeneralContext;
