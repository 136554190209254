import React, { useCallback } from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { useTemplateJoiner } from "./hook";
import { FormContextProvider } from "src/lib/templates";
import { FormMain } from "./components/FormMain";
import { HeaderCreateForm } from "./components/HeaderCreateForm";
import { useFormularioDAO, useQuestionDAO } from "src/app/business";
import { useHistory } from "react-router";

const templateMap = {
  question: "questionTemp",
};

export const CreateForm = ({
  initialValues,
  template,
  editIdForm,
  question,
  reFetch,
  setLoading,
}: any) => {
  // Unimos varios templates en uno solo
  // @ts-ignore
  const joinedTemplates: any = useTemplateJoiner(template, templateMap);
  const history = useHistory();

  const FormularioDAO = useFormularioDAO();
  const QuestionDAO = useQuestionDAO();

  const onSubmit = useCallback(
    async (value: any) => {
      try {
        setLoading(true);

        const title_question = {
          value: encodeURI(value.question.title_question.value),
        };
        const question_first = {
          type: value.question.question_first.type,
          value: encodeURI(value.question.question_first.value),
        };
        const question_alternative = value.question.question_alternative.map(
          (item: any) => ({ type: item.type, value: encodeURI(item.value) })
        );

        const reformObject = {
          question: {
            title_question,
            question_first,
            question_alternative,
          },
        };

        const fechaDate = moment().toISOString();
        const createForm = {
          nombre_de_formulario: reformObject.question.title_question.value,
          fecha_creacion: fechaDate,
          fecha_baja: fechaDate,
          estado_formulario: { id_estado_formulario: 2 },
        };
        const editForm = {
          ...question?.formulario,
          id_formulario: editIdForm,
          nombre_de_formulario: reformObject.question.title_question.value,
        };

        const formObj = !editIdForm ? createForm : editForm;
        const resCreateForm = await FormularioDAO.aspect("aspect-web").save(
          formObj
        );
        const createQuestion = {
          formulario: { id_formulario: resCreateForm.id_formulario },
          template: JSON.stringify(reformObject),
          cabecera: reformObject.question.title_question.value,
        };

        const editQuestion = {
          ...question,
          template: JSON.stringify(reformObject),
          cabecera: reformObject.question.title_question.value,
        };
        const questionObj = !editIdForm ? createQuestion : editQuestion;
        await QuestionDAO.aspect("aspect-web").save(questionObj);
        if (!editIdForm) {
          history.goBack();
        } else {
          reFetch();
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [
      FormularioDAO,
      QuestionDAO,
      editIdForm,
      question,
      history,
      setLoading,
      reFetch,
    ]
  );

  const disabledPreview =
    question?.formulario?.estado_formulario?.id_estado_formulario === 3 ||
    question?.formulario?.estado_formulario?.id_estado_formulario === 1;

  return (
    <Grid>
      <FormContextProvider
        template={joinedTemplates}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        <HeaderCreateForm disabledPreview={disabledPreview}>
          <FormMain disabledPreview={disabledPreview} />
        </HeaderCreateForm>
      </FormContextProvider>
    </Grid>
  );
};
