import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EstablishmentAreaResponsabilityDAO extends LibbyFetchDAO {
  constructor() {
    super('establecimientos_arearesponsable', 'id_establecimientos_arearesponsable');
  }

  getAll() {
    return this.query().limit(0, 20).run();
  }

}
