import React, { useMemo, useCallback } from "react";
import { DatabaseConnector } from "@phinxlab/libby-rest-web";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { LibbyObject, MainButton, useRouteScreenTitle } from "src/commons";
import { makeStyles } from "@material-ui/styles";
import { useAccountFetchById } from "src/app/business/Account";
import { useRolesContext } from "src/context";

const useStyles = makeStyles(() => ({
  container: {
    background: "#f5f5f5",
    marginTop: 20,
    borderRadius: 8,
    boxShadow: "0px 0px 0px 1px #E0E0E0",
  },
  root: {
    width: "100%",
  },
  buttonStyle: {
    marginTop: 20,
  },
  inline: {
    display: "inline",
  },
  rolStyle: {
    marginTop: 30,
    height: 30,
    paddingTop: 4,
    borderRadius: 16,
    background: "#E0E0E0",
    textAlign: "center",
  },
  title: {
    fontSize: 12,
    color: "#696969",
  },
  titleRol: {
    fontSize: 13,
    color: "#000000",
  },
  subtitle: {
    fontSize: 16,
    color: "#000000",
  },
}));

export const UserPerfilRaw = ({ libby }: LibbyObject) => {
  const classes = useStyles();
  useRouteScreenTitle("Perfil");

  const { userInfo } = useRolesContext();

  const { data: dataUser } = useAccountFetchById(userInfo.idusuario);

  const onLogout = useCallback(async () => {
    libby.session.logout();
  }, [libby.session]);

  const perfilData = useMemo(
    () => [
      {
        key: "Nombre",
        title: dataUser?.persona?.nombre,
      },
      {
        key: "Apellido",
        title: dataUser?.persona?.nombre,
      },
      {
        key: "DNI",
        title: dataUser?.persona?.documento,
      },
      {
        key: "Mail",
        title: dataUser?.email,
      },
    ],
    [dataUser]
  );

  return (
    <>
      <Grid container xs={5} className={classes.container}>
        <Grid item xs={8}>
          <List className={classes.root}>
            {perfilData.map((item, index) => (
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography className={classes.title}>
                      {item.key}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.subtitle}
                        color="textPrimary"
                      >
                        {item.title}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={3} className={classes.rolStyle}>
          <Typography className={classes.titleRol}>
            {dataUser?.rol?.descripcion}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.buttonStyle}>
        <MainButton title="Cerrar sesion" handleOnClick={onLogout} />
      </Grid>
    </>
  );
};

export const UserPerfil = DatabaseConnector(UserPerfilRaw)();
