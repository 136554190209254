import { MenuItem } from "src/commons";
import { ReclaimObservation } from "src/screens";
import { Establishment } from "src/screens/Private/MesaTerritorial/Establishment";

export const routes: MenuItem[] = [
  {
    key: 'establecimientoIdForm',
    basePath: '/:idestablecimiento/:tipoformulario',
    target: {
      component: ReclaimObservation,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Establecimiento tipo formulario',
    children: [],
  },
  {
    key: 'establecimientoId',
    basePath: '/:idestablecimientovisita',
    target: {
      component: ReclaimObservation,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Establecimiento Lista',
    children: [],
  },
  {
    key: 'repositorioABMForm',
    basePath: '/:idestablecimiento',
    target: {
      component: ReclaimObservation,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Repositorio Form ABM',
    children: [],
  },
  {
    key: "establishmentList",
    basePath: "/",
    target: {
      component: Establishment,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Establecimientos Lista",
    children: [],
    default: true,
  },
];
