import { ReclamoTerritorio } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'comuna';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idcomuna',
      labelKey: 'nombre',
    },
    withSelectedChips: {
      idKey: 'idcomuna',
      labelKey: 'nombre',
    },
  },
};

const optionsToUse = !businessOptions.Comuna ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Comuna,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Comuna.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ReclamoTerritorio>(DAO_NAME, optionsToUse);

// hooks
export const useComunaFetchById = fetchById;
export const useComunaLibbyCall = libbyCall;
export const useComunaDAO = libbyDAO;
export const useComunaLibbyFetch = libbyFetch;

// components/hoc
export const ComunaAutocomplete = entityAutocomplete;
export const ComunaCall = renderPropCall;
export const ComunaById = renderPropFetchById;
export const ComunaSelect = simpleSelect;
export const ComunaAccordionSelector = accordionSelector;
export const ComunaSelectedChips = selectedChips;

// context
// ByIdContext
export const useComunaByIdContext = useByIdContext;
export const ComunaByIdProvider = ByIdProvider;
export const ComunaByIdContext = ByIdContext;
// GeneralContext
export const useComunaContext = useGeneralContext;
export const ComunaProvider = GeneralProvider;
export const ComunaContext = GeneralContext;
