import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class StudentViewDAO extends LibbyFetchDAO {
  constructor() {
    super('studentView', 'idalumnomovimiento');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

}
