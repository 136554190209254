import { LibbyFetchDAO } from './LibbyFetchDAO';

export class AreaResponsabilityDAO extends LibbyFetchDAO {
  constructor() {
    super('arearesponsable', 'idarearesponsable');
  }

  getAll() {
    return this.query().limit(0, 20).run();
  }

}
