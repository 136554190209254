import { LibbyFetchDAO } from './LibbyFetchDAO';

export class QuestionDAO extends LibbyFetchDAO {
  constructor() {
    super('pregunta', 'id_pregunta');
  }

  getAll() {
    return this.query().limit(0, 20).run();
  }

}
