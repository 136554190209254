import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class TransportSchoolDAO extends LibbyFetchDAO {
  constructor() {
    super('transporteescolar', 'idtransporteescolar');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

}
