import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class SchoolDistrictDAO extends LibbyFetchDAO {
  constructor() {
    super('distritoescolar', 'iddistritoescolar');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
