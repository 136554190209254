export const templates = {
  questionTemp: {
    idTemplates: 1,
    name: "question",
    template: [
      {
        name: "title_question",
        type: {
          name: "object",
          kind: [
            {
              name: "value",
              type: {
                name: "string",
              },
              label: "Titulo",
            },
          ],
        },
      },
      {
        name: "question_first",
        type: {
          name: "object",
          kind: [
            {
              name: "value",
              type: {
                name: "string",
              },
              label: "Pregunta",
            },
            {
              name: "type",
              type: {
                name: "string",
              },
              label: "Formato",
            },
          ],
        },
      },
      {
        name: "question_alternative",
        type: {
          name: "array",
          optional: true,
          kind: [
            {
              name: "value",
              type: {
                name: "string",
              },
              label: "Pregunta",
            },
            {
              name: "type",
              type: {
                name: "string",
              },
              label: "Formato",
            },
          ],
        },
      },
    ],
  },
};
