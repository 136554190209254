import React, { useCallback, useMemo, useState } from "react";
import Delete from "@material-ui/icons/Delete";
import Build from "@material-ui/icons/Build";
import {
  confirmDialog,
  customFormDialog,
  HeaderTerri,
  IconTables,
  InfoTable,
  Loading,
  useDebounce,
  useOrderByState,
} from "src/commons";
import { ModalAssignArea } from "./components";
import { ModalAssignEstablishmentArea } from "./components/ModalAssignEstablishmentArea";
import { categoryMobileApp, column } from "./helpers";
import {
  useEstablecimientoCategoriasDAO,
  useEstablecimientoCategoriasLibbyFetch,
} from "src/app/business/business/EstablecimientoCategorias";

export const EstablishmentArea = () => {
  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 1000);

  const EstablecimientoCategoriasDAO = useEstablecimientoCategoriasDAO();

  const { orderBy, direction, onOrderByDirectionChange } = useOrderByState(
    "id_establecimientos_categorias",
    "asc"
  );

  const filterSearch = useMemo(
    () => ({
      personaFilter: [
        {
          path: "id_localizacion",
          value: searchDebounced,
          method: "includes",
        },
      ],
    }),
    [searchDebounced]
  );

  const {
    data: categorias = [],
    working: workingEstablishmentCategory,
    fetchMore: fetchMoreEstablishmentCategory,
    reFetch,
  } = useEstablecimientoCategoriasLibbyFetch({
    limit: 10,
    direction,
    orderBy,
    filter: filterSearch,
  });

  const handleAssignArea = useCallback(
    async (establishment) => {
      const formValues = establishment.categorias;
      await customFormDialog.show({
        title: "Desactivar Categorías",
        renderComponent: (
          <ModalAssignArea
            isValues={formValues}
            category={categoryMobileApp}
            id_establecimientos_categorias={
              establishment.id_establecimientos_categorias
            }
            id_localizacion={establishment.id_localizacion}
            reFetch={reFetch}
          />
        ),
        sizeWidth: "sm",
      });
    },
    [reFetch]
  );

  const handleDeleteCategory = useCallback(
    async (establishment) => {
      const confirm = await confirmDialog.show({
        title: "",
        confirmText: "Deshabilitar",
        cancelText: "Cancelar",
        content: `¿Desea deshabilitar? Esto hara que el establecimiento vuelva a ver todas las categorias`,
      });
      if (confirm) {
        const deleteCategory = {
          id_establecimientos_categorias:
            establishment.id_establecimientos_categorias,
        };

        await EstablecimientoCategoriasDAO.aspect("aspect-web").remove(
          deleteCategory
        );
        reFetch();
      }
    },
    [EstablecimientoCategoriasDAO, reFetch]
  );

  const icoTables = useCallback(
    (establishment) => [
      {
        text: "Configurar",
        icon: <Build />,
        handleOnClick: () => handleAssignArea(establishment),
        disabled: false,
      },
      {
        text: "Deshabilitar",
        icon: <Delete />,
        handleOnClick: () => handleDeleteCategory(establishment),
        disabled: false,
      },
    ],
    [handleAssignArea, handleDeleteCategory]
  );

  const rows = useMemo(
    () =>
      categorias.map((item) => ({
        ...item,
        descripcion: item.localizacion[0].descripcion,
        cueanexo: item.localizacion[0].cueanexo,
        icon: <IconTables content={icoTables(item)} />,
      })),
    [categorias, icoTables]
  );

  const handleAddEstablishmentArea = useCallback(async () => {
    const onActiveCheckbox = {
      establishment_category: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    };

    await customFormDialog.show({
      title: "Desactivar categorías",
      renderComponent: (
        <ModalAssignEstablishmentArea
          reFetch={reFetch}
          isValues={onActiveCheckbox}
        />
      ),
      sizeWidth: "sm",
    });
  }, [reFetch]);

  return (
    <HeaderTerri
      rightLayout
      searchValue={search}
      setSearchValue={setSearch}
      placeholder="Buscar por Cue"
      titleButton="Desactivar categorías"
      handleButton={handleAddEstablishmentArea}
    >
      {workingEstablishmentCategory ? (
        <Loading />
      ) : (
        <InfoTable
          working={workingEstablishmentCategory}
          rows={rows || []}
          columns={column}
          onBottomScroll={fetchMoreEstablishmentCategory}
          rowIdKey="id_establecimientos_categorias"
          direction={direction}
          orderBy={orderBy}
          onSortChange={onOrderByDirectionChange}
        />
      )}
    </HeaderTerri>
  );
};
