import { LibbyFetchDAO } from './LibbyFetchDAO';

export class AreaResponsableSubWebDAO extends LibbyFetchDAO {
  constructor() {
    super('arearesponsable_subweb', 'id_arearesponsable_sub');
  }

  getAll() {
    return this.query().limit(0, 20).run();
  }

}
