import { LibbyFetchDAO } from './LibbyFetchDAO';

export class FormDAO extends LibbyFetchDAO {
  constructor() {
    super('formulario', 'id_formulario');
  }

  getAll() {
    return this.query().limit(0, 20).run();
  }

}
