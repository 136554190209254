import { UsuarioEstablecimiento } from "src/app/models/business/UsuarioEstablecimiento";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'usuarioestablecimiento';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idusuarioestablecimiento',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idusuarioestablecimiento',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Establishment ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Establishment,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Establishment.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<UsuarioEstablecimiento>(DAO_NAME, optionsToUse);

// hooks
export const useUserEstablishmentFetchById = fetchById;
export const useUserEstablishmentLibbyCall = libbyCall;
export const useUserEstablishmentDAO = libbyDAO;
export const useUserEstablishmentLibbyFetch = libbyFetch;

// components/hoc
export const UserEstablishmentAutocomplete = entityAutocomplete;
export const UserEstablishmentCall = renderPropCall;
export const UserEstablishmentById = renderPropFetchById;
export const UserEstablishmentSelect = simpleSelect;
export const UserEstablishmentAccordionSelector = accordionSelector;
export const UserEstablishmentSelectedChips = selectedChips;

// context
// ByIdContext
export const useUserEstablishmentByIdContext = useByIdContext;
export const UserEstablishmentByIdProvider = ByIdProvider;
export const UserEstablishmentByIdContext = ByIdContext;
// GeneralContext
export const useUserEstablishmentContext = useGeneralContext;
export const UserEstablishmentProvider = GeneralProvider;
export const UserEstablishmentContext = GeneralContext;
