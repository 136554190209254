import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'activeTeacher';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idviewdocentesactivos',
      labelKey: 'apellido_y_nombre',
    },
    withSelectedChips: {
      idKey: 'idviewdocentesactivos',
      labelKey: 'apellido_y_nombre',
    },
  },
};

const optionsToUse = !businessOptions.ActiveTeachers ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.ActiveTeachers,
  hoc: { ...defaultOptions.hoc, ...businessOptions.ActiveTeachers.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useActiveTeachersFetchById = fetchById;
export const useActiveTeachersLibbyCall = libbyCall;
export const useActiveTeachersDAO = libbyDAO;
export const useActiveTeachersLibbyFetch = libbyFetch;

// components/hoc
export const ActiveTeachersAutocomplete = entityAutocomplete;
export const ActiveTeachersCall = renderPropCall;
export const ActiveTeachersById = renderPropFetchById;
export const ActiveTeachersSelect = simpleSelect;
export const ActiveTeachersAccordionSelector = accordionSelector;
export const ActiveTeachersSelectedChips = selectedChips;

// context
// ByIdContext
export const useActiveTeachersByIdContext = useByIdContext;
export const ActiveTeachersByIdProvider = ByIdProvider;
export const ActiveTeachersByIdContext = ByIdContext;
// GeneralContext
export const useActiveTeachersContext = useGeneralContext;
export const ActiveTeachersProvider = GeneralProvider;
export const ActiveTeachersContext = GeneralContext;
