import validateCreditCard from 'card-validator';

export const customValidatorFunctions: { [k: string]: any } = {
  creditCardNumber: (value: any, helpers: any) => {
    const numberValidation = validateCreditCard.number(value);
    if (!numberValidation.isValid) {
      return helpers.error('creditCardNumber.invalid');
    }
    return value;
  },
  creditCardExpiry: (value: any, helpers: any) => {
    const numberValidation = validateCreditCard.expirationDate(value);
    if (!numberValidation.isValid) {
      return helpers.error('creditCardExpiry.invalid');
    }
    return value;
  },
  creditCardProvincia: (value: any, helpers: any) => {
    const validNumbers = [
      '541719',
      '541646',
      '548790',
      '557497',
      '553668',
      '454832',
      '454850',
      '451275',
      '454846',
      '489321',
      '433833',
      '451772',
      '510510',
    ];

    if (value.toString().length >= 6 && !validNumbers.includes(value.toString().slice(0, 6))) {
      return helpers.error('creditCardProvincia.invalid');
    }
    return value;
  },
};
