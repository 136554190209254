
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'usuario';

const defaultOptions: PartialBusinessEntityOptions<any> = {
    hoc: {
        withAccordionSelector: {
            idKey: 'idusuario',
            labelKey: 'descripcion',
        },
        withSelectedChips: {
            idKey: 'idusuario',
            labelKey: 'descripcion',
        },
    },
};

const optionsToUse = !businessOptions.Funcionario ? defaultOptions : {
    ...defaultOptions,
    ...businessOptions.Funcionario,
    hoc: { ...defaultOptions.hoc, ...businessOptions.Funcionario.hoc }
};

const {
    hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
    hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
    context: {
        byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
        generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
    },
    //TODO: add interface
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useFuncionarioFetchById = fetchById;
export const useFuncionarioLibbyCall = libbyCall;
export const useFuncionarioDAO = libbyDAO;
export const useFuncionarioLibbyFetch = libbyFetch;

// components/hoc
export const FuncionarioAutocomplete = entityAutocomplete;
export const FuncionarioCall = renderPropCall;
export const FuncionarioById = renderPropFetchById;
export const FuncionarioSelect = simpleSelect;
export const FuncionarioAccordionSelector = accordionSelector;
export const FuncionarioSelectedChips = selectedChips;

// context
// ByIdContext
export const useFuncionarioByIdContext = useByIdContext;
export const FuncionarioByIdProvider = ByIdProvider;
export const FuncionarioByIdContext = ByIdContext;
// GeneralContext
export const useFuncionarioContext = useGeneralContext;
export const FuncionarioProvider = GeneralProvider;
export const FuncionarioContext = GeneralContext;
