import React, { ReactNode, createRef, useMemo } from "react";
import { useField } from "react-final-form-hooks";
import _ from "lodash";
import {
  Grid,
  MenuItem,
  InputProps,
  InputLabelProps,
  Typography,
  FormControl,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { getErrorAndHelperTextNoTranslation } from "src/utils/materialHelpers";
import { FormApi } from "final-form";
import { Loading } from "src/commons";
import { makeStyles } from "@material-ui/styles";
import { grey, primary } from "src/theme/colors";
import pallet from "src/theme/palette";
import typography from "src/theme/typography";

// FIXME: type form props
export interface FormSelectProps<T> {
  name: string;
  form: FormApi<any, Partial<any>>;
  content: T[];
  valueKey?: string;
  labelKey?: string;
  fullWidth?: boolean;
  inputProps?: InputProps;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  variant?: "standard" | "filled" | "outlined";
  inputLabelProps?: Partial<InputLabelProps>;
  size?: "small" | "medium";
  positionLabel?: "inner" | "outer";
  loading?: boolean;
  helperText?: ReactNode;
  disableUnderline?: boolean;
  multiple?: boolean;
  defaultValue?: T;
  optionToValue?: (value: string, options?: T[]) => T | undefined;
  valueToOption?: (value: T, options: T[]) => string | undefined;
}

const useStyles = makeStyles({
  formControl: {
    margin: 1,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "7px 1em",
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
    },
  },
  title: {
    marginBottom: 10,
    fontSize: 16,
    lineHeight: "17px",
    fontWeight: 400,
    color: pallet.text.primaryAlternative,
  },
  select: {
    fontFamily: typography.fontFamily,
    color: grey.textPrimary,
    fontSize: "13px",
    maxWidth: "100%",
    display: "block",
    whiteSpace: "nowrap",
  },
  placeHolder: {
    fontFamily: typography.fontFamily,
    color: grey.textPrimary,
    fontSize: "16px",
    lineHeight: "17px",
    maxWidth: "100%",
    overflow: "hidden",
    display: "block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export function FormSelect<T>({
  name,
  form,
  content = [],
  label,
  placeholder = "Seleccioná",
  fullWidth = false,
  valueKey = "description",
  labelKey = "id",
  inputLabelProps,
  variant = "outlined",
  size,
  positionLabel = "inner",
  loading = false,
  disableUnderline = false,
  helperText,
  disabled,
  multiple,
  optionToValue,
  valueToOption,
}: FormSelectProps<T>) {
  const field = useField(name, form);
  const classes = useStyles();

  const {
    error,
    helperText: innerHelperText,
  } = getErrorAndHelperTextNoTranslation(field);

  const options = content[0]
    ? [{ ...content[0], [valueKey]: "", [labelKey]: placeholder }, ...content]
    : content;

  const ref = createRef<HTMLLIElement>();

  const renderValue = useMemo(() => {
    if (!multiple) {
      return undefined;
    }
    return (values: unknown) => {
      if (!values || !(values as any[]).length) return placeholder;
      return (values as any[])
        .map((item: any) => {
          const option = content?.find(
            (con: any) => `${_.get(con, valueKey)}` === `${item}`
          );
          return !option ? "-" : _.get(option, labelKey);
        })
        .join(", ") as ReactNode;
    };
  }, [content, labelKey, multiple, placeholder, valueKey]);
  return (
    <Grid>
      {loading ? (
        <Loading />
      ) : (
        <>
          {label && (
            <Typography className={classes.title}>
              {label?.charAt(0).toUpperCase() + label?.toLowerCase().slice(1)}
            </Typography>
          )}
          <FormControl
            variant={variant}
            className={classes.formControl}
            error={error}
          >
            <Select
              fullWidth={fullWidth}
              onChange={(event: any) => {
                const newValue = event.target.value;
                if (Array.isArray(newValue)) {
                  field.input.onChange(
                    optionToValue
                      ? newValue.map((item) => optionToValue(item, options))
                      : newValue
                  );
                } else {
                  field.input.onChange(
                    optionToValue ? optionToValue(newValue, options) : newValue
                  );
                }
              }}
              value={
                field.input.value
                  ? valueToOption
                    ? valueToOption(field.input.value, options)
                    : field.input.value
                  : multiple
                  ? []
                  : ""
              }
              disabled={disabled}
              displayEmpty
              disableUnderline={disableUnderline}
              onFocus={field.input.onFocus}
              onBlur={field.input.onBlur}
              renderValue={renderValue}
            >
              <MenuItem
                style={{ backgroundColor: primary.white, opacity: 1 }}
                value=""
              >
                <Typography className={classes.placeHolder}>
                  {placeholder?.charAt(0).toUpperCase() +
                    placeholder?.toLowerCase().slice(1)}
                </Typography>
              </MenuItem>
              {content.map((option) => {
                const theValue = _.get(option, valueKey);
                return (
                  <MenuItem ref={ref} key={theValue} value={theValue}>
                    <Typography className={classes.select}>
                      {_.get(option, labelKey)}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
            {!!innerHelperText && (
              <FormHelperText>{innerHelperText}</FormHelperText>
            )}
          </FormControl>
        </>
      )}
    </Grid>
  );
}
