import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'responsibleArea';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idarearesponsable',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idarearesponsable',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ResponsableArea ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.ResponsableArea,
  hoc: { ...defaultOptions.hoc, ...businessOptions.ResponsableArea.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useResponsableAreaFetchById = fetchById;
export const useResponsableAreaLibbyCall = libbyCall;
export const useResponsableAreaDAO = libbyDAO;
export const useResponsableAreaLibbyFetch = libbyFetch;

// components/hoc
export const ResponsableAreaAutocomplete = entityAutocomplete;
export const ResponsableAreaCall = renderPropCall;
export const ResponsableAreaById = renderPropFetchById;
export const ResponsableAreaSelect = simpleSelect;
export const ResponsableAreaAccordionSelector = accordionSelector;
export const ResponsableAreaSelectedChips = selectedChips;

// context
// ByIdContext
export const useResponsableAreaByIdContext = useByIdContext;
export const ResponsableAreaByIdProvider = ByIdProvider;
export const ResponsableAreaByIdContext = ByIdContext;
// GeneralContext
export const useResponsableAreaContext = useGeneralContext;
export const ResponsableAreaProvider = GeneralProvider;
export const ResponsableAreaContext = GeneralContext;
