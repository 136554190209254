import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'secundariadelfuturo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'id_secundariadelfuturo',
      labelKey: 'fecha_desde',
    },
    withSelectedChips: {
      idKey: 'id_secundariadelfuturo',
      labelKey: 'fecha_desde',
    },
  },
};

const optionsToUse = !businessOptions.EstablishmentFuture ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.EstablishmentFuture,
  hoc: { ...defaultOptions.hoc, ...businessOptions.EstablishmentFuture.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useEstablishmentFutureFetchById = fetchById;
export const useEstablishmentFutureLibbyCall = libbyCall;
export const useEstablishmentFutureDAO = libbyDAO;
export const useEstablishmentFutureLibbyFetch = libbyFetch;

// components/hoc
export const EstablishmentFutureAutocomplete = entityAutocomplete;
export const EstablishmentFutureCall = renderPropCall;
export const EstablishmentFutureById = renderPropFetchById;
export const EstablishmentFutureSelect = simpleSelect;
export const EstablishmentFutureAccordionSelector = accordionSelector;
export const EstablishmentFutureSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstablishmentFutureByIdContext = useByIdContext;
export const EstablishmentFutureByIdProvider = ByIdProvider;
export const EstablishmentFutureByIdContext = ByIdContext;
// GeneralContext
export const useEstablishmentFutureContext = useGeneralContext;
export const EstablishmentFutureProvider = GeneralProvider;
export const EstablishmentFutureContext = GeneralContext;
