import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
});

export const InfoDrawer = ({ children, drawerState, setDrawerState, customStyle = {} }: any) => {
  const classes = useStyles();

  const list = () => (
    <div
      className={clsx(`${classes.list} ${customStyle}`)}
      role="presentation"
      onClick={() => setDrawerState({ ...drawerState, open: false })}
      onKeyDown={() => setDrawerState({ ...drawerState, open: false })}
    >
      {children}
    </div>
  );

  return (
    <div>
      <React.Fragment key={drawerState.position}>
        <Drawer
          anchor={drawerState.position}
          open={drawerState.open}
          onClose={() => setDrawerState({ ...drawerState, open: false })}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
};
