export const column = [
  {
    id: "descripcion",
    label: "Establecimiento",
    width: "5%",
    orderById: "descripcion",
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: "cueanexo",
    label: "CUEANEXO",
    width: "5%",
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: "icon",
    label: "Gestionar",
    width: "4%",
    hideSortIcon: true,
    noSort: true,
    style: { textAlign: "left" },
  },
];
