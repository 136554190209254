import { useMemo } from 'react';
import { usePermissionContext } from '../permissionContext';

export const usePermissionSideFilter = (
  sideMenu: {
    id: number;
    title: string;
    icon: JSX.Element;
    path: string;
  }[],
) => {
  const { activeRules } = usePermissionContext();
  return useMemo(() =>
    sideMenu.filter(
      (side) => !activeRules.sideMenu.find((ruleSide) => ruleSide.id === side.id),
    ), [activeRules, sideMenu]);
};
