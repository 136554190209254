import { EstablecimientoPrioridades } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'establecimientoprioridades';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idestablecimientoprioridades',
      labelKey: 'nombre',
    },
    withSelectedChips: {
      idKey: 'idestablecimientoprioridades',
      labelKey: 'nombre',
    },
  },
};

const optionsToUse = !businessOptions.EstablishmentPriority ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.EstablishmentPriority,
  hoc: { ...defaultOptions.hoc, ...businessOptions.EstablishmentPriority.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstablecimientoPrioridades>(DAO_NAME, optionsToUse);

// hooks
export const useEstablishmentPriorityFetchById = fetchById;
export const useEstablishmentPriorityLibbyCall = libbyCall;
export const useEstablishmentPriorityDAO = libbyDAO;
export const useEstablishmentPriorityLibbyFetch = libbyFetch;

// components/hoc
export const EstablishmentPriorityAutocomplete = entityAutocomplete;
export const EstablishmentPriorityCall = renderPropCall;
export const EstablishmentPriorityById = renderPropFetchById;
export const EstablishmentPrioritySelect = simpleSelect;
export const EstablishmentPriorityAccordionSelector = accordionSelector;
export const EstablishmentPrioritySelectedChips = selectedChips;

// context
// ByIdContext
export const useEstablishmentPriorityByIdContext = useByIdContext;
export const EstablishmentPriorityByIdProvider = ByIdProvider;
export const EstablishmentPriorityByIdContext = ByIdContext;
// GeneralContext
export const useEstablishmentPriorityContext = useGeneralContext;
export const EstablishmentPriorityProvider = GeneralProvider;
export const EstablishmentPriorityContext = GeneralContext;
