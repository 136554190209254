import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { MainInput } from "src/commons";
import { makeStyles } from "@material-ui/styles";

const modalTableUserStyles = makeStyles(() => ({
  formContainer: {
    marginTop: 20,
  },
}));

interface userSelect {
  idusuario: number;
  nombre: string;
  dni: string
}

interface NotificacionFormScreenProps {
  userSelect: userSelect[];
  checkbox: boolean;
  formValues: { mensaje: string; titulo: string }
  setFormValues: (e: any) => void
}

export const NotificacionFormScreen = ({
  userSelect,
  checkbox,
  formValues,
  setFormValues,
}: NotificacionFormScreenProps) => {
  const classes = modalTableUserStyles();

  return (
    <Grid container>
      <Grid>
        <Typography variant="subtitle2">Destinatario:</Typography>
        {checkbox ? (
          <Typography>Mensaje Global</Typography>
        ) : (
          userSelect?.map((item: any, index: any) => (
            <Typography display="inline" key={index}>
              {item.nombre},{" "}
            </Typography>
          ))
        )}
      </Grid>
      <Grid container className={classes.formContainer}>
        <MainInput
          label="Asunto"
          value={formValues.titulo}
          handleChange={(e) =>
            setFormValues({ ...formValues, titulo: e.target.value })
          }
          placeholder="Escribí un asunto"
          maxLengths={50}
          fullWidth
        />
      </Grid>
      <Grid container className={classes.formContainer}>
        <MainInput
          label="Mensaje"
          value={formValues.mensaje}
          handleChange={(e) =>
            setFormValues({ ...formValues, mensaje: e.target.value })
          }
          placeholder="Escribí tu mensaje"
          maxLengths={500}
          fullWidth
          multiline
          rows={5}
        />
      </Grid>
    </Grid>
  );
};
