import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class ComunaDAO extends LibbyFetchDAO {
  constructor() {
    super('comuna', 'idcomuna');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
