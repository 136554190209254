import { LibbyFetchDAO } from './LibbyFetchDAO';

export class LocalizacionDAO extends LibbyFetchDAO {
  constructor() {
    super('establecimiento', 'idestablecimiento');
  }


  getAll() {
    return this.query().limit(0, 10).run();
  }

  fetchByLocalizacionId(id: any) {
    return this.query()
      .equals('localizacion.idlocalizacion', id)
      .run();
  }
}