import { MenuItem } from "src/commons";
import { UserPerfil } from "src/screens";

export const routes: MenuItem[] = [
  {
    key: "userPerfil",
    basePath: "/",
    target: {
      component: UserPerfil,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Perfil",
    children: [],
    default: true,
  },
];
