import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { loading } from "src/assets/images";
import { primary, grey } from "../../../theme/colors";
import { UserAvatar } from "../UserAvatar";
import avatarEscuelaDefault from "../../../assets/images/mi_escuela_default-image.png"
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 450,
  },
  title: {
    color: primary.black,
    fontSize: 15,
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  subtitle: {
    color: grey.textSecondary,
    fontSize: 12,
  },
  subtitleTest: {
    color: grey.textSecondary,
    fontSize: 12,
    marginLeft: 20,
  },
  listContainer: {
    borderBottom: `1px solid ${primary.lighter}`,
    paddingTop: 10,
    paddingBottom: 10,
    "&:hover":{
      background: "#EFEFFF"
    }
  },
  avatar: {
    background: "#5E5CE6",
  },
  dateLeft: {
    padding: "5px 20px 0px 0px",
  },
  dateLeftText: {
    textAlign: "center",
  },
  additionalInformationStyle: {
    padding: "15px 0px 0px 5px",
    cursor: "pointer",
  },
  rowRight: {
    textAlign: "right"
  }
}));

//TODO: hay que componetizar mas este component
export const InfoList = ({
  rows = [],
  messageNoData = "No tenemos datos para mostrarte",
  onBottomScroll,
  rowIdKey = "id",
  working,
  onRowClick,
  onInfoClick,
  additionalInformation,
  rowSelect,
  children,
  customStyleTitle = {},
  customStyleRoot = {},
  containerCustomStyle = {},
}: any) => {
  const classes = useStyles();

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    if (bottom && onBottomScroll) {
      onBottomScroll();
    }
  };
  console.log('InfoList:rows',rows);
  return (
    <Grid container>
      <Grid item xs={children ? 4 : 12} className={containerCustomStyle}>
        {additionalInformation && (
          <Grid
            className={classes.additionalInformationStyle}
            container
            onClick={onInfoClick ? () => onInfoClick() : undefined}
          >
            {additionalInformation}
          </Grid>
        )}
        <List
          className={`${classes.root} ${customStyleRoot}`}
          onScroll={handleScroll}
        >
          {rows?.map((row: any, index: any) => (
            <ListItem
              onClick={onRowClick ? () => onRowClick(row) : undefined}
              key={row[rowIdKey]}
              alignItems="flex-start"
              className={classes.listContainer}
            >
              {row.dateLeft ? (
                <Grid className={classes.dateLeft}>
                  <Typography className={classes.dateLeftText}>
                    {row.dateLeft}
                  </Typography>
                </Grid>
              ) : (
                <ListItemAvatar>
                  <UserAvatar
                    name={row.title}
                    avatarHeight={40}
                    avatarWidth={40}
                    size={21}
                    icon={row.icon}
                    img={row.img? row.img : avatarEscuelaDefault}
                  />
                </ListItemAvatar>
              )}
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={9}>
                      <Typography
                        className={`${classes.title} ${customStyleTitle}`}
                      >
                        {row.title || row.establecimiento.nombre }
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={classes.rowRight}>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.subtitleTest}
                      >
                        {row.dateRight}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.subtitle}
                    >
                      {row.subtitle}
                    </Typography>
                    {row.subtitleSecondary && (
                      <Grid container>
                        <Grid item xs={9}>
                          <Typography className={classes.subtitle}>
                            {row.subtitleSecondary}
                          </Typography>
                        </Grid>
                        {row.subtitleThird && (
                          <Grid item xs={3} className={classes.rowRight}>
                            <Typography className={classes.subtitle}>
                              {row.subtitleThird}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
        {working ? (
          <Grid container justify="center" alignItems="center">
            <img src={loading} width={50} alt="loading" />
          </Grid>
        ) : (
          !rows.length && (
            <Grid container justify="center" alignItems="center">
              <Typography>{messageNoData}</Typography>
            </Grid>
          )
        )}
      </Grid>
      {children && (
        <Grid item xs={8}>
          {children(rowSelect)}
        </Grid>
      )}
    </Grid>
  );
};
