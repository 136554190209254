import React, { useMemo } from "react";
import { TabBar, useRouteScreenTitle } from "src/commons";
import { EstablishmentList } from "./EstablishmentList";
import { EstablishmentMap } from "./EstablishmentMap";

export const Establishment = () => {
  useRouteScreenTitle("Establecimientos");

  const ListEstablishmentTab = useMemo(
    () => [
      {
        title: "Lista",
        component: <EstablishmentList />,
      },
      {
        title: "Mapa",
        component: <EstablishmentMap />,
      },
    ],
    []
  );

  return (
    <>
      <TabBar loading={false} content={ListEstablishmentTab} />
    </>
  );
};
