import React, {useCallback, useState} from "react";

export type DirectionOption = 'asc' | 'desc';

export const useOrderByState = (initialOrderByValue = '', initialDirectionValue: DirectionOption = 'asc') => {
  const [direction, setDirection] = React.useState<DirectionOption>(initialDirectionValue);
  const [orderBy, setOrderBy] = useState(initialOrderByValue);
  const onOrderByDirectionChange = useCallback((newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  }, []);

  return {
    direction,
    setDirection,
    orderBy,
    setOrderBy,
    onOrderByDirectionChange,
  };
};
