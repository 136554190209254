import React from "react";
import { FormMultiCheckbox } from "src/lib/templates";

export const SelectCategory = ({ form, category }: any) => {
  return (
    <>
      <FormMultiCheckbox
        name="establishment_category"
        idKey="id_category"
        options={category}
        form={form}
      />
    </>
  );
};
