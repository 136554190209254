import React from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
    marginTop: 10,
    borderTop: "1px solid #E0E0E0",
  },
  title: {
    fontSize: 15,
    color: "#696969",
    marginBottom: 5,
  },
  containerList: {
    marginTop: 10,
    marginBottom: 10,
  },
  boxList: {
    textAlign: "center",
    marginRight: 30,
    marginBottom: 15,
    cursor: "pointer",
  },
  boxText: {
    fontSize: 12,
  },
  iconButton: {
    border: "1px solid #E5E5EA",
    width: 50,
    height: 50,
  },
}));

export const ContainerInformation = ({ info, loading }: any) => {
  const classes = useStyles();
  console.log('ContainerInformation:ContainerInformation',info);
  return (
    <Grid container className={classes.container}>
      {info.map((item: any, index: any) => (
        <Grid container>
          <Typography className={classes.title}>{item.title}</Typography>
          <Grid container className={classes.containerList}>
            {loading ? <Typography>Cargando </Typography> : item.list.map((sub: any) => (
              <Grid onClick={sub.onClick} className={classes.boxList} item>
                <IconButton className={classes.iconButton}>
                  <SvgIcon component={sub.icon} className={sub.className} />
                </IconButton>
                <Typography className={classes.boxText}>{sub.title}</Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
