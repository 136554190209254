import { AreaResponsaleSub } from "src/app/models/business/AreaResponsableSub";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'arearesponsable_subweb';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'id_arearesponsable_sub',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'id_arearesponsable_sub',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.AreaResponsableSubWeb ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.AreaResponsableSubWeb,
  hoc: { ...defaultOptions.hoc, ...businessOptions.AreaResponsableSubWeb.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<AreaResponsaleSub>(DAO_NAME, optionsToUse);

// hooks
export const useAreaResponsableSubWebFetchById = fetchById;
export const useAreaResponsableSubWebLibbyCall = libbyCall;
export const useAreaResponsableSubWebDAO = libbyDAO;
export const useAreaResponsableSubWebLibbyFetch = libbyFetch;

// components/hoc
export const AreaResponsableSubWebAutocomplete = entityAutocomplete;
export const AreaResponsableSubWebCall = renderPropCall;
export const AreaResponsableSubWebById = renderPropFetchById;
export const AreaResponsableSubWebSelect = simpleSelect;
export const AreaResponsableSubWebAccordionSelector = accordionSelector;
export const AreaResponsableSubWebSelectedChips = selectedChips;

// context
// ByIdContext
export const useAreaResponsableSubWebByIdContext = useByIdContext;
export const AreaResponsableSubWebByIdProvider = ByIdProvider;
export const AreaResponsableSubWebByIdContext = ByIdContext;
// GeneralContext
export const useAreaResponsableSubWebContext = useGeneralContext;
export const AreaResponsableSubWebProvider = GeneralProvider;
export const AreaResponsableSubWebContext = GeneralContext;
