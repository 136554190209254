import React, { useCallback } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { grey } from "src/theme/colors";
import { useAccountLibbyFetch } from "../../Account";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      padding: 5,
      border: "none",
    },
  },
  labelStyle: {
    marginBottom: 8,
    fontSize: 13,
    fontWeight: 600,
    color: grey.medium,
  },
}));

const dummyFilter = {};

export const AccountAutoComplete = ({
  multiple = false,
  autoCompleteProps,
  onChange,
  filter = dummyFilter,
  textFieldProps = dummyFilter,
  setInputValue,
  inputValue = null,
  disabled = false,
  label,
  loading,
}: any) => {
  const classes = useStyles();

  const { working, data: options = [] } = useAccountLibbyFetch({
    filter,
  });

  const onChangeAutoComplete = useCallback(
    (event, newValue) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <>
      <Autocomplete
        classes={classes}
        options={options}
        loading={working}
        freeSolo
        onChange={onChangeAutoComplete}
        disabled={disabled || loading}
        renderInput={(params) => {
          return (
            <>
              <Typography className={classes.labelStyle}>{label}</Typography>
              <TextField
                placeholder="Buscá por nombre"
                {...params}
                variant="outlined"
                {...textFieldProps}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                        }
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {working || loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            </>
          );
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option?.email?.toString()}
        renderOption={(option) => {
          return <>{option.email}</>;
        }}
        getOptionSelected={(option, _value) =>
          option.idusuario === _value.idusuario
        }
        {...(autoCompleteProps || {})}
      />
    </>
  );
};
