import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../lib/libby/generator";
import {Account} from "../models";
import {businessOptions} from "./businessOptions";

const DAO_NAME = 'usuario';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idusuario',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idusuario',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Account ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Account,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Account.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Account>(DAO_NAME, optionsToUse);

// hooks
export const useAccountFetchById = fetchById;
export const useAccountLibbyCall = libbyCall;
export const useAccountDAO = libbyDAO;
export const useAccountLibbyFetch = libbyFetch;

// components/hoc
export const AccountAutocomplete = entityAutocomplete;
export const AccountCall = renderPropCall;
export const AccountById = renderPropFetchById;
export const AccountSelect = simpleSelect;
export const AccountAccordionSelector = accordionSelector;
export const AccountSelectedChips = selectedChips;

// context
// ByIdContext
export const useAccountByIdContext = useByIdContext;
export const AccountByIdProvider = ByIdProvider;
export const AccountByIdContext = ByIdContext;
// GeneralContext
export const useAccountContext = useGeneralContext;
export const AccountProvider = GeneralProvider;
export const AccountContext = GeneralContext;
