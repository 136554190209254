/* eslint-disable */
import * as React from "react";

export const LogoBA = () => (
  <svg
    width="151"
    height="81"
    viewBox="0 0 151 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M149.186 63.8045L120.165 8.14525C117.898 3.1676 112.456 0 106.562 0C100.667 0 95.6787 3.1676 92.958 8.14525L72.5526 47.514C70.2853 43.4413 66.6577 40.2737 62.1231 38.4637C67.1111 34.8436 70.2853 29.4134 70.2853 22.6257C70.2853 14.933 66.2042 8.59777 59.8559 5.43017C54.4144 2.71508 48.0661 0.905028 41.2643 0.905028H11.3363C4.98799 1.35754 0 6.3352 0 12.2179V68.7821C0 75.1173 4.98799 80.095 11.3363 80.095H22.2192H43.985C50.3333 80.095 56.2282 78.7374 61.6697 76.4749L63.9369 75.5698H64.3904C66.2042 78.7374 69.8318 81 73.9129 81C78.4474 81 82.5285 78.2849 84.3423 74.2123C85.2493 71.9497 87.0631 70.5922 89.7838 70.5922H123.793C126.06 70.5922 128.327 71.9497 129.234 74.2123C131.048 78.2849 135.129 81 139.664 81C146.012 81 151 76.0223 151 69.6871C150.547 67.4246 150.093 65.6145 149.186 63.8045Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="75.2347"
        y1="75.2449"
        x2="75.2347"
        y2="24.109"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00B8F1" />
        <stop offset="0.0317256" stop-color="#10BBEB" />
        <stop offset="0.0940926" stop-color="#3BC3DD" />
        <stop offset="0.1803" stop-color="#80CFC5" />
        <stop offset="0.2124" stop-color="#9BD4BC" />
        <stop offset="0.3042" stop-color="#BADEB1" />
        <stop offset="0.4164" stop-color="#E5EBA2" />
        <stop offset="0.5077" stop-color="#E8E88F" />
        <stop offset="0.6929" stop-color="#F0E05E" />
        <stop offset="0.9534" stop-color="#FDD40F" />
        <stop offset="1" stop-color="#FFD200" />
      </linearGradient>
    </defs>
  </svg>
);
