import React from "react";
import { Drawer, Grid, List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import School from "@material-ui/icons/School";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import Build from "@material-ui/icons/Build";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import { NavLink, useLocation } from "react-router-dom";
import { SIDEMENU_ID } from "src/platform/permission/const";
import { DatabaseConnector } from "@phinxlab/libby-rest-web";
import { grey, primary } from "../../../theme/colors/index";
import { LibbyObject } from "../../types";
import { LogoTerri } from "src/assets/images/svgs";
import { usePermissionSideFilter } from "src/lib/permission";

const drawerWidthOpen = 245;
const drawerWithClose = 70;

const navBarStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  drawerOpenContainer: {
    transition: "0.8s",
    width: drawerWidthOpen,
    marginLeft: 15,
  },
  drawerCloseContainer: {
    transition: "0.8s",
    width: drawerWithClose,
    marginLeft: 15,
  },
  drawerPaperOpen: {
    transition: "0.8s",
    marginTop: 10,
    width: drawerWidthOpen,
    padding: "0px 25px",
  },
  drawerPaperClose: {
    transition: "0.8s",
    marginTop: 10,
    width: drawerWithClose,
  },
  logoStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 1px",
  },
  iconContainer: {
    height: 37,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectedIcon: {
    textDecoration: "none",
    height: 37,
    width: drawerWidthOpen,
    color: primary.white,
    backgroundColor: primary.lightBlue,
    display: "flex",
    paddingLeft: 25,
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "7px",
  },
  defaultIcon: {
    textDecoration: "none",
    height: 37,
    width: drawerWidthOpen,
    paddingLeft: 25,
    color: grey.medium,
    backgroundColor: primary.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  arrowContainer: {
    flexDirection: "row",
    alignSelf: "center",
  },
  arrowIcon: {
    paddingTop: 10,
    fontSize: 30,
    height: 30,
    color: grey.medium,
  },
  titleStyle: {
    transition: "0.5s",
    fontSize: 13,
    paddingLeft: 15,
    color: grey.medium,
    textDecoration: "none",
  },
  selectedTitle: {
    transition: "0.5s",
    fontSize: 13,
    paddingLeft: 15,
    color: primary.white,
    textDecoration: "none",
  },
  iconButtonStyle: {
    backgroundColor: primary.white,
    "&:hover": {
      backgroundColor: primary.white,
    },
  },
}));

const sideMenuIcons = [
  {
    id: SIDEMENU_ID.RECLAIM_OBSERVATION,
    title: "Informes de visita",
    icon: <ErrorOutlineIcon />,
    path: "/private/informes-de-visita",
  },
  {
    id: SIDEMENU_ID.ESTABLISHMENT,
    title: "Establecimientos",
    icon: <School />,
    path: "/private/establecimientos-lista",
  },
  {
    id: SIDEMENU_ID.ABM_USER,
    title: "ABM Usuarios",
    icon: <PeopleOutlineIcon />,
    path: "/private/abm-usuarios",
  },
  {
    id: SIDEMENU_ID.CONFIGURATION,
    title: "Configuración",
    icon: <Build />,
    path: "/private/configuracion",
  },
  {
    id: SIDEMENU_ID.CREATED_FORM,
    title: "Formularios",
    icon: <FormatListBulletedIcon />,
    path: "/private/formularios",
  },
  {
    id: SIDEMENU_ID.COMUNICATION,
    title: "Comunicación",
    icon: <QuestionAnswerIcon />,
    path: "/private/comunicacion",
  },
];

const SideMenuRaw = ({ libby }: { libby: LibbyObject }) => {
  const classes = navBarStyles();
  const location = useLocation();

  const menuItems = usePermissionSideFilter(sideMenuIcons);


  return (
    <div className={classes.root}>
      <nav className={classes.drawerOpenContainer}>
        <Drawer
          classes={{
            paper: classes.drawerPaperOpen,
          }}
          variant="persistent"
          open
        >
          <Grid className={classes.logoStyle}>
            <LogoTerri />
          </Grid>
          <List>
            {menuItems.map((item, index) => (
              <ListItem
                button
                key={index}
                disableGutters
                className={classes.iconContainer}
              >
                <NavLink
                  to={item.path}
                  className={
                    item.path === location.pathname
                      ? classes.selectedIcon
                      : classes.defaultIcon
                  }
                >
                  {item.icon}
                  <Typography
                    className={
                      item.path === location.pathname
                        ? classes.selectedTitle
                        : classes.titleStyle
                    }
                  >
                    {item.title}
                  </Typography>
                </NavLink>
              </ListItem>
            ))}
          </List>
        </Drawer>
      </nav>
    </div>
  );
};

export const SideMenu = DatabaseConnector(SideMenuRaw)();
