import React, { useState } from "react";
import { useParams } from "react-router";
import { Loading, useRouteScreenTitle } from "src/commons";
import { CreateFormEdit, CreateFormNew } from "./components";
import { CreateForm } from "./CreateForm";
export const MainForm = () => {
  const [loading, setLoading] = useState(false);
  const { id_formulario }: any = useParams();

  useRouteScreenTitle(
    !id_formulario ? "Nuevo formulario" : "Editar formulario"
  );

  return !id_formulario ? (
    <CreateFormNew>
      {({ template, initialValues }: any) =>
        loading ? (
          <Loading />
        ) : (
          <CreateForm
            initialValues={initialValues}
            template={template}
            setLoading={setLoading}
          />
        )
      }
    </CreateFormNew>
  ) : (
    <CreateFormEdit editIdForm={id_formulario}>
      {({ template, data, working, question, reFetch }: any) =>
        working || loading ? (
          <Loading />
        ) : (
          <CreateForm
            initialValues={data}
            template={template}
            editIdForm={id_formulario}
            question={question}
            reFetch={reFetch}
            setLoading={setLoading}
          />
        )
      }
    </CreateFormEdit>
  );
};
