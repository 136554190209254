import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class TypeDocumentDAO extends LibbyFetchDAO {
  constructor() {
    super("tipodocumento", "idtipodocumento");
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
