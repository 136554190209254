export enum SIDEMENU_ID {
  // Side Menu
  RECLAIM_OBSERVATION = 1,
  ESTABLISHMENT,
  ABM_USER,
  CONFIGURATION,
  CREATED_FORM,
  GOOGLE_MAPS,
  COMUNICATION,
}
