import { MenuItem } from "src/commons";
import { ConfiguracionAreaResponsable } from "src/screens/Private/ConfiguracionAreaResponsable";
import { SubAreas } from "src/screens/Private/ConfiguracionAreaResponsable/ReclaimArea/components/SubAreas";

export const routes: MenuItem[] = [
  {
    key: 'subAreas',
    basePath: '/sub-areas/:area',
    target: {
      component: SubAreas,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Sub Areas',
    children: [],
  },
  {
    key: "configuracion",
    basePath: "/",
    target: {
      component: ConfiguracionAreaResponsable,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Configuracion",
    children: [],
    default: true,
  },
];
