import React, { useState, useCallback, useMemo } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { Typography, makeStyles, Box } from "@material-ui/core";
import {
  useFormularioDAO,
  useFormularioLibbyFetch,
} from "src/app/business";
import { confirmDialog, OptionsModal } from "src/commons";
import { exportToCsv } from "src/utils";

const useStyles = makeStyles({
  status_1: {
    background: "#CBF1D9",
    padding: "4px 8px",
    borderRadius: "3px",
    color: "#000000",
    fontSize: 13,
    textAlign: "center",
    width: "50%",
  },
  status_2: {
    background: "#FFFBEF",
    padding: "4px 8px",
    borderRadius: "3px",
    color: "#766200",
    fontSize: 13,
    textAlign: "center",
    width: "50%",
  },
  status_3: {
    background: "#ebebeb",
    padding: "4px 8px",
    borderRadius: "3px",
    color: "#8E8E8E",
    fontSize: 13,
    textAlign: "center",
    width: "50%",
  },
});

const statusRowFinish: Record<number, boolean> = {
  1: false,
  2: true,
  3: true,
};

const statusRowEdit: Record<number, boolean> = {
  1: true,
  2: false,
  3: true,
};

const statusPreview: Record<number, boolean> = {
  1: false,
  2: true,
  3: false,
};

export const useListFormLogic = ({
  direction,
  orderBy,
  filterSearch = {},
}: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const status: Record<number, string> = useMemo(
    () => ({
      1: classes.status_1,
      2: classes.status_2,
      3: classes.status_3,
    }),
    [classes]
  );

  const { data, working, fetchMore, reFetch } = useFormularioLibbyFetch({
    limit: 20,
    direction,
    orderBy,
    filter: filterSearch,
  });

  const dataRows = useMemo(
    () =>
      data?.map((item, index) => ({
        nombre: decodeURI(item.nombre_de_formulario),
        fecha_create: moment(item.fecha_creacion).format("D/MM/YYYY"),
        response: item?.respuestas?.length || 0,
        fecha_finish:
          item.fecha_creacion === item.fecha_baja
            ? "-"
            : moment(item.fecha_baja).format("D/MM/YYYY"),
        active: (
          <Typography
            className={status[item?.estado_formulario?.id_estado_formulario]}
          >
            {item?.estado_formulario?.descripcion}
          </Typography>
        ),
        ...item,
      })),
    [data, status]
  );

  const FormularioDAO = useFormularioDAO();

  const handleActiveForm = useCallback(
    async (form) => {
      try {
        if (
          data?.some(
            (item, index) => item.estado_formulario.id_estado_formulario === 1
          )
        ) {
          enqueueSnackbar("Solo puede haber un formulario activo", {
            variant: "error",
          });
        } else {
          const confirm = await confirmDialog.show({
            title: "Activar formulario",
            confirmText: "Activar",
            cancelText: "Cancelar",
            content:
              "¿Desea activar el formulario?, una vez activado no podra editar el formulario!",
          });
          if (confirm) {
            setLoading(true);
            const formObj = {
              id_formulario: form.id_formulario,
              nombre_de_formulario: form.nombre_de_formulario,
              fecha_creacion: form.fecha_creacion,
              fecha_baja: form.fecha_baja,
              estado_formulario: { id_estado_formulario: 1 },
            };
            await FormularioDAO.aspect("aspect-web").save(formObj);

            enqueueSnackbar("El formulario se ha activado", {
              variant: "success",
            });
            reFetch();
          }
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar("Se ha producido un error intentelo nuevamente", {
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [data, FormularioDAO, reFetch, enqueueSnackbar]
  );

  const handleFinishForm = useCallback(
    async (form) => {
      try {
        const confirm = await confirmDialog.show({
          title: "Finalizar formulario",
          confirmText: "Finalizar",
          cancelText: "Cancelar",
          content: "¿Desea finalizar el formulario?",
        });
        if (confirm) {
          setLoading(true);
          const formObj = {
            id_formulario: form.id_formulario,
            nombre_de_formulario: form.nombre_de_formulario,
            fecha_creacion: form.fecha_creacion,
            fecha_baja: moment().toISOString(),
            estado_formulario: { id_estado_formulario: 3 },
          };
          await FormularioDAO.aspect("aspect-web").save(formObj);
          enqueueSnackbar("El formulario se ha finalizado", {
            variant: "success",
          });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar("Se ha producido un error intentelo nuevamente", {
          variant: "error",
        });
      } finally {
        reFetch();
        setLoading(false);
      }
    },
    [FormularioDAO, reFetch, enqueueSnackbar, setLoading]
  );

  const actionButtons = useMemo(
    () => [
      {
        label: "Activar",
        onClick: (row: any) => handleActiveForm(row),
        hide: (row: any) =>
          row.estado_formulario.id_estado_formulario === 2 ? false : true,
      },
      {
        label: "Finalizar",
        onClick: (row: any) => handleFinishForm(row),
        hide: (row: any) =>
          statusRowFinish[row.estado_formulario.id_estado_formulario],
      },
      {
        label: "Editar formulario",
        onClick: (row: any) =>
          history.push(`formularios/nuevo-formulario/${row.id_formulario}`),
        hide: (row: any) =>
          statusRowEdit[row.estado_formulario.id_estado_formulario],
      },
      {
        label: "Vista previa",
        onClick: (row: any) =>
          history.push(`formularios/nuevo-formulario/${row.id_formulario}`),
        hide: (row: any) =>
          statusPreview[row.estado_formulario.id_estado_formulario],
      },
      {
        label: "Descargar respuestas",
        onClick: (row: any) => {
          exportToCsv(`${row.nombre}.csv`, row?.respuestas);
        },
        hide: (row: any) =>
          statusPreview[row.estado_formulario.id_estado_formulario],
      },
    ],
    [history, handleActiveForm, handleFinishForm]
  );

  const column = [
    {
      id: "nombre",
      label: "Nombre de formulario",
      width: "5%",
      orderById: "nombre_de_formulario",
    },
    {
      id: "fecha_create",
      label: "Fecha de creación",
      width: "5%",
      orderById: "fecha_creacion",
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: "active",
      label: "Estado",
      width: "5%",
      orderById: "estado_formulario.id_estado_formulario",
    },
    {
      id: "fecha_finish",
      label: "Fecha de finalización",
      width: "5%",
      orderById: "fecha_baja",
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: "response",
      label: "Respuestas",
      width: "5%",
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: "icon",
      label: "",
      width: "5%",
      hideSortIcon: true,
      noSort: true,
      style: { textAlign: "left" },
      render: (value: any, row: any) => (
        <Box whiteSpace="nowrap" textAlign="left">
          <OptionsModal options={actionButtons} data={row} />
        </Box>
      ),
    },
  ];

  return {
    column,
    rows: dataRows,
    loading: working,
    loadingExternal: loading,
    fetchMore,
  };
};
