import React, { useMemo, useState } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { HeaderInformation } from "src/screens/Private/MesaTerritorial/ReclaimObservation/ReclaimObservationList/components/HeaderInformation";
import { Grid } from "@material-ui/core";
import { InfoDrawer } from "src/commons";
import { useInformationRow } from "../../helpers/useInformationRow";
import { useFetchInformationRow } from "../../helpers/useFetchInformationRow";
import { ContainerInformation } from "../ContainerInformation/ContainerInformation";
import { DrawerList } from "../DrawerList";
import { DrawerPriority } from "../DrawerPriority";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    overflow: "auto",
    maxHeight: "490px",
  },
  iconStyle: {
    color: "#5E5CE6",
  },
  iconStyleSub: {
    color: "#1C1C1E",
  },
  childColumnFlex: {
    borderWidth: 1,
    border: "1px solid #e0e0e0",
    borderRadius: 8,
    marginBottom: 10,
    padding: 10,
  },
  childColumnFlexDataSub: {
    borderWidth: 1,
    border: "1px solid #e0e0e0",
    borderRadius: 8,
    marginBottom: 10,
    padding: 10,
    width: "46%",
    marginRight: 10,
  },
  titleDataFirst: {
    fontSize: 13,
    color: "#8C8C8C",
  },
  subtitleDataFirst: {
    fontSize: 16,
  },
  containerDrawer: {
    padding: 10,
  },
  widthDrawe: {
    width: 300,
  },
}));

export const InformationRow = ({ row, setState }: any) => {
  const classes = useStyles();
  const [selectDrawer, setSelectDrawe] = useState(0);
  const [drawer, setDrawer] = useState<{ position: string; open: boolean }>({
    position: "left",
    open: false,
  });
  const useData = useFetchInformationRow(row);
  const headerPropsData: any = useMemo(
    () => ({
      image: row?.localizacion?.imagenfachada,
      title: row?.title || "",
      calle: `${row.localizacion?.domicilio.calle} ${row.localizacion?.domicilio.altura}`,
      cp: row.localizacion?.domicilio.codigopostalbarrio.codigopostal,
      cue: row.localizacion?.cueanexo,
      distrito: row.distritoescolar.nombre,
    }),
    [row]
  );

  const getEstablishmentPriority = useMemo(() => {
    const dataPriority = useData?.establishmentPriority.map(
      (item: any, index: any) => ({
        ...item,
        contenido: item.contenido,
        fecha: moment(item.fecha).format("L"),
        author: `${item.usuario.persona.nombre} ${item.usuario.persona.apellido}`,
      })
    );
    return dataPriority;
  }, [useData]);

  const infoData = useInformationRow(
    setState,
    setDrawer,
    row,
    useData,
    setSelectDrawe
  );

  return (
    <Grid container className={classes.container}>
      <HeaderInformation row={headerPropsData} onViewMap={infoData.onViewMap}/>
      <ContainerInformation
        info={infoData.containerPropsData}
        loading={useData.loading}
      />
      <InfoDrawer
        drawerState={drawer}
        setDrawerState={setDrawer}
        customStyle={classes.widthDrawe}
      >
        {selectDrawer === 10 ? (
          <DrawerPriority infoData={getEstablishmentPriority || []} />
        ) : (
          <DrawerList infoData={infoData} selectDrawer={selectDrawer} />
        )}
      </InfoDrawer>
    </Grid>
  );
};
