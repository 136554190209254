export const columns = [
  {
    id: "establishment",
    label: "Establecimiento",
    width: "5%",
    orderById: "nombre",
  },
  {
    id: "cue",
    label: "CUE",
    width: "5%",
    orderById: "nombre",
  },
  {
    id: "direction",
    label: "Dirección ",
    width: "5%",
    orderById: "nombre",
  },
  {
    id: "icon",
    label: "",
    width: "4%",
    hideSortIcon: true,
    noSort: true,
  },
];