export const columns = [
    {
      id: "nombre",
      label: "Funcionario",
      width: "5%",
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: "dni",
      label: "N° de documento",
      width: "5%",
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: "icon",
      label: "",
      width: "4%",
      hideSortIcon: true,
      noSort: true,
    },
  ];