import React from "react";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import { makeStyles } from "@material-ui/core/styles";
import { UserAvatar } from "src/commons";
import { primary } from "src/theme/colors";

const useStyles = makeStyles((theme) => ({
  titleSchool: {
    fontSize: 24,
  },
  informationReclaim: {
    background: "#F5F5F5",
    padding: 10,
    marginTop: 15,
  },
  subtitles: {
    color: "#8C8C8C",
    fontSize: 15,
  },
  avatar: {
    marginRight: 15,
  },
  subContainer: {
    marginTop: 15,
  },
  map: {
    textAlign: "right",
    marginTop: -5,
  },
  mapText: {
    color: primary.lightBlue
  }
}));

export const HeaderInformation = ({ row, onViewMap }: any) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={2} className={classes.avatar}>
        <UserAvatar avatarWidth={120} avatarHeight={120} img={row.image} />
      </Grid>
      <Grid item xs={8}>
        <Grid container>
          <Typography className={classes.titleSchool}>{row.title}</Typography>
        </Grid>
        <Grid container className={classes.subContainer}>
          <Typography className={classes.subtitles}>
            {row.calle} | C.P: {row.cp}
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.subtitles}>
              CUE Anexo: {row.cue} | D.E. {row.distrito}
            </Typography>
          </Grid>
          {onViewMap && (
            <Grid item xs={6} className={classes.map} onClick={onViewMap}>
              <Typography className={classes.mapText}>
                <RoomIcon style={{ fontSize: 18, color: "#5E5CE6", position: "relative", top: 3, marginRight: 5, }} />
                Ver mapa
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
