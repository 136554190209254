export const categoryMobileApp = [
    {
      id_category: 1,
      descripcion: "General",
    },
    {
      id_category: 2,
      descripcion: "Prioridades",
    },
    {
      id_category: 3,
      descripcion: "Conducción",
    },
    {
      id_category: 4,
      descripcion: "Docentes",
    },
    {
      id_category: 5,
      descripcion: "No docentes",
    },
    {
      id_category: 6,
      descripcion: "Becas",
    },
    {
      id_category: 7,
      descripcion: "Estudiantes",
    },
    {
      id_category: 8,
      descripcion: "Comedor",
    },
    {
      id_category: 9,
      descripcion: "Transporte",
    },
  ];