import React from 'react';
import { useField } from 'react-final-form-hooks';
import { Input, InputProps } from '../../../app/components/inputs/Input';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';

// FIXME: type form props
export interface FormInputProps {
  name: string;
  label: string;
  form: any;
  inputProps?: InputProps;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  className?: any;
}

export const FormInput = ({
  type,
  name,
  form,
  label,
  disabled,
  placeholder,
  inputProps = {},
  className = {}
}: FormInputProps): JSX.Element => {
  const field = useField(name, form);

  return (
    <Input
      placeholder={placeholder}
      label={label}
      disabled={disabled}
      {...field.input}
      {...getErrorAndHelperTextNoTranslation(field)}
      {...inputProps}
      type={type}
      className={className}
    />
  );
};
