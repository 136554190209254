import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'notificacionterritorio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idnotificacionterritorio',
      labelKey: 'mensaje',
    },
    withSelectedChips: {
      idKey: 'idnotificacionterritorio',
      labelKey: 'mensaje',
    },
  },
};

const optionsToUse = !businessOptions.NotificationTerritory ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.NotificationTerritory,
  hoc: { ...defaultOptions.hoc, ...businessOptions.NotificationTerritory.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useNotificationTerritoryFetchById = fetchById;
export const useNotificationTerritoryLibbyCall = libbyCall;
export const useNotificationTerritoryDAO = libbyDAO;
export const useNotificationTerritoryLibbyFetch = libbyFetch;

// components/hoc
export const NotificationTerritoryAutocomplete = entityAutocomplete;
export const NotificationTerritoryCall = renderPropCall;
export const NotificationTerritoryById = renderPropFetchById;
export const NotificationTerritorySelect = simpleSelect;
export const NotificationTerritoryAccordionSelector = accordionSelector;
export const NotificationTerritorySelectedChips = selectedChips;

// context
// ByIdContext
export const useNotificationTerritoryByIdContext = useByIdContext;
export const NotificationTerritoryByIdProvider = ByIdProvider;
export const NotificationTerritoryByIdContext = ByIdContext;
// GeneralContext
export const useNotificationTerritoryContext = useGeneralContext;
export const NotificationTerritoryProvider = GeneralProvider;
export const NotificationTerritoryContext = GeneralContext;
