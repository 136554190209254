import React, { useState, useMemo, useCallback } from "react";
import {
  Typography,
  Grid,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import { AccountAutoComplete } from "src/app/business";
import { Account } from "src/app/models";
import { InfoTable, useDebounce } from "src/commons";
import { Checkbox } from "@material-ui/core";
import { columns } from "./helpers/columns";

const modalTableUserStyles = makeStyles(() => ({
  container: {
    padding: "20px 30px",
  },
  containerForm: {
    alignItems: "center",
  },
  allEstablish: {
    marginTop: 5,
    textAlign: "center",
  },
  tableContainer: {
    maxHeight: "350px",
  },
}));

interface userSelect {
  idusuario: number;
  nombre: string;
  dni: string;
}

interface SelectUserScreenProps {
  setUserSelect: (user: any) => void;
  userSelect: userSelect[];
  loading: boolean;
  checkbox: boolean;
  setCheckbox: (e: boolean) => void;
}

export const SelectUserScreen = ({
  setUserSelect,
  userSelect,
  loading,
  checkbox,
  setCheckbox,
}: SelectUserScreenProps) => {
  const classes = modalTableUserStyles();
  const [inputValue, setInputValue] = useState("");
  const searchDebounced = useDebounce(inputValue, 1000);

  const handleAddAccount = useCallback((user: any) => {
    if (user) {
      setUserSelect({
        idusuario: user.idusuario,
        nombre: `${user?.persona?.nombre} ${user?.persona?.apellido}`,
        dni: user?.persona?.documento,
      });
    }
  }, [setUserSelect]);

  const filterAccount = useMemo(
    () => ({
      id: !searchDebounced.length
        ? [{}]
        : [
            {
              path: "email",
              value: searchDebounced,
              method: "includes",
            },
            {
              path: "persona.nombre",
              value: searchDebounced,
              method: "includes",
            },
            {
              path: "persona.apellido",
              value: searchDebounced,
              method: "includes",
            },
          ],
    }),
    [searchDebounced]
  );

  const rowsData = useMemo(
    () =>
      userSelect.map((item: userSelect) => ({
        ...item,
        icon: (
          <IconButton onClick={() => handleAddAccount(item)}>
            <CloseIcon />
          </IconButton>
        ),
      })),
    [userSelect, handleAddAccount]
  );

  return (
    <Grid container>
      <Grid container className={classes.containerForm}>
        <Grid item xs={6}>
          <AccountAutoComplete
            value=""
            filter={filterAccount}
            label="Seleccionar destinatarios"
            setInputValue={setInputValue}
            disabled={checkbox}
            loading={loading}
            onChange={(account: Account) => handleAddAccount(account)}
          />
        </Grid>
        <Grid item xs={6} className={classes.allEstablish}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox}
                onChange={(e) => setCheckbox(e.target.checked)}
                name="checkedB"
                color="primary"
                disabled={loading}
              />
            }
            label="Enviar a todos los funcionarios"
          />
        </Grid>
        <Grid container>
          <InfoTable
            rows={rowsData || []}
            columns={columns}
            rowIdKey="idusuario"
            customStyle={classes.tableContainer}
          />
          {checkbox && (
            <Typography>
              Este mensaje se enviará a todos los funcionarios
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
