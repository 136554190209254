import { MenuItem } from "src/commons";
import { Comunication } from "src/screens/Private/MesaTerritorial/Comunication";

export const routes: MenuItem[] = [
  {
    key: "comunication",
    basePath: "/",
    target: {
      component: Comunication,
      path: "/",
    },
    enabled: true,
    icon: "List",
    text: "Comunicacion",
    children: [],
    default: true,
  },
];
