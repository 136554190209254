import React from "react";
import { Grid } from "@material-ui/core";
import { useForm, useField } from "react-final-form-hooks";
import { customFormDialog, FooterForm } from "src/commons";
import { BarrioSelect, ComunaSelect, NivelSelect, SchoolDistrictSelect } from "src/app/business";

const customStyles = {
  footer: { marginTop: 20 },
  containerGrid: { paddingRight: 20, paddingLeft: 20, paddingBottom: 17 },
};

export const ModalFilterEstablishmentList = ({ values }: any) => {
  const formValues = values;

  const { form, handleSubmit } = useForm({
    initialValues: formValues,
    onSubmit: (values) => customFormDialog.handleConfirm(values),
  });

  const nivelForm = useField("nivel", form);
  const distritoForm = useField("distrito", form);
  const comunaForm = useField("comuna", form);
  const barrioForm = useField("barrio", form);

  const arrSelect: any = [
    {
      component: NivelSelect,
      title: "Nivel",
      placeholder: "Seleccioná nivel",
      name: nivelForm.input.name,
      handleChange: nivelForm.input.onChange,
      value: nivelForm.input.value,
      valueKey: "idnivel",
    },
    {
      component: SchoolDistrictSelect,
      title: "Distrito",
      placeholder: "Seleccioná distrito",
      name: distritoForm.input.name,
      handleChange: distritoForm.input.onChange,
      value: distritoForm.input.value,
      valueKey: "iddistritoescolar",
      labelKey: "nombre"
    },
    {
      component: ComunaSelect,
      title: "Comuna",
      placeholder: "Seleccioná comuna",
      name: comunaForm.input.name,
      handleChange: comunaForm.input.onChange,
      value: comunaForm.input.value,
      valueKey: "idcomuna",
      labelKey: "nombre"
    },
    {
      component: BarrioSelect,
      title: "Barrio",
      placeholder: "Seleccioná barrio",
      name: barrioForm.input.name,
      handleChange: barrioForm.input.onChange,
      value: barrioForm.input.value,
      valueKey: "idbarrio",
      labelKey: "barrio"
    },
  ];

  const buttonConfig = [
    {
      title: "Cancelar",
      handleOnClick: () => customFormDialog.handleCancel(),
      type: "secondary",
      size: "small",
    },
    {
      title: "Limpiar",
      handleOnClick: () => customFormDialog.handleCancel(""),
      type: "cancel",
      size: "small",
    },
    {
      title: "Aplicar",
      handleOnClick: () => null,
      size: "small",
      typeButton: "submit",
    },
  ];

  return (
    <Grid container style={{ width: "95%", margin: "auto", marginTop: 10 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} style={customStyles.containerGrid}>
          {arrSelect.map((e: any) => (
            <Grid item xs={6}>
              {e.component({ ...e })}
            </Grid>
          ))}
          <FooterForm
            buttonConfig={buttonConfig}
            spacing={1}
            customStyle={customStyles.footer}
          />
        </Grid>
      </form>
    </Grid>
  );
};
