import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'especialidad';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idespecialidad',
      labelKey: 'especialidad',
    },
    withSelectedChips: {
      idKey: 'especialidad',
      labelKey: 'especialidad',
    },
  },
};

const optionsToUse = !businessOptions.Especialidad ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Especialidad,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Especialidad.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useEspecialidadFetchById = fetchById;
export const useEspecialidadLibbyCall = libbyCall;
export const useEspecialidadDAO = libbyDAO;
export const useEspecialidadLibbyFetch = libbyFetch;

// components/hoc
export const EspecialidadAutocomplete = entityAutocomplete;
export const EspecialidadCall = renderPropCall;
export const EspecialidadById = renderPropFetchById;
export const EspecialidadSelect = simpleSelect;
export const EspecialidadAccordionSelector = accordionSelector;
export const EspecialidadSelectedChips = selectedChips;

// context
// ByIdContext
export const useEspecialidadByIdContext = useByIdContext;
export const EspecialidadByIdProvider = ByIdProvider;
export const EspecialidadByIdContext = ByIdContext;
// GeneralContext
export const useEspecialidadContext = useGeneralContext;
export const EspecialidadProvider = GeneralProvider;
export const EspecialidadContext = GeneralContext;
