import { useCallback, useEffect, useRef, useState } from "react";
import { useLibby } from "@phinxlab/libby-rest-web";
import { AnyObject, usePrevious } from "src/commons";
import _ from "lodash";

// La diferencia entre fixedFilter y filters, es que el primero no reacciona a cambios automaticamente
export type LibbyFetchOptions = {
  filter?: AnyObject;
  fixedFilter?: AnyObject;
  orderBy?: string | string[];
  daoName: string;
  limit?: number;
  checkDuplication?: boolean;
  checkDuplicationDeep?: boolean;
  direction?: "asc" | "desc";
  aspect?: string;
  enabled?: boolean;
};

export interface LibbyFetchReturn<T> {
  data?: T[];
  working: boolean;
  fetchMore: () => void;
  reFetch: () => void;
  addCreate: (data: any) => void;
  setMergedData: (prev: T[]) => void;
  updateData: (dataUpdate: T, id: string) => void;
  setFixedFilters: (newFixedFilter: AnyObject) => void;
}

export const useLibbyFetch = <T>({
  filter,
  fixedFilter = {},
  orderBy,
  direction = "asc",
  daoName = "",
  limit: initialLimit = 40,
  checkDuplication = true,
  checkDuplicationDeep = false,
  aspect,
  enabled = true,
}: LibbyFetchOptions): LibbyFetchReturn<T> => {
  const { libby } = useLibby([daoName]);
  const fixedFilterRef = useRef<AnyObject>(fixedFilter);
  const prevFilter = usePrevious(filter);
  const [loading, setLoading] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const [initialFetch, setInitialFetch] = useState(enabled);
  const [mergedData, setMergedData] = useState<T[]>([]);
  const [limit] = useState(initialLimit);
  const [offset, setOffset] = useState(0);

  const fetch = useCallback(async () => {
    if (!daoName) {
      // eslint-disable-next-line
      console.log("daoName required!");
    } else if (!libby[daoName]) {
      // eslint-disable-next-line
      console.log(
        "DAO not found, be sure that DatabaseConnector HOC has the daoName in its config"
      );
    } else if (!endReached) {
      try {
        setLoading(true);
        if (aspect) {
          libby[daoName].aspect(aspect);
        }
        const joinedFilters = filter
          ? { ...fixedFilterRef.current, ...filter }
          : fixedFilterRef.current;
        const data = await libby[daoName].fetch({
          filter: joinedFilters,
          orderBy,
          limit,
          offset,
          direction,
        });
        setMergedData((prev: T[]) => {
          // this is to avoid duplications
          if (checkDuplicationDeep && data?.length) {
            // filter objects by Deep equality
            // this check is useful when pk is not in the first depth level of the data response
            return data.filter(
              (itemA: object, idxA: number, array: Array<object>) => {
                return !array
                  .slice(idxA + 1)
                  .some((itemB: object) => _.isEqual(itemA, itemB));
              }
            );
          } else {
            const copy: T[] = [...prev];
            const { pk } = libby[daoName];
            if (data && data.length) {
              data.forEach((item: T) => {
                if (
                  !checkDuplication ||
                  !copy.find(
                    (el) =>
                      el[pk as keyof typeof item] ===
                      item[pk as keyof typeof item]
                  )
                ) {
                  copy.push(item);
                }
              });
            }
            return copy;
          }
        });
        setOffset(offset + limit);
        if (!data?.length) {
          setEndReached(true);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        throw e;
      }
    }
  }, [
    checkDuplicationDeep,
    checkDuplication,
    daoName,
    filter,
    libby,
    limit,
    offset,
    orderBy,
    direction,
    endReached,
    aspect,
  ]);

  const reFetch = useCallback(() => {
    setMergedData([]);
    setOffset(0);
    setEndReached(false);
    setInitialFetch(false);
  }, []);
  useEffect(() => {
    if (enabled) {
      reFetch();
    }
  }, [orderBy, direction, reFetch, enabled]);
  useEffect(() => {
    if (prevFilter !== filter && enabled) {
      reFetch();
    }
  }, [filter, prevFilter, reFetch, enabled]);
  useEffect(() => {
    if (!initialFetch && enabled) {
      setInitialFetch(true);
      fetch();
    }
  }, [fetch, initialFetch, enabled]);

  const [debounce, setDebounce] = useState<NodeJS.Timeout | null>(null);
  const fetchMore = useCallback(() => {
    if (debounce) {
      clearTimeout(debounce);
    }
    setDebounce(
      setTimeout(() => {
        fetch();
      }, 100)
    );
  }, [debounce, fetch]);

  const addCreate = useCallback((data: any) => {
    setMergedData((prev) => {
      const copy: T[] = [...prev];
      copy.push(data);
      return copy;
    });
  }, []);

  const updateData = useCallback(
    (dataUpdate: T, id: string) => {
      setMergedData((prev) => {
        const copy: T[] = [...prev];
        if (dataUpdate[id as keyof typeof dataUpdate]) {
          const result = copy.findIndex(
            (value) =>
              value[id as keyof typeof dataUpdate] ===
              dataUpdate[id as keyof typeof dataUpdate]
          );
          copy[result] = dataUpdate;
        }
        return copy;
      });
    },
    [setMergedData]
  );

  const setFixedFilters = useCallback(
    (newFixedFilters) => {
      fixedFilterRef.current = newFixedFilters;
      if (reFetch) reFetch();
    },
    [reFetch]
  );

  return {
    data: mergedData,
    working: loading,
    fetchMore,
    reFetch,
    addCreate,
    setMergedData,
    updateData,
    setFixedFilters,
  };
};
