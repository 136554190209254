import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'establecimiento_categorias';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'id_establecimientos_categorias',
      labelKey: 'id_localizacion',
    },
    withSelectedChips: {
      idKey: 'id_establecimientos_categorias',
      labelKey: 'id_localizacion',
    },
  },
};

const optionsToUse = !businessOptions.EstablecimientoCategorias ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.EstablecimientoCategorias,
  hoc: { ...defaultOptions.hoc, ...businessOptions.EstablecimientoCategorias.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useEstablecimientoCategoriasFetchById = fetchById;
export const useEstablecimientoCategoriasLibbyCall = libbyCall;
export const useEstablecimientoCategoriasDAO = libbyDAO;
export const useEstablecimientoCategoriasLibbyFetch = libbyFetch;

// components/hoc
export const EstablecimientoCategoriasAutocomplete = entityAutocomplete;
export const EstablecimientoCategoriasCall = renderPropCall;
export const EstablecimientoCategoriasById = renderPropFetchById;
export const EstablecimientoCategoriasSelect = simpleSelect;
export const EstablecimientoCategoriasAccordionSelector = accordionSelector;
export const EstablecimientoCategoriasSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstablecimientoCategoriasByIdContext = useByIdContext;
export const EstablecimientoCategoriasByIdProvider = ByIdProvider;
export const EstablecimientoCategoriasByIdContext = ByIdContext;
// GeneralContext
export const useEstablecimientoCategoriasContext = useGeneralContext;
export const EstablecimientoCategoriasProvider = GeneralProvider;
export const EstablecimientoCategoriasContext = GeneralContext;
