import { Typography } from "@material-ui/core";
import Delete  from "@material-ui/icons/Delete";
import Menu  from "@material-ui/icons/Menu";
import Edit  from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useAreaResponsabilityWebDAO, useAreaResponsabilityWebLibbyFetch } from "src/app/business/business/AreaResponsabilityWeb";
import { AreaResponsable } from "src/app/models/business/AreaResponsability";
import {
  confirmDialog,
  customFormDialog,
  HeaderTerri,
  IconTables,
  InfoTable,
  Loading,
  useDebounce,
  useOrderByState,
} from "src/commons";
import { ModalCreateAreaResponsability } from "./components";
import { column } from "./helpers";

const useStyles = makeStyles({
  status_1: {
    background: "#CBF1D9",
    padding: "4px 8px",
    borderRadius: "3px",
    color: "#000000",
    fontSize: 13,
    textAlign: "center",
    width: "50%",
  },
  status_2: {
    background: "#FFFBEF",
    padding: "4px 8px",
    borderRadius: "3px",
    color: "#766200",
    fontSize: 13,
    textAlign: "center",
    width: "50%",
  },
  status_3: {
    background: "#ebebeb",
    padding: "4px 8px",
    borderRadius: "3px",
    color: "#8E8E8E",
    fontSize: 13,
    textAlign: "center",
    width: "50%",
  },
});

export const ReclaimArea = () => {
  const history = useHistory();
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 1000);
  const areaResponsableWebDAO = useAreaResponsabilityWebDAO();

  const { orderBy, direction, onOrderByDirectionChange } = useOrderByState(
    "descripcion",
    "asc"
  );

  const filterSearch = useMemo(
    () => ({
      personaFilter: [
        {
          path: "descripcion",
          value: searchDebounced,
          method: "includes",
        },
      ],
    }),
    [searchDebounced]
  );

  const {
    data: areaResponsability = [],
    working: workingArea,
    fetchMore: fetchMoreArea,
    reFetch,
  } = useAreaResponsabilityWebLibbyFetch({
    filter: filterSearch,
    orderBy,
    direction
  });

  const handleAddArea = useCallback(
    async (area, edit) => {
      const formValue = area.descripcion;
      const textModal = !area.idarearesponsable
        ? "Crear área responsable"
        : "Editar área responsable";
      await customFormDialog.show({
        title: textModal,
        renderComponent: (
          <ModalCreateAreaResponsability
            isValue={formValue}
            reFetch={reFetch}
            areaSelect={area}
          />
        ),
        sizeWidth: "sm",
      });
    },
    [reFetch]
  );

  const handleDisabledArea = useCallback(
    async (area: AreaResponsable) => {
      const textPrimary = area.disabled ? "Habilitar" : "Deshabilitar";
      const confirm = await confirmDialog.show({
        title: "",
        confirmText: textPrimary,
        cancelText: "Cancelar",
        content: `¿Desea ${textPrimary} el area?`,
        alternativeColor: area.disabled,
      });
      if (confirm) {
        const areaDisabled = {
          ...area,
          disabled: area.disabled ? false : true,
        };

        await areaResponsableWebDAO.aspect("aspect-web").save(areaDisabled);
        reFetch();
      }
    },
    [areaResponsableWebDAO, reFetch]
  );

  const icoTables = useCallback(
    (area) => [
      {
        text: "Editar",
        icon: <Edit />,
        handleOnClick: () => handleAddArea(area, "edit"),
        disabled: false,
      },
      {
        text: "Sub Áreas",
        icon: <Menu />,
        handleOnClick: () =>
          history.push(`configuracion/sub-areas/${area.idarearesponsable}`),
        disabled: false,
      },
      {
        text: area?.disabled ? "Habilitar" : "Deshabilitar",
        icon: <Delete />,
        handleOnClick: () => handleDisabledArea(area),
        disabled: false,
      },
    ],
    [history, handleAddArea, handleDisabledArea]
  );

  const status: Record<number, string> = useMemo(
    () => ({
      1: classes.status_1,
      2: classes.status_2,
      3: classes.status_3,
    }),
    [classes]
  );

  const rows = useMemo(
    () =>
      areaResponsability.map((item) => ({
        ...item,
        icon: <IconTables content={icoTables(item)} />,
        active: (
          <Typography className={item.disabled ? status[1] : status[2]}>
            {item?.disabled ? "Inactivo" : "Activo"}
          </Typography>
        ),
      })),
    [areaResponsability, icoTables, status]
  );

  return (
    <HeaderTerri
      rightLayout
      searchValue={search}
      setSearchValue={setSearch}
      titleButton="Crear nueva área"
      handleButton={handleAddArea}
      placeholder="Buscar"
    >
      {workingArea ? (
        <Loading />
      ) : (
        <InfoTable
          working={workingArea}
          rows={rows || []}
          columns={column}
          onBottomScroll={fetchMoreArea}
          rowIdKey="idarearesponsable"
          direction={direction}
          orderBy={orderBy}
          onSortChange={onOrderByDirectionChange}
        />
      )}
    </HeaderTerri>
  );
};
