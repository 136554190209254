import React, { useState, useMemo, useCallback } from "react";
import moment from "moment";
import { Container } from "@material-ui/core";
import { useForm, useField } from "react-final-form-hooks";
import { LibbyObject, useRouteScreenTitle } from "src/commons";
import {
  useClaimDAO, useUserEstablishmentLibbyFetch,
} from "src/app/business";
import { ListForm } from "./components/ListForm";
import { AccountSelect } from "src/app/business/Account";


//TODO: esto no se usa borrar
export const FormClaim = ({ libby }: LibbyObject) => {
  useRouteScreenTitle('Aulas Virtuales');

  const [disabled, setDisabled] = useState(false);
  const [selectUser, setSelectUser] = useState<any>(null);
  const [selectEstablishment, setSelectEstablishment] = useState<any>(null);
  const [selectNivel, setSelectNivel] = useState("");
  const [selectAreaResponsable, setSelectAreaResponsable] = useState("");

  const claimDAO = useClaimDAO();

  const onSubmit = useCallback(
    async (valuesForm) => {
      const claimToSave = {
        establecimiento: {
          idestablecimiento: selectEstablishment.idestablecimiento,
        },
        arearesponsable: { idarearesponsable: selectAreaResponsable },
        usuario: selectUser?.idusuario,
        fecha: moment().toISOString(),
        establecimientovisita: null,
        tipoformulario: { idtipoformulario: 1 },
        ...valuesForm,
      };
      await claimDAO.aspect("aspect-web").save(claimToSave);
    },
    [
      selectUser,
      selectEstablishment,
      //selectNivel,
      selectAreaResponsable,
      claimDAO,
    ]
  );

  const validate = (values: any) => {
    const errors = {};
    if (
      !values.asunto ||
      !values.nroreclamo ||
      !values.observaciones ||
      !selectAreaResponsable ||
      !selectEstablishment
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    return errors;
  };

  const formValues = {};
  const { form, handleSubmit, submitting, pristine } = useForm({
    initialValues: formValues,
    onSubmit,
    validate,
  });

  const asuntoForm = useField("asunto", form);
  const numReclamForm = useField("nroreclamo", form);
  const observationForm = useField("observaciones", form);

  const filterUserEstablishment = useMemo(
    () => ({
      id:
        selectUser?.admin === 1
          ? [{}]
          : [
              {
                path: "idusuario",
                value: selectUser?.idusuario,
              },
            ],
    }),
    [selectUser]
  );

  const {
    data: dataEstablishment,
    working: workingEstablishment,
  } = useUserEstablishmentLibbyFetch({
    filter: filterUserEstablishment,
  });

  const params = useMemo(
    () =>
      dataEstablishment?.map((item: any) => ({
        path: "localizacion.idlocalizacion",
        value: Number(item.cueanexo),
      })),
    [dataEstablishment]
  );

  return (
    <Container
      style={{ background: "#ffffff", padding: 20, width: "65%", marginTop: 10, borderRadius: 4, marginBottom: 20, }}
    >
      <AccountSelect
        handleChange={(e) => setSelectUser(e.target.value)}
        value={selectUser}
        title="Seleccione usuario"
        labelKey="username"
        valueKey=""
        orderBy="username"
        direction="asc"
      />
      {selectUser && !workingEstablishment && (
        <ListForm
          filter={params}
          handleSubmit={handleSubmit}
          asuntoForm={asuntoForm}
          numReclamForm={numReclamForm}
          observationForm={observationForm}
          selectEstablishment={selectEstablishment}
          setSelectEstablishment={setSelectEstablishment}
          selectNivel={selectNivel}
          setSelectNivel={setSelectNivel}
          selectAreaResponsable={selectAreaResponsable}
          setSelectAreaResponsable={setSelectAreaResponsable}
          disabled={disabled || submitting || pristine}
        />
      )}
    </Container>
  );
};
