import { Preguntas } from "src/app/models/business/Preguntas";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'pregunta';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'id_pregunta',
      labelKey: 'cabecera',
    },
    withSelectedChips: {
      idKey: 'id_pregunta',
      labelKey: 'cabecera',
    },
  },
};

const optionsToUse = !businessOptions.Question ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Question,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Question.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Preguntas>(DAO_NAME, optionsToUse);

// hooks
export const useQuestionFetchById = fetchById;
export const useQuestionLibbyCall = libbyCall;
export const useQuestionDAO = libbyDAO;
export const useQuestionLibbyFetch = libbyFetch;

// components/hoc
export const QuestionAutocomplete = entityAutocomplete;
export const QuestionCall = renderPropCall;
export const QuestionById = renderPropFetchById;
export const QuestionSelect = simpleSelect;
export const QuestionAccordionSelector = accordionSelector;
export const QuestionSelectedChips = selectedChips;

// context
// ByIdContext
export const useQuestionByIdContext = useByIdContext;
export const QuestionByIdProvider = ByIdProvider;
export const QuestionByIdContext = ByIdContext;
// GeneralContext
export const useQuestionContext = useGeneralContext;
export const QuestionProvider = GeneralProvider;
export const QuestionContext = GeneralContext;
