import { InformacionPersonalNoDocente } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'infopersonalnodocente';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idinfopersonalnodocente',
      labelKey: 'nombre',
    },
    withSelectedChips: {
      idKey: 'idseccion',
      labelKey: 'idinfopersonalnodocente',
    },
  },
};

const optionsToUse = !businessOptions.InfoPersonalNoDocent ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.InfoPersonalNoDocent,
  hoc: { ...defaultOptions.hoc, ...businessOptions.InfoPersonalNoDocent.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<InformacionPersonalNoDocente>(DAO_NAME, optionsToUse);

// hooks
export const useInfoPersonalNoDocentFetchById = fetchById;
export const useInfoPersonalNoDocentLibbyCall = libbyCall;
export const useInfoPersonalNoDocentDAO = libbyDAO;
export const useInfoPersonalNoDocentLibbyFetch = libbyFetch;

// components/hoc
export const InfoPersonalNoDocentAutocomplete = entityAutocomplete;
export const InfoPersonalNoDocentCall = renderPropCall;
export const InfoPersonalNoDocentById = renderPropFetchById;
export const InfoPersonalNoDocentSelect = simpleSelect;
export const InfoPersonalNoDocentAccordionSelector = accordionSelector;
export const InfoPersonalNoDocentSelectedChips = selectedChips;

// context
// ByIdContext
export const useInfoPersonalNoDocentByIdContext = useByIdContext;
export const InfoPersonalNoDocentByIdProvider = ByIdProvider;
export const InfoPersonalNoDocentByIdContext = ByIdContext;
// GeneralContext
export const useInfoPersonalNoDocentContext = useGeneralContext;
export const InfoPersonalNoDocentProvider = GeneralProvider;
export const InfoPersonalNoDocentContext = GeneralContext;
