/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ValidationErrors } from 'final-form';
import _ from 'lodash';
import { Template, TemplateProperty, TemplatePropertyValidator } from './types';
import { AnyObject } from '../../commons/types';
import { getJoiSchema } from './joiHelpers';
import { getErrorMessage } from './errors';

export const validateTemplate = async (value: any, template: Template) => {
  const schema = await getJoiSchema(template.template);
  const result = schema.validate(value, { abortEarly: false });
  const error = result?.error || result?.errors;
  if (error) {
    return error.details.reduce((acum: AnyObject, item, index: number) => {
      const fieldName = item?.context?.key;
      const errorType = item.type.split('.')[1];

      const errorTemplates = _.get(template.template[0], 'type.kind');
      const correspondingTemplate = errorTemplates?.find(
        (templateItem: TemplateProperty) => templateItem.name === fieldName,
      );
      const templateValidators = correspondingTemplate?.validators;
      let customMessage = null;

      if (templateValidators) {
        const correspondingValidator = templateValidators.find(
          (validator: TemplatePropertyValidator) => validator.name === errorType,
        );
        customMessage = correspondingValidator?.errorMessage;
      }

      _.set(acum, item.path.join('.'), customMessage || getErrorMessage(item));
      return acum;
    }, {});
  }
  if (!result.value) {
    throw new Error(`Invalid value for template ${template.name}`);
  }
  return undefined;
};

export const makeFinalFormValidate = <T>(template: Template) => (
  values: T,
): ValidationErrors | Promise<ValidationErrors> | undefined => validateTemplate(values, template);
