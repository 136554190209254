import React, { useState, useMemo, useCallback } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import LanguageIcon from "@material-ui/icons/Language";
import ChatIcon from "@material-ui/icons/Chat";
import { useNotificationTerritoryLibbyFetch } from "src/app/business";
import {
  customFormDialog,
  HeaderTerri,
  InfoList,
  useDebounce,
  useRouteScreenTitle,
} from "src/commons";
import { InformationRow } from "./components/InformationRow";
import { ModalCommunication } from "./components/ModalCommunication";
import { filterComunication } from "./helpers/filterComunication";

const comunicationStyles = makeStyles(() => ({
  headerTerri: {
    borderBottom: "1px solid #e9e9e9",
    paddingBottom: 5,
  },
}));

export const Comunication = () => {
  useRouteScreenTitle("Comunicación");
  const [rowSelect, setRowSelect] = useState<any>(null);
  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 1000);
  const classes = comunicationStyles();

  const filterSearch = useMemo(() => filterComunication(searchDebounced), [
    searchDebounced,
  ]);

  const filterNotification = useMemo(
    () =>
      !filterSearch
        ? {
            noti: [
              {
                path: "estado_notificacion.id_estado_notificacion",
                value: 1,
              },
            ],
          }
        : {
            noti: [
              {
                path: "estado_notificacion.id_estado_notificacion",
                value: 1,
              },
            ],
            ...filterSearch,
          },
    [filterSearch]
  );

  const {
    data: notificationTerritoryData = [],
    working: workingNotificationTerritoryData,
    fetchMore: fetchMoreNotificationTerritoryData,
    reFetch,
  } = useNotificationTerritoryLibbyFetch({
    limit: 10,
    orderBy: "idnotificacionterritorio",
    direction: "desc",
    filter: filterNotification,
  });

  const handleOpenModalSendCommunication = useCallback(async () => {
    await customFormDialog.show({
      title: "Nuevo mensaje",
      renderComponent: <ModalCommunication reFetch={reFetch} />,
      sizeWidth: "sm",
    });
  }, [reFetch]);

  const notificationRows = useMemo(
    () =>
      notificationTerritoryData.map((item, index) => ({
        title: item.titulo,
        subtitleSecondary: `Por ${item?.remitente?.persona?.nombre || "Mesa"} ${
          item?.remitente?.persona.apellido || "Territorial"
        }`,
        subtitleThird: item.esglobal ? "Global" : "Directo",
        dateRight: moment(item.fecha).format("D/MM"),
        icon: item.esglobal ? <LanguageIcon /> : <ChatIcon />,
        ...item,
      })),
    [notificationTerritoryData]
  );

  return (
    <>
      <HeaderTerri
        rightLayout
        searchValue={search}
        setSearchValue={setSearch}
        titleButton="Nuevo mensaje"
        customStyles={classes.headerTerri}
        handleButton={handleOpenModalSendCommunication}
        placeholder="Buscar mensaje"
      />
      <InfoList
        rows={notificationRows}
        onBottomScroll={fetchMoreNotificationTerritoryData}
        working={workingNotificationTerritoryData}
        rowSelect={rowSelect}
        rowIdKey="idnotificacionterritorio"
        onRowClick={(row: any) => setRowSelect(row)}
      >
        {(rowSelect: any) => rowSelect && <InformationRow row={rowSelect} />}
      </InfoList>
    </>
  );
};
