import React from "react";
import { templates } from "./templates";

const initialValues: any = {
  question: {
    title_question: {
      value: "",
    },
    question_first: {
      type: "",
      value: "",
    },
    question_alternative: [],
  },
};

export type childrenFunc = ({
  template,
  working,
  initialValues,
}: {
  template?: any;
  working: boolean;
  initialValues: any;
}) => JSX.Element;

export const CreateFormNew = ({ children }: any) => {
  return (
    <>
      {children({
        template: templates,
        initialValues: JSON.parse(JSON.stringify(initialValues)),
      })}
    </>
  );
};
