import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class PersonDAO extends LibbyFetchDAO {
  constructor() {
    super("persona", "idpersona");
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
