import React, { useCallback } from "react";
import { DatabaseConnector } from "@phinxlab/libby-rest-web";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { nubesBackground } from "src/assets/images";
import { LibbyObject } from "src/commons";
import { primary } from "src/theme/colors";
import { LoginForm } from "./components/LoginForm";
import jwt_decode from "jwt-decode";
import {REACT_APP_GOOGLE_PROJECT_ID } from "../../../config";

const LoginStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(180deg, #417AB6 0%, #6AA7D0 77.08%, #86C6E3 100%)",
    padding: "25px 25px 10px 25px",
  },
  leftContainer: {
    width: "65%",
    height: "100%",
    backgroundImage: `url(${nubesBackground.nubes})`,
    backgroundPosition: "0% 70%",
    backgroundSize: "cover",
  },
  // nubeBackground: {
  //   backgroundPosition: '10% 50%',
  //   height: '100%',
  //   width: '100%',
  //   backgroundImage: `url(${nubesBackground.nubes})`,
  //   flexDirection: 'row',
  // },
  formContainer: {
    height: "100%",
    paddingLeft: 20,
  },
  iconContainer: {
    paddingLeft: 40,
    marginTop: theme.spacing(10),
  },
  descriptionTextTitle: {
    fontSize: 30,
    fontFamily: "Open Sans",
    fontWeight: 600,
    paddingLeft: 30,
    color: "white",
    marginBottom: theme.spacing(2),
  },
  descriptionText: {
    fontSize: 15,
    fontFamily: "Open Sans",
    fontWeight: 600,
    paddingLeft: 30,
    color: "white",
  },
  CardContainer: {
    flexDirection: "row",
    width: "100%",
    marginTop: 50,
    alignSelf: "center",
    justifySelf: "center",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "red",
  },
  BuenosAiresCiudadLogin: {
    paddingLeft: 30,
  },
  bottom: {
    marginTop: "-11%",
  },
  icon: {
    fontSize: 45,
    color: primary.white,
  },
}));

const LoginRaw = ({ libby }: LibbyObject) => {
  const classes = LoginStyles();

  const loginAction = useCallback(
    async (user, password) => {
      console.log('Login:',user, password);
      try {
        const res = await libby.session.login(user, password, {
          username: user,
          password,
        });
        if (!res) {
          // eslint-disable-next-line
          alert("El usuario ingresado no es válido. Intentalo nuevamente.");
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        alert("El usuario ingresado no es válido. Intentalo nuevamente.");
      }
    },
    [libby]
  );

  const googleSignIn = useCallback(
    async (response) => {
      const decoded = jwt_decode(response.credential);
      console.log('Login:response',decoded);



   /*   const userInfo = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${response.access_token}`)
      const data = await userInfo.json();
      console.log('Login:',data);

      const tokenInfo = await fetch(`https://iamcredentials.googleapis.com/v1/projects/-/serviceAccounts/${data.email}:generateIdToken`)
      const token = await tokenInfo.json();
      console.log('Login:token',token);
*/

      try {
        /*const resGoogle = await GoogleApi.singIn();
        console.log('Login:',resGoogle);*/
        const res = await libby.session.login(decoded?.email, "", {
          provider: "google",
          idToken: response.credential,
        });
        if (!res) {
          // eslint-disable-next-line
          alert("Usuario invalido");
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        if (e.message === "Dominio no permitido") {
          // eslint-disable-next-line
          alert(e.message);
        } else {
          // eslint-disable-next-line
          alert("Ah ocurrido un error al intentar iniciar sesion con google");
        }
      }
    },
    [libby.session]
  );

  return (
    <>
      <Grid
        item
        xs={4}
        className={classes.formContainer}
        container
        direction="column"
        alignItems="flex-end"
      >
        <LoginForm
          handleLogin={(user, password) => loginAction(user, password)}
          onGoogleSignInClick={googleSignIn}
        />
      </Grid>
    </>
  );
};

export const Login = DatabaseConnector(LoginRaw)();
