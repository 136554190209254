import React from "react";
import moment from "moment";
import { Grid } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Loading } from "src/commons";
import { RespuestaTerritorio } from "src/app";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    overflow: "auto",
    maxHeight: "490px",
  },
  containerSub: {
    borderTop: "1px solid #E0E0E0",
    marginTop: 15,
  },
  listComment: {
    padding: 15,
    background: "#f5f5f5",
    marginTop: 15,
    borderRadius: 8,
    boxShadow: "0px 0px 0px 1px #E0E0E0",
  },
  itemReclaim: {
    textAlign: "right",
  },
  containerForm: {
    marginTop: 15,
  },
  title: {
    display: "flex",
    width: "100%",
  },
  subtitle: {
    display: "flex",
    marginTop: 15,
    fontSize: 12,
    color: "#8C8C8C",
  },
  iconStyle: {
    fontSize: 12,
    color: "#8C8C8C",
    position: "relative",
    top: 5,
    marginRight: 7,
  },
}));

interface ListCommentProps {
  dataComment: RespuestaTerritorio[];
  loading?: boolean;
}

export const ListComment = ({ dataComment, loading }: ListCommentProps) => {
  const classes = useStyles();
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        dataComment?.map((item: RespuestaTerritorio, index: number) => (
          <Grid
            key={item.idrespuestaterritorio}
            container
            className={classes.listComment}
          >
            <Typography className={classes.title} variant="body1">
              {item.respuesta}
            </Typography>
            <Typography className={classes.subtitle} variant="body1">
              <AccessTimeIcon className={classes.iconStyle} />{" "}
              {moment(item.fecha).format("D/MM/YYYY")}
            </Typography>
          </Grid>
        ))
      )}
    </>
  );
};
