export const API_URL = process.env.REACT_APP_API_URL as string;
export const GOOGLE_MAPS_API_KEY = process.env
  .REACT_APP_GOOGLE_MAPS_API_KEY as string;
export const GOOGLE_OAUTH_API_KEY = process.env.REACT_APP_GOOGLE_OAUTH_API_KEY as string;
export const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string;
export const GOOGLE_MAP_API_KEY = 'AIzaSyCMDHpXlQiX6L0s90Q7JeDDwjQkn5075IM' as string;
export const REACT_APP_GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY as string;

export const REACT_APP_GOOGLE_PROJECT_ID = process.env.REACT_APP_GOOGLE_PROJECT_ID as string

