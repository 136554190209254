import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { MainInput, MainButton } from "src/commons/components";
import GoogleButton from "react-google-button";
import { primary } from "src/theme/colors";
import { BuenosAiresCiudad, LogoBA } from "src/assets/images/svgs";
import {GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import {GOOGLE_OAUTH_CLIENT_ID} from "../../../../../config";

const LoginFormStyles = makeStyles({
  container: {
    backgroundColor: "white",
    paddingLeft: 35,
    paddingRight: 35,
    width: "80%",
    paddingTop: 20,
    borderRadius: 15,
    paddingBottom: 35,
    marginBottom: 25,
    marginTop: "19%",
    marginRight: "15%",
  },
  header: {
    alignItems: "center",
    width: "100%",
    height: 25,
  },
  icon: {
    marginLeft: 10,
    marginRight: 10,
    height: 45,
  },
  formContainer: {
    marginTop: -100,
  },
  titleText: {
    marginTop: "30%",
    fontSize: 24,
    fontWeight: 600,
    fontFamily: "Open Sans",
  },
  descriptionText: {
    marginTop: 15,
    fontSize: 13,
    fontFamily: "Open Sans",
    fontWeight: 600,
  },
  descriptionContainer: {
    paddingTop: 25,
  },
  subDescripcion: {
    cursor: "pointer",
    marginLeft: 4,
    color: primary.lightBlue,
    textDecoration: "underline",
  },
  labelTitle: {
    color: "red",
  },
  googleSignInButton: {
    marginTop: 20,
    marginBottom: 20,
  },
});

interface LoginFormProps {
  handleLogin: (user: string, password: string) => void;
  onGoogleSignInClick: (user: string, password: string) => void;
}

export const LoginForm = ({
  handleLogin,
  onGoogleSignInClick,
}: LoginFormProps) => {
  const classes = LoginFormStyles();
  const [user,setUser] = useState("");
  const [password,setPassword] = useState("");
  const hidden = true;

   const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleLogin(user, password);
  };


  const login = useGoogleLogin({
    flow:'auth-code',
    // @ts-ignore
     onSuccess: async (response) => onGoogleSignInClick(response),
     scope: 'openid'
   })

  return (
    <Grid
      container
      className={classes.container}
      direction="row"
      justify="center"
    >
      <Grid style={{ position: "relative", top: "-25px", marginBottom: '30px' }}>
        <LogoBA />
      </Grid>
      <Grid style={{ width: "100%", display:'flex', justifyContent:'center' }}>
        { !hidden && <form onSubmit={onSubmit}>
          <MainInput
              fullWidth
              customStyle={{marginTop: 25, width: "100%"}}
              value={user}
              handleChange={(e) => setUser(e.target.value)}
              label=""
              placeholder="Email"
              customStyleLabel={{
                fontWeight: 600,
                marginBottom: "-5%",
                marginTop: "10%",
              }}
          />
          <MainInput
              fullWidth
              customStyle={{marginTop: 25, width: "100%"}}
              label=""
              type="password"
              value={password}
              handleChange={(e) => setPassword(e.target.value)}
              placeholder="Contraseña"
              customStyleLabel={{
                fontWeight: 600,
                marginBottom: "-5%",
                marginTop: "10%",
              }}
          />
          <MainButton
              customStyle={{marginTop: 20, width: "100%"}}
              title="Ingresar"
              type="primary"
              size="medium"
              typeButton="submit"
          />
        </form>}
                  {/*<GoogleButton
                      label="Iniciar sesión con Google"
                      className={classes.googleSignInButton}
                      onClick={() => login()}
                      style={{ width: "100%", fontSize: "13px" }}
                  />*/}
        {/*@ts-ignore*/}
        <GoogleLogin
            size={'large'}

            itp_support={false}
            type={'standard'}
            shape={'pill'}
            auto_select={false}
            onSuccess={(response)=> onGoogleSignInClick(response)}/>
      </Grid>
      <Grid style={{ marginTop: "30px" }}>
        <BuenosAiresCiudad />
      </Grid>
    </Grid>
  );
};
