import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { IconButtonRemove, MainInput, SimpleSelect } from "src/commons";
import { FormInput, FormMulti, FormSelect } from "src/lib/templates";
import { useCreateFormContext } from "../../hook";
import { RadioPrimary } from "./components/RadioPrimary";
import { RadioSecondary } from "./components/RadioSecondary";

const option = [
  {
    descripcion: "Parrafo",
    value: "text",
  },
  {
    descripcion: "Radio (si/no)",
    value: "radio_primary",
  },
  {
    descripcion: "Radio (bueno/regular/malo)",
    value: "radio_secondary",
  },
];

const useStyles = makeStyles({
  containerPrimary: {
    background: "#EFEFFF",
    padding: 20,
  },
  container: {
    background: "#ffffff",
    padding: 15,
    paddingTop: 17,
    marginBottom: 10,
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: 5,
  },
  containerComponent: {
    alignItem: "center",
    justifyContent: "space-around",
  },
  questionStyles: {
    marginRight: 10,
    width: "45%",
  },
  preview: {
    marginTop: 10,
    marginLeft: 20,
  },
  textField: {
    paddingBottom: "17px",
  },
  titleInput: {
    "& .MuiOutlinedInput-input": {
      fontSize: 38,
      padding: 10,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderRadius: 0,
    },
  },
  inputFirst: {
    "& .MuiOutlinedInput-input": {
      padding: 0,
    },
  },
});

const customStyleInputPrimary: any = {
    "& .MuiInputBase-inputMultiline": {
      padding: 10,
      fontSize: 16,
    },
}

const customStyleInput = {
  border: "none",
  borderBottom: "1px solid #000000",
  borderRadius: 0,
};

export const FormMain = ({ disabledPreview = false }: any) => {
  const classes = useStyles();
  const { form, values } = useCreateFormContext();

  const previewComponent: Record<string, JSX.Element> = {
    radio_primary: <RadioPrimary />,
    text: (
      <MainInput
        placeholder="Texto de respuesta largo"
        handleChange={() => null}
        value=""
        customStyle={customStyleInput}
      />
    ),
    radio_secondary: <RadioSecondary />,
  };

  return (
    <>
      <Grid container className={classes.containerPrimary}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <FormInput
              label=""
              name="question.title_question.value"
              form={form}
              placeholder="Título del formulario"
              disabled={disabledPreview}
              className={classes.titleInput}
              inputProps={{ inputProps: { min: 0, maxLength: 25 } }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid container className={classes.containerComponent}>
            <Grid item className={classes.questionStyles}>
              <FormInput
                label=""
                name="question.question_first.value"
                form={form}
                placeholder="Escribí la pregunta"
                disabled={disabledPreview}
                className={classes.inputFirst}
                inputProps={{ inputProps: { min: 0, maxLength: 120 }, multiline: true }}
                />
            </Grid>
            <Grid item className={classes.questionStyles}>
              <FormSelect
                label=""
                name="question.question_first.type"
                form={form}
                placeholder="Selecciona formato"
                positionLabel="outer"
                labelKey="descripcion"
                valueKey="value"
                content={option}
                fullWidth
                disabled={disabledPreview}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.preview}>
            {previewComponent[values?.question?.question_first.type]}
          </Grid>
        </Grid>
        <FormMulti
          label=""
          name="question.question_alternative"
          disabled={disabledPreview}
          form={form}
          addButtonText="Agregar pregunta"
          getNewElement={() => ({
            type: "",
            value: "",
          })}
        >
          {(value, input, meta, index) => {
            const onChangeMaker = (name: string) => ({
              target: { value: _value },
            }: {
              target: { value: any };
            }) => {
              input.onChange({ ...value, [name]: _value });
            };
            const getError = (key: string) => {
              const helperText =
                meta.touched && !!meta.error && meta.error[key];
              const error = !!helperText;
              return { error, helperText };
            };
            return (
              <Grid container className={classes.container}>
                <Grid container className={classes.containerComponent}>
                  <Grid item className={classes.questionStyles}>
                    <MainInput
                      {...input}
                      value={value.value}
                      placeholder="Escribí la pregunta"
                      fullWidth
                      handleChange={onChangeMaker("value")}
                      {...getError("value")}
                      disabled={disabledPreview}
                      maxLengths={120}
                      customStyle={customStyleInputPrimary}
                      multiline
                    />
                  </Grid>
                  <Grid item className={classes.questionStyles}>
                    <SimpleSelect
                      {...input}
                      placeholder="Selecciona formato"
                      content={option}
                      labelKey="descripcion"
                      valueKey="value"
                      value={value.type}
                      onChange={onChangeMaker("type")}
                      {...getError("type")}
                      disabled={disabledPreview}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.preview}>
                  {previewComponent[value.type]}
                </Grid>
                <Grid container justify="flex-end">
                  <IconButtonRemove
                    handleOnClick={input.onRemove}
                    disabled={disabledPreview}
                  />
                </Grid>
              </Grid>
            );
          }}
        </FormMulti>
      </Grid>
    </>
  );
};
