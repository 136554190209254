import React from 'react';
import { Grid } from '@material-ui/core';



export const FullScreenLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      justify="center"
    >
      {children}
    </Grid>
  );
};
