import React, { useCallback } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField } from 'react-final-form-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from 'src/theme/colors';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';

const useStyles = makeStyles({
  label: {
    marginBottom: 8,
    fontSize: 13,
    fontWeight: 600,
    color: grey.medium,
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 5,
    },
  },
});

type OptionLabel = (option: any) => JSX.Element;
//type OptionId = (option: any) => any;
//type GetOptionValue = (option: any, primaryKey?: string) => any;
type TransformValue = (value: any) => any;

// FIXME: type form props and autoComplete
export interface FormAutocompleteProps {
  name: string;
  label: string;
  form: any;
  multiple?: boolean;
  autoCompleteProps?: any;
  options: any[];
  optionLabelKey: string | OptionLabel;
  transformValue?: TransformValue;
  getOptionSelected?: any;
  valueToOption?: (value: any, options: any) => any;
  optionToValue?: (option: any) => any;
  placeholder?: string;
  customClasses?: any;
}

export const FormAutocomplete = ({
  name,
  form,
  label,
  options,
  multiple = false,
  optionLabelKey,
  autoCompleteProps,
  getOptionSelected,
  valueToOption,
  optionToValue,
  placeholder,
  customClasses,
}: FormAutocompleteProps): JSX.Element => {
  const originalClasses = useStyles();
  const classes = { ...originalClasses, ...customClasses };
  const field = useField(name, form);
  const getOptionLabel = useCallback(
    (option: any) => {
      if (typeof optionLabelKey === 'string') {
        return option[optionLabelKey] || '';
      }
      return optionLabelKey(option);
    },
    [optionLabelKey],
  );
  return (
    <div>
      <Typography className={classes.label}>{label}</Typography>
      <Autocomplete
        classes={{ root: classes.root }}
        multiple={multiple}
        options={options}
        onChange={(event, newValue: any) => {
          if (Array.isArray(newValue)) {
            field.input.onChange(optionToValue ? newValue.map(optionToValue) : newValue);
          } else {
            field.input.onChange(optionToValue ? optionToValue(newValue) : newValue);
          }
        }}
        value={
          field.input.value
            ? valueToOption
              ? valueToOption(field.input.value, options)
              : field.input.value
            : multiple
            ? []
            : null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ color: 'red' }}
            variant="outlined"
            onFocus={field.input.onFocus}
            onBlur={field.input.onBlur}
            placeholder={placeholder}
            {...getErrorAndHelperTextNoTranslation(field)}
          />
        )}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        {...(autoCompleteProps || {})}
      />
    </div>
  );
};
