import { EstablecimientoVisita } from "src/app/models/business/EstablecimientoVisitas";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'establecimientovisita';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idestablecimientovisita',
      labelKey: 'establecimiento.nombre',
    },
    withSelectedChips: {
      idKey: 'idestablecimientovisita',
      labelKey: 'establecimiento.nombre',
    },
  },
};

const optionsToUse = !businessOptions.EstablishmentVisits ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.EstablishmentVisits,
  hoc: { ...defaultOptions.hoc, ...businessOptions.EstablishmentVisits.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstablecimientoVisita>(DAO_NAME, optionsToUse);

// hooks
export const useEstablishmentVisitsFetchById = fetchById;
export const useEstablishmentVisitsLibbyCall = libbyCall;
export const useEstablishmentVisitsDAO = libbyDAO;
export const useEstablishmentVisitsLibbyFetch = libbyFetch;

// components/hoc
export const EstablishmentVisitsAutocomplete = entityAutocomplete;
export const EstablishmentVisitsCall = renderPropCall;
export const EstablishmentVisitsById = renderPropFetchById;
export const EstablishmentVisitsSelect = simpleSelect;
export const EstablishmentVisitsAccordionSelector = accordionSelector;
export const EstablishmentVisitsSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstablishmentVisitsByIdContext = useByIdContext;
export const EstablishmentVisitsByIdProvider = ByIdProvider;
export const EstablishmentVisitsByIdContext = ByIdContext;
// GeneralContext
export const useEstablishmentVisitsContext = useGeneralContext;
export const EstablishmentVisitsProvider = GeneralProvider;
export const EstablishmentVisitsContext = GeneralContext;
