import { MenuItem } from "../../../commons/ui/menu";
import { reclaimObservationMenu } from "./reclaimObservation";
import { abmUserMenu } from "./abmUser";
import { comunicationMesaMenu } from "./comunicationMesa";
import { googleMapsMenu } from "./googleMaps";
import { createdFormMenu } from "./createdForm";
import { establishmentListMenu } from "./establishmentList";
import { userPerfilMenu } from "./userPerfil";
import { configuracionAreaResponsableMenu } from "./configuracionAreaResponsable";

export const routes: MenuItem[] = [
  reclaimObservationMenu,
  configuracionAreaResponsableMenu,
  abmUserMenu,
  comunicationMesaMenu,
  establishmentListMenu,
  userPerfilMenu,
  googleMapsMenu,
  createdFormMenu,
];
