import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class UsuarioDAO extends LibbyFetchDAO {
  constructor() {
    super("usuario", "idusuario");
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
