import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, TextFieldProps } from './TextField';
import pallet from '../../../../theme/palette';

export const useFormInputStyles = makeStyles({
  label: {
    marginBottom: 10,
    fontSize: 16,
    lineHeight: '17px',
    fontWeight: 400,
    color: pallet.text.primaryAlternative,
  },
});

export type InputProps = TextFieldProps;

export const Input = (props: InputProps): JSX.Element => {
  const { label, ...rest } = props;
  const classes = useFormInputStyles();
  return (
    <div>
      {label && <Typography className={classes.label}>{label}</Typography>}
      <TextField {...rest} />
    </div>
  );
};
