import { Padron } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'padron';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idpadron',
      labelKey: 'nombre',
    },
    withSelectedChips: {
      idKey: 'idseccion',
      labelKey: 'nombre',
    },
  },
};

const optionsToUse = !businessOptions.Padron ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.Padron,
  hoc: { ...defaultOptions.hoc, ...businessOptions.Padron.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Padron>(DAO_NAME, optionsToUse);

// hooks
export const usePadronFetchById = fetchById;
export const usePadronLibbyCall = libbyCall;
export const usePadronDAO = libbyDAO;
export const usePadronLibbyFetch = libbyFetch;

// components/hoc
export const PadronAutocomplete = entityAutocomplete;
export const PadronCall = renderPropCall;
export const PadronById = renderPropFetchById;
export const PadronSelect = simpleSelect;
export const PadronAccordionSelector = accordionSelector;
export const PadronSelectedChips = selectedChips;

// context
// ByIdContext
export const usePadronByIdContext = useByIdContext;
export const PadronByIdProvider = ByIdProvider;
export const PadronByIdContext = ByIdContext;
// GeneralContext
export const usePadronContext = useGeneralContext;
export const PadronProvider = GeneralProvider;
export const PadronContext = GeneralContext;
