export const filterEstablishmentList = (search: any, values: any) => {
  const filter = {
    modal: !values.distrito
      ? {}
      : [
          {
            path: "distritoescolar.iddistritoescolar",
            value: values?.distrito,
          },
        ],
    b: !values.barrio
      ? {}
      : [
          {
            path: "localizacion.domicilio.codigopostalbarrio.barrio.idbarrio",
            value: values?.barrio,
          },
        ],
    c: !values.nivel
      ? {}
      : [
          {
            path: "nivel.idnivel",
            value: values?.nivel,
          },
        ],
    d: !values.comuna
      ? {}
      : [
          {
            path:
              "localizacion.domicilio.codigopostalbarrio.barrio.comuna.idcomuna",
            value: values?.comuna,
          },
        ],
  };

  const filterSearch = [
    { path: "cue", value: search, method: "includes" },
    {
      path: "nombre",
      value: search,
      method: "includes",
    },
    {
      path: "localizacion.descripcion",
      value: search,
      method: "includes",
    },
    {
      path: "localizacion.cueanexo",
      value: search,
      method: "includes",
    },
    {
      path: "localizacion.domicilio.calle",
      value: search,
      method: "includes",
    },
    {
      path: "localizacion.domicilio.codigopostalbarrio.barrio.barrio",
      value: search,
      method: "includes",
    },
  ];

  return {
    filterSearch,
    ...filter,
  };
};
