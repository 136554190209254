import React from "react";
import { useParams } from "react-router";
import { useRouteScreenTitle } from "src/commons";
import { ReclaimObservationList } from "./ReclaimObservationList";

export const ReclaimObservation = () => {
  const {
    idestablecimiento = null,
  }: { idestablecimiento?: string | null } = useParams();
  const textRouter = !idestablecimiento
    ? "Informes de visita"
    : `Informes de visita: ${idestablecimiento}`;
  useRouteScreenTitle(textRouter);
  return (
    <>
      <ReclaimObservationList />
    </>
  );
};
