import { TipoDocumento } from "src/app/models";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'tipodocumento';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idtipodocumento',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idtipodocumento',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TypeDocument ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.TypeDocument,
  hoc: { ...defaultOptions.hoc, ...businessOptions.TypeDocument.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoDocumento>(DAO_NAME, optionsToUse);

// hooks
export const useTypeDocumentFetchById = fetchById;
export const useTypeDocumentLibbyCall = libbyCall;
export const useTypeDocumentDAO = libbyDAO;
export const useTypeDocumentLibbyFetch = libbyFetch;

// components/hoc
export const TypeDocumentAutocomplete = entityAutocomplete;
export const TypeDocumentCall = renderPropCall;
export const TypeDocumentById = renderPropFetchById;
export const TypeDocumentSelect = simpleSelect;
export const TypeDocumentAccordionSelector = accordionSelector;
export const TypeDocumentSelectedChips = selectedChips;

// context
// ByIdContext
export const useTypeDocumentByIdContext = useByIdContext;
export const TypeDocumentByIdProvider = ByIdProvider;
export const TypeDocumentByIdContext = ByIdContext;
// GeneralContext
export const useTypeDocumentContext = useGeneralContext;
export const TypeDocumentProvider = GeneralProvider;
export const TypeDocumentContext = GeneralContext;
