import {Rules} from "../../../../lib/permission/types";
import { SIDEMENU_ID } from "../../const";
//import {SIDEMENU_ID} from "../../const";

export const adminRuleSet: Rules = {
  idRule: 'rol-1',
  routes: [
    { key: 'configuracion' },
  ],
  blockers: [
    // aqui van las restricciones de blockers que se usan para componentes y ui
  ],
  sideMenu: [
    // aqui van las restricciones del menu
    { id: SIDEMENU_ID.CONFIGURATION },
  ],
  tabBars: [
    // aqui van las restricciones de tabs
  ],
};
