import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'escuelas_verdes';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'id_escuelas_verdes',
      labelKey: 'lazo',
    },
    withSelectedChips: {
      idKey: 'id_escuelas_verdes',
      labelKey: 'lazo',
    },
  },
};

const optionsToUse = !businessOptions.SchoolGreen ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.SchoolGreen,
  hoc: { ...defaultOptions.hoc, ...businessOptions.SchoolGreen.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);

// hooks
export const useSchoolGreenFetchById = fetchById;
export const useSchoolGreenLibbyCall = libbyCall;
export const useSchoolGreenDAO = libbyDAO;
export const useSchoolGreenLibbyFetch = libbyFetch;

// components/hoc
export const SchoolGreenAutocomplete = entityAutocomplete;
export const SchoolGreenCall = renderPropCall;
export const SchoolGreenById = renderPropFetchById;
export const SchoolGreenSelect = simpleSelect;
export const SchoolGreenAccordionSelector = accordionSelector;
export const SchoolGreenSelectedChips = selectedChips;

// context
// ByIdContext
export const useSchoolGreenByIdContext = useByIdContext;
export const SchoolGreenByIdProvider = ByIdProvider;
export const SchoolGreenByIdContext = ByIdContext;
// GeneralContext
export const useSchoolGreenContext = useGeneralContext;
export const SchoolGreenProvider = GeneralProvider;
export const SchoolGreenContext = GeneralContext;
