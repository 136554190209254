export const column = [
  {
    id: "descripcion",
    label: "Área Responsable",
    width: "9%",
    orderById: "descripcion",
  },
  {
    id: "active",
    label: "",
    width: "5%",
    orderById: "disabled",
    hideSortIcon: true,
    noSort: true,
  },
  {
    id: "icon",
    label: "Configurar",
    width: "3%",
    hideSortIcon: true,
    noSort: true,
    style: { textAlign: "left" },
  },
];
