import { AreaResponsable } from "src/app/models/business/AreaResponsability";
import {makeBusinessEntity, PartialBusinessEntityOptions} from "../../../lib/libby/generator";
import { businessOptions } from "../businessOptions";

const DAO_NAME = 'arearesponsable';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idarearesponsable',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idarearesponsable',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.AreaResponsability ? defaultOptions : {
  ...defaultOptions,
  ...businessOptions.AreaResponsability,
  hoc: { ...defaultOptions.hoc, ...businessOptions.AreaResponsability.hoc }
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { entityAutocomplete, renderPropCall, renderPropFetchById, simpleSelect, accordionSelector, selectedChips },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<AreaResponsable>(DAO_NAME, optionsToUse);

// hooks
export const useAreaResponsabilityFetchById = fetchById;
export const useAreaResponsabilityLibbyCall = libbyCall;
export const useAreaResponsabilityDAO = libbyDAO;
export const useAreaResponsabilityLibbyFetch = libbyFetch;

// components/hoc
export const AreaResponsabilityAutocomplete = entityAutocomplete;
export const AreaResponsabilityCall = renderPropCall;
export const AreaResponsabilityById = renderPropFetchById;
export const AreaResponsabilitySelect = simpleSelect;
export const AreaResponsabilityAccordionSelector = accordionSelector;
export const AreaResponsabilitySelectedChips = selectedChips;

// context
// ByIdContext
export const useAreaResponsabilityByIdContext = useByIdContext;
export const AreaResponsabilityByIdProvider = ByIdProvider;
export const AreaResponsabilityByIdContext = ByIdContext;
// GeneralContext
export const useAreaResponsabilityContext = useGeneralContext;
export const AreaResponsabilityProvider = GeneralProvider;
export const AreaResponsabilityContext = GeneralContext;
