import { Typography } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import {
  useAreaResponsableSubWebDAO,
  useAreaResponsableSubWebLibbyFetch,
} from "src/app/business/business/AreaResponsableSubWeb";
import { AreaResponsaleSub } from "src/app/models/business/AreaResponsableSub";
import {
  confirmDialog,
  customFormDialog,
  HeaderTerri,
  IconTables,
  InfoTable,
  Loading,
  useDebounce,
  useOrderByState,
  useRouteScreenTitle,
} from "src/commons";
import { ModalCreateSubArea } from "./components";
import { column } from "./helpers";

const useStyles = makeStyles({
  status_1: {
    background: "#CBF1D9",
    padding: "4px 8px",
    borderRadius: "3px",
    color: "#000000",
    fontSize: 13,
    textAlign: "center",
    width: "50%",
  },
  status_2: {
    background: "#FFFBEF",
    padding: "4px 8px",
    borderRadius: "3px",
    color: "#766200",
    fontSize: 13,
    textAlign: "center",
    width: "50%",
  },
  status_3: {
    background: "#ebebeb",
    padding: "4px 8px",
    borderRadius: "3px",
    color: "#8E8E8E",
    fontSize: 13,
    textAlign: "center",
    width: "50%",
  },
});

export const SubAreas = () => {
  useRouteScreenTitle("Configuracion");
  const classes = useStyles();

  const [search, setSearch] = useState("");
  const searchDebounced = useDebounce(search, 1000);
  const { area: areaParams }: any = useParams();
  const areaResponsableSubWebDAO = useAreaResponsableSubWebDAO();

  const { orderBy, direction, onOrderByDirectionChange } = useOrderByState(
    "descripcion",
    "asc"
  );

  const filterSearch = useMemo(
    () => ({
      area: [
        {
          path: "id_arearesponsable",
          value: areaParams,
        },
      ],
      personaFilter: [
        {
          path: "descripcion",
          value: searchDebounced,
          method: "includes",
        },
      ],
    }),
    [searchDebounced, areaParams]
  );

  const {
    data: areaResponsableSub = [],
    working: workingArea,
    fetchMore: fetchMoreArea,
    reFetch,
  } = useAreaResponsableSubWebLibbyFetch({
    filter: filterSearch,
  });

  const handleDisabledArea = useCallback(
    async (area: AreaResponsaleSub) => {
      const textPrimary = area.disabled ? "Habilitar" : "Deshabilitar";
      const confirm = await confirmDialog.show({
        title: "",
        confirmText: textPrimary,
        cancelText: "Cancelar",
        content: `¿Desea ${textPrimary} el área?`,
        alternativeColor: area.disabled,
      });
      if (confirm) {
        const areaDisabled = {
          ...area,
          disabled: area.disabled ? false : true,
        };

        await areaResponsableSubWebDAO.aspect("aspect-web").save(areaDisabled);
        reFetch();
      }
    },
    [areaResponsableSubWebDAO, reFetch]
  );

  const handleAddArea = useCallback(
    async (area, edit) => {
      const formValue = area.descripcion;
      const textModal = !area.idarearesponsable
        ? "Crear sub área"
        : "Editar sub área responsable";
      await customFormDialog.show({
        title: textModal,
        renderComponent: (
          <ModalCreateSubArea
            isValue={formValue}
            reFetch={reFetch}
            id_arearesponsable_sub={area.id_arearesponsable_sub}
            area={areaParams}
          />
        ),
        sizeWidth: "sm",
      });
    },
    [reFetch, areaParams]
  );

  const icoTables = useCallback(
    (area) => [
      {
        text: "Editar",
        icon: <Edit />,
        handleOnClick: () => handleAddArea(area, "edit"),
        disabled: false,
      },
      {
        text: "Deshabilitar",
        icon: <Delete />,
        handleOnClick: () => handleDisabledArea(area),
        disabled: false,
      },
    ],
    [handleAddArea, handleDisabledArea]
  );

  const status: Record<number, string> = useMemo(
    () => ({
      1: classes.status_1,
      2: classes.status_2,
      3: classes.status_3,
    }),
    [classes]
  );

  const rows = useMemo(
    () =>
      areaResponsableSub.map((item) => ({
        ...item,
        icon: <IconTables content={icoTables(item)} />,
        active: (
          <Typography className={item.disabled ? status[1] : status[2]}>
            {item?.disabled ? "Inactivo" : "Activo"}
          </Typography>
        ),
      })),
    [areaResponsableSub, icoTables, status]
  );

  return (
    <HeaderTerri
      rightLayout
      searchValue={search}
      setSearchValue={setSearch}
      titleButton="Crear nueva sub área"
      handleButton={handleAddArea}
      placeholder="Buscar"
    >
      {workingArea ? (
        <Loading />
      ) : (
        <InfoTable
          working={workingArea}
          rows={rows || []}
          columns={column}
          onBottomScroll={fetchMoreArea}
          rowIdKey="idarearesponsable"
          direction={direction}
          orderBy={orderBy}
          onSortChange={onOrderByDirectionChange}
        />
      )}
    </HeaderTerri>
  );
};
