import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EstablishmentDAO extends LibbyFetchDAO {
  constructor() {
    super('usuarioestablecimiento', 'idusuarioestablecimiento');
  }


  getAll() {
    return this.query().limit(0, 10).run();
  }

  fetchByLocalizacionId(id: any) {
    return this.query()
      .equals('localizacion.idlocalizacion', id)
      .run();
  }
}
