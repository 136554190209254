import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-final-form-hooks";
import { useEstablecimientoCategoriasDAO } from "src/app/business/business/EstablecimientoCategorias";
import { customFormDialog, FooterForm } from "src/commons";
import { FormMultiCheckbox } from "src/lib/templates";
import { categoryMobileApp } from "../../helpers";

const customStyles = {
  footer: { marginTop: 20 },
  containerGrid: { paddingRight: 20, paddingLeft: 20, paddingBottom: 17 },
};

export const ModalAssignArea = ({
  isValues = {},
  id_establecimientos_categorias,
  id_localizacion,
  reFetch,
}: any) => {
  const formValues = isValues;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const EstablecimientoCategoriasDAO = useEstablecimientoCategoriasDAO();

  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const categorias = {
          id_establecimientos_categorias,
          id_localizacion,
          categorias: JSON.stringify(values),
        };
        console.log(categorias);
        await EstablecimientoCategoriasDAO.aspect("aspect-web").save(
          categorias
        );

        enqueueSnackbar("Se han desactivado las categorías", {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar("Se produjo un error, intente nuevamente", {
          variant: "error",
        });
      } finally {
        reFetch();
        setLoading(false);
        customFormDialog.handleCancel();
      }
    },
    [
      id_establecimientos_categorias,
      id_localizacion,
      EstablecimientoCategoriasDAO,
      reFetch,
      enqueueSnackbar,
    ]
  );

  const { form, handleSubmit } = useForm({
    initialValues: formValues,
    onSubmit,
  });

  const buttonConfig = useMemo(
    () => [
      {
        title: "Cancelar",
        handleOnClick: () => customFormDialog.handleCancel(),
        type: "secondary",
        size: "small",
      },
      {
        title: "Guardar",
        handleOnClick: () => null,
        size: "small",
        disabled: loading,
        typeButton: "submit",
      },
    ],
    [loading]
  );

  return (
    <Grid container style={{ width: "95%", margin: "auto", marginTop: 10 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} style={customStyles.containerGrid}>
          <FormMultiCheckbox
            name="establishment_category"
            idKey="id_category"
            options={categoryMobileApp}
            form={form}
          />
          <FooterForm
            buttonConfig={buttonConfig}
            spacing={1}
            customStyle={customStyles.footer}
          />
        </Grid>
      </form>
    </Grid>
  );
};
