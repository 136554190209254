import React, { useMemo, useRef } from "react";
import { Grid, Radio } from "@material-ui/core";
import { InfoTable, MainButton, MainInput, SimpleSelect } from "src/commons";
import { ResponsableAreaSelect, useEstablishmentLibbyFetch } from "src/app/business";

const customStyles = {
  customStyleTitle: { color: "black", position: "relative", top: "-12px" },
  containerGrid: { paddingRight: 20, paddingLeft: 20, paddingBottom: 17 },
  inputGrid: { paddingTop: 25 },
  footer: { marginTop: 20 },
  select: { marginTop: 20},
  buttom: { marginTop: 20, justifyContent: "center", width: "100%"}
};

const column = [
  {
    id: "name",
    label: "Nombre del establecimiento",
    width: "5%",
    orderById: "nombre",
  },
];

export const ListForm = ({
  handleSubmit,
  filter,
  asuntoForm,
  numReclamForm,
  observationForm,
  selectEstablishment,
  setSelectEstablishment,
  setSelectNivel,
  setSelectAreaResponsable,
  selectNivel,
  selectAreaResponsable,
  disabled,
}: any) => {

  const arrSelects = [
    {
      input: true,
      label: "Asunto",
      placeholder: "Ingresá el asunto",
      name: asuntoForm.input.name,
      handleChange: asuntoForm.input.onChange,
      value: asuntoForm.input.value,
    },
    {
      input: true,
      label: "Numero de reclamo",
      placeholder: "Ingresá el numero de reclamo",
      name: numReclamForm.input.name,
      handleChange: numReclamForm.input.onChange,
      value: numReclamForm.input.value,
    },
    {
      input: true,
      label: "Observaciones",
      placeholder: "Ingresa la observacion",
      name: observationForm.input.name,
      handleChange: observationForm.input.onChange,
      value: observationForm.input.value,
      multiline: true,
      rows: 6,
    },
  ];

  const filterEstablishmentTable = useRef({
    id: filter,
  });

  const {
    data = [],
    working: workingLocalizacion,
    fetchMore: fetchLocalizacion,
  } = useEstablishmentLibbyFetch({
    filter: filterEstablishmentTable.current,
  });

  const usePassRequestTableLogic = useMemo(() => {
    const rows = data.map((item, index: number) => ({
      id: item.idestablecimiento,
      name: (
        <Grid container alignItems="center">
          <Grid item>
            <Radio
              color="default"
              name="radio-button-demo[]"
              value={selectEstablishment}
              checked={
                selectEstablishment?.idestablecimiento ===
                item.idestablecimiento
              }
              onChange={() => setSelectEstablishment(data[index])}
            />
          </Grid>
          <Grid item style={{ maxWidth: '200px' }}>{item.nombre}</Grid>
        </Grid>
      ),
      ...item,
    }));
    return {
      rows,
    };
  }, [data, selectEstablishment, setSelectEstablishment]);

/*   const filterLevel = useMemo(
    () => ({
      id: selectEstablishment?.nivel?.map((item: any) => ({
        path: "idnivel",
        value: item.idnivel,
      })),
    }),
    [selectEstablishment]
  ); */

  return (
    <>
      <InfoTable
        rows={usePassRequestTableLogic.rows}
        columns={column}
        working={workingLocalizacion}
        rowIdKey="idGrupo"
        onBottomScroll={fetchLocalizacion}
      />
    {/*   <NivelSelect
        handleChange={(e) => setSelectNivel(e.target.value)}
        value={selectNivel}
        title="Seleccione el nivel"
        labelKey="descripcion"
        valueKey="idnivel"
        filter={filterLevel}
        customStyleTitle={customStyles.select}
      /> */}
      <ResponsableAreaSelect
        handleChange={(e) => setSelectAreaResponsable(e.target.value)}
        value={selectAreaResponsable}
        title="Seleccione el área responsable"
        labelKey="descripcion"
        valueKey="idarearesponsable"
        customStyleTitle={customStyles.select}
      />
      <form onSubmit={handleSubmit}>
        {arrSelects.map((e: any) =>
          e.input ? (
            <Grid style={customStyles.inputGrid}>
              <MainInput
                {...e}
                handleChange={e.handleChange}
                value={e.value}
                fullWidth
                maxLengths={200}
                customStyleLabel={customStyles.customStyleTitle}
              />
            </Grid>
          ) : (
            <Grid item xs={6}>
              <SimpleSelect
                {...e}
                handleChange={e.handleChange}
                content={e.content}
                value={e.value}
              />
            </Grid>
          )
        )}
        <MainButton
          title="Enviar"
          handleOnClick={() => null}
          typeButton="submit"
          disabled={disabled}
          customStyle={customStyles.buttom}
        />
      </form>
    </>
  );
};
