import React, { useMemo } from "react";
import { TabBar, useRouteScreenTitle } from "src/commons";
import { EstablishmentArea } from "./EstablishmentArea";
import { ReclaimArea } from "./ReclaimArea";

export const ConfiguracionAreaResponsable = () => {
  useRouteScreenTitle("Configuración");

  const ListConfiguracionArea = useMemo(
    () => [
      {
        title: "Configuración de Área",
        component: <ReclaimArea />,
      },
      {
        title: "Establecimientos",
        component: <EstablishmentArea />,
      },
    ],
    []
  );

  return (
    <>
      <TabBar loading={false} content={ListConfiguracionArea} />
    </>
  );
};
