import { LibbyFetchDAO } from "./LibbyFetchDAO";

export class BarrioDAO extends LibbyFetchDAO {
  constructor() {
    super('barrio', 'idbarrio');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
